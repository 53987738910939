import React from 'react';
import { useTranslation, Trans } from "react-i18next";

const SavedContentEmptyState = () => {
  const { t } = useTranslation('LearnDashboard');

  return (
    <div className="EmptyState">
      <h4 className="EmptyState-title">{t("savedContent.emptyStates.todoTitle")}</h4>
      <img className="EmptyState-image" src="/assets/empty_states/learn_dashboard_todo.png" />
      <p className="EmptyState-description">
        <Trans t={t} i18nKey="savedContent.emptyStates.todoDescription">
          <i className="fa fa-plus-circle u-textDarkBlue" />
        </Trans>
      </p>
      <a href="/library" className="EmptyState-action Button Button--primary">{t("savedContent.emptyStates.todoButton")}</a>
    </div>
  );
};

export default SavedContentEmptyState;

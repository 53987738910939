import React from 'react';
import { CORRECT, INCORRECT, NOT_EVALUATED } from "./Reducers/AnswersReducer";

const AnswerIcon = ({ answerEvaluation }) => {
  switch (answerEvaluation) {
    case NOT_EVALUATED:
      return null;
    case CORRECT:
      return <i className="fa fa-check-circle" />;
    case INCORRECT:
      return <i className="fa fa-times-circle" />;
    default:
      return null;
  }
};

export default AnswerIcon;

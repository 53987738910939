import { useState } from 'react';
import client from '../../lib/api/axiosClient';

const useSqlEditor = ({ exerciseId, defaultSql = '', schema = 'public' }) => {
  const [query, setQuery] = useState(defaultSql);
  const [result, setResult] = useState([]);
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  // Run the SQL query without evaluating the answer
  const runQuery = (e) => {
    e.preventDefault();
    runSql(false);
  };

  // Run the SQL query, evaluate the answer and create an exercise submission
  const submitQuery = (e) => {
    e.preventDefault();
    runSql(true);
  };

  const runSql = (shouldEvaluateAnswer) => {
    setErrorMessage(null);

    client
      .post('/sql_exercises', { query, exercise_id: exerciseId, schema })
      .then(({ data }) => {
        const responseParsed = JSON.parse(data.backend_response);

        if (responseParsed.error) {
          setErrorMessage(responseParsed.error);
          setStatus('error');
          setResult([]);
          setFields([]);
        } else {
          setResult(responseParsed.values);
          setFields(responseParsed.fields);

          if (shouldEvaluateAnswer) {
            if (data.correct_response) setStatus('success');
            else setStatus('incorrect');
          } else if(status === 'error') {
            setStatus('');
          }
        }
      })
      .catch(_e => {
        // noop
      });
  };

  return {
    query,
    setQuery,
    fields,
    setFields,
    result,
    setResult,
    status,
    setStatus,
    errorMessage,
    setErrorMessage,
    runQuery,
    submitQuery,
    runSql,
  };
};

export default useSqlEditor;

import React from 'react';
import { useTranslation } from "react-i18next";

const NewToKubicle = ({ marketing_site_url }) => {
  const { t } = useTranslation('NewToKubicle');

  return (
    <div className="Blurb">
      <h2 className="Blurb-title">{t('newToKubicle')}</h2>
      <p className="Blurb-text">{t('tagline')}</p>
      <div className="EmptyState">
        <img className="EmptyState-image" src="/assets/sign_in/sign_in_marketing.png" />
      </div>
      <a href={marketing_site_url} className="Button Button--secondary">{t('learnMore')}</a>
      <a href={`${marketing_site_url}/library`} className="Button Button--primary white-border">{t('visitLibrary')}</a>
    </div>
  );
};

export default NewToKubicle;

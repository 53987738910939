import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Banner from './Banner';
import Content from './Content';
import client from '../../../lib/api/axiosClient';
import Modal from '../../DesignSystem/Modal';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Toasts from '../../DesignSystem/Toasts';
import ContentIcon from '../../Common/Components/ContentIcon';
import LearningPathSlide from './LearningPathSlide';
import Icon from '../../DesignSystem/Icon';

const LearningPath = ({ user_id, learning_path_id }) => {
  const { t } = useTranslation('LearningPaths');
  const [learningPath, setLearningPath] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [showEnrollmentLimit, setShowEnrollmentLimit] = useState(false);
  const [missingSubjects, setMissingSubjects] = useState([]);
  const [showMissingSubjects, setShowMissingSubjects] = useState(false);
  const [showBehindSchedule, setBehindSchedule] = useState(false);
  const [diplomaDetails, setDiplomaDetails] = useState(null);
  const [enrollmentCount, setEnrollmentCount] = useState(null);

  const maxEnrollments = 3;

  useEffect(() => {
    loadLearningPath(learning_path_id);
  }, []);

  useEffect(() => {
    if (!learningPath) return;
    const subjectSet = new Set(
      learningPath.contents
        .filter(content => !content.can_access)
        .map(content => content.subject_name)
    );
    setMissingSubjects(Array.from(subjectSet));
    setDiplomaDetails({
      subject_colour: learningPath.subject_colour,
      content_type: 'learning-path-diploma',
    });
  }, [learningPath]);

  const hideLimitModal = () => setShowEnrollmentLimit(false);
  const hideBehindScheduleModal = () => setBehindSchedule(false);

  const loadLearningPath = id => {
    client
      .get(`/api/v1/learning_paths/${id}.json`)
      .then(response => {
        const learningPath = response.data.data.learning_path;
        setLearningPath(learningPath);
        setEnrollmentCount(learningPath.enrolled_learning_paths_count);
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.status === 401) window.location.reload();
      });
  };

  const enroll = () => {
    if (actionInProgress) return;
    setActionInProgress(true);
    if (missingSubjects.length > 0) {
      setShowMissingSubjects(true);
      setActionInProgress(false);
      return;
    }
    if (enrollmentCount >= maxEnrollments) {
      setShowEnrollmentLimit(true);
      setActionInProgress(false);
      return;
    }
    if (!showBehindSchedule && learningPath.semester_behind_schedule) {
      setBehindSchedule(true);
      setActionInProgress(false);
      return;
    }

    client
      .post(`/api/v1/users/${user_id}/user_learning_paths/${learning_path_id}/enroll.json`)
      .then(response => {
        setActionInProgress(false);
        window.location.assign(response.data.redirect);
      })
      .catch(() => setActionInProgress(false));
  };

  const togglePathEnrollment = (isEnrolling, learningPathId) => {
    const request = isEnrolling ? 'enroll' : 'unenroll';
    const newCount = isEnrolling ? (enrollmentCount + 1) : (enrollmentCount - 1);

    client
      .post(`/api/v1/users/${user_id}/user_learning_paths/${learningPathId}/${request}.json`)
      .then(() => {
        setEnrollmentCount(newCount);
      }).catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  };

  const renderHours = () => {
    const isInProgress = learningPath.remaining_hours !== null && learningPath.remaining_hours !== learningPath.hours;
    if (isInProgress) {
      return t('hours_remaining', {
        remaining: learningPath.remaining_hours,
        total: learningPath.hours,
      });
    }

    return `${learningPath.hours} ${t('hour', { count: learningPath.hours })}`;
  };

  const renderEnrollButton = className => {
    const onClick = () => {
      if (!user_id) {
        return function () {
          window.location.href = `/individual_trials/new?learning_path_id=${learningPath.id}`;
        };
      }
      if (learningPath.enrolled) {
        return function () {
          window.location.href = `/users/${user_id}/my_kubicle?tab=learning-path-${learningPath.id}`;
        };
      }
      return enroll;
    };

    const extraBtnClass = () => (actionInProgress ? 'is-inactive' : '');

    const buttonText = () => {
      if (!user_id) return t('tryForFree');

      if (learningPath.enrolled) return t('continueLearning');

      if (learningPath.complete) return t('claimDiploma');

      return t('startPath');
    };

    return (
      <a
        onClick={onClick()}
        className={`Button Button--tertiary ${extraBtnClass()} ${className}`}
        disabled={actionInProgress}
      >
        {buttonText()}
      </a>
    );
  };

  const modalEnroll = () => {
    if (enrollmentCount < 3) {
      togglePathEnrollment(true, learning_path_id);
      window.location.href = `/users/${user_id}/my_kubicle?tab=learning-path-${learning_path_id}`;
    }
  };

  const renderDiplomaCTAPanel = () => (
    <>
      <div className="ProgressBlocks-step">
        <div className="ProgressBlocks-stepSeparator" />
        <div className="ProgressBlocks-stepNumber">
          <i
            className="fa fa-flag"
            style={{ color: learningPath.subject_colour }}
          />
        </div>
        <div className="ProgressBlocks-stepSeparator u-invisible" />
      </div>
      <div className="ProgressBlocks-content">
        <div className="Panel">
          <div className="ContentHeading ContentHeading--cta">
            {diplomaDetails
              && <ContentIcon content={diplomaDetails} className="SubjectIcon--large" />}
            <div className="ContentHeading-text u-textDarkBlue u-grow1">
              <span className="ContentHeading-subject">
                {t('certifiedDiploma')}
                :
              </span>
              <span className="ContentHeading-content m-bottom-none">{learningPath.name}</span>
              <div className="ContentHeading-showcase Showcase Showcase--flex m-all-none m-all-m-m">
                <div className="Descriptor u-textGray u-grow0">
                  <Icon className="Descriptor-icon" icon="schedule" />
                  <span className="Descriptor-text">{renderHours()}</span>
                </div>
                <div className="Descriptor u-textGray u-grow1">
                  <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-grey" />
                  <span className="Descriptor-text">{t('cpdCredits', { count: learningPath.cpd_credits })}</span>
                </div>
                {!user_id
                  && (
                    <div className="Descriptor u-textGray">
                      <span className="Descriptor-text">{t('39EuroPerMonth')}</span>
                    </div>
                  )}
              </div>
            </div>
            {renderEnrollButton()}
          </div>
        </div>
      </div>
    </>
  );

  if (learningPath === null) return null;

  return (
    <>
      <Banner
        learningPath={learningPath}
        user_id={user_id}
        enrollAction={enroll}
        actionInProgress={actionInProgress}
        renderHours={renderHours}
        renderEnrollButton={renderEnrollButton}
      />
      <div className="m-top-l u-hiddenTablet" />
      <section className="Page Page--withSidebar u-columnReverseTablet">
        <div className="Page-content">
          <div className="ProgressBlocks">
            {learningPath.contents.map((content, index) => (
              <Content
                key={`${content.content_type}-${content.id}`}
                index={index}
                content={content}
                totalContents={learningPath.contents.length}
              />
            ))}
            {renderDiplomaCTAPanel()}
          </div>
        </div>
        <div className="Page-sidebar Page-sidebar--splitTablet">
          <div className="Panel">
            <h4>
              <i className="custom-icon icon-technology icon-color-navy Panel-headingIcon" />
              <span>{t('Technology')}</span>
            </h4>
            <p>{learningPath.technology}</p>
          </div>
          <div className="Panel">
            <h4>
              <i className="custom-icon icon-learning-path icon-color-navy Panel-headingIcon" />
              <span>{t('prerequisitePath')}</span>
            </h4>
            <div>
              {learningPath.prerequisite_path.id
                && (
                  <a
                    href={`/learning_paths/${learningPath.prerequisite_path.slug}`}
                  >
                    {learningPath.prerequisite_path.name}
                  </a>
                )}
              {!learningPath.prerequisite_path.id && <span>{t('noPrerequisite')}</span>}
            </div>
          </div>
        </div>
      </section>
      {showEnrollmentLimit && (
        <div className="Modals">
          <Modal closeCallback={hideLimitModal}>
            <div className="Modal-content">
              <h4 className="Modal-title">{t('maxEnrollments')}</h4>
              <img className="Modal-image" src="/assets/errors/too-much-learning.png" />
              <div className="Modal-description">
                <Trans
                  t={t}
                  i18nKey="maxEnrollmentsDescription"
                  values={{ limit: maxEnrollments, path_name: learningPath.name }}
                >
                  Text
                  <span className="u-bold">Text</span>
                  Text
                </Trans>
              </div>
              <div className="Modal-content">
                <div className="m-top-m">
                  {learningPath.enrolled_learning_paths.map(learningPath => (
                    <LearningPathSlide
                      learningPathId={learningPath.id}
                      name={learningPath.name}
                      progress={learningPath.progress}
                      togglePathEnrollment={togglePathEnrollment}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="Modal-actions">
              <a
                onClick={modalEnroll}
                className={`Modal-action Button Button--primary ${enrollmentCount >= 3 ? 'is-inactive' : ''}`}
              >
                {t('startPath')}
              </a>
            </div>
          </Modal>
        </div>
      )}
      {showBehindSchedule && (
        <div className="Modals">
          <Modal closeCallback={hideBehindScheduleModal}>
            <div className="Modal-content">
              <h4 className="Modal-title">{t('areYouSure')}</h4>
              <img className="Modal-image" src="/assets/errors/too-much-learning.png" />
              <div className="Modal-description">
                <Trans
                  t={t}
                  i18nKey="behindScheduleDescription"
                  values={{ semester_name: learningPath.semester_name }}
                >
                  Text
                  <span className="u-bold">Text</span>
                  Text
                </Trans>
              </div>
            </div>
            <div className="Modal-actions">
              <a onClick={enroll} className="Modal-action Button Button--primary">{t('confirmEnroll')}</a>
              <a onClick={hideBehindScheduleModal} className="Modal-action Button Button--secondary">{t('cancel')}</a>
            </div>
          </Modal>
        </div>
      )}
      <Toasts>
        {showMissingSubjects && missingSubjects.length > 0
          && (
            <Toast
              state={ToastStates.WARNING}
              title="Missing Licenses"
            >
              <p>{t('cannotEnroll')}</p>
              <ul>
                {missingSubjects.map(subject => <li key={`missing-subject-${subject}`}>{subject}</li>)}
              </ul>
            </Toast>
          )}
      </Toasts>
    </>
  );
};

export default LearningPath;

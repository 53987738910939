/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import axiosClient from '../../lib/api/axiosClient';
import Form from '../DesignSystem/Forms/Form';
import Fieldset from '../DesignSystem/Forms/Fieldset';
import Field from '../DesignSystem/Forms/Field';
import Toasts from '../DesignSystem/Toasts';
import Toast, { ToastStates } from '../DesignSystem/Toast';

const SignInEmailStep = ({ onSubmitSuccess }) => {
  const { t } = useTranslation('SignInPanel');

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [networkError, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  const onSubmit = async () => {
    if (actionInProgress) return;

    setErrors({});
    setNetworkError(null);
    setActionInProgress(true);

    if (email.trim() === '') {
      setErrors({ email: t('emailEmpty') });
      setActionInProgress(false);
      return;
    }

    try {
      const res = await axiosClient.get('/api/v1/sign-in.json', {
        params: {
          email,
        },
      }, { timeout: 20000 });
      setActionInProgress(false);

      if (res.data.redirect) window.location.href = res.data.redirect;

      onSubmitSuccess({ email, ssoUrl: res.data.sso_url });
    } catch (error) {
      if (error.response?.data) {
        setErrors({ email: error.response.data.error });
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit ${actionInProgress ? 'is-inactive' : ''}`}
      disabled={actionInProgress}
      value={t('emailSubmit')}
    />
  );

  const renderFields = () => (
    <>
      <Field
        id="user_email"
        type="text"
        name="email"
        label={t('email')}
        value={email}
        autoFocus="autofocus"
        onChange={(_, newEmail) => setEmail(newEmail.trim().toLowerCase())}
        error={errors.email}
        required
      />
    </>
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <Form onSubmit={onSubmit} disabled={actionInProgress}>
        <Fieldset>
          {renderFields()}
          {renderSubmit(actionInProgress)}
        </Fieldset>
      </Form>
    </>
  );
};

export default SignInEmailStep;

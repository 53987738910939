import React from 'react';
import { useTranslation } from 'react-i18next';

const LearningPathCard = ({
  learningPath,
  onClick,
}) => {
  const { t: lpt } = useTranslation('LearningPaths');

  const onClickHandler = (event) => {
    event.preventDefault();
    onClick(learningPath);
  };

  return (
    <a
      className="Card is-link"
      id={`card-learning-path-${learningPath.id}`}
      onClick={onClickHandler}
    >
      <div className="Card-tile">
        <img className="Card-image" src={learningPath.image} />
        <div className="Card-content">
          <div className="Card-title">
            {learningPath.name}
          </div>
          <div className="Card-descriptors Card-descriptors--grid u-flexRow">
            <div className="Descriptor Descriptor--card">
              <i className="Descriptor-icon fa fa-clock-o" />
              <span className="Descriptor-text">
                {learningPath.hours}
                {' '}
                {lpt('hour', { count: learningPath.hours })}
              </span>
            </div>
            <div className="Descriptor Descriptor--card">
              <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
              <span className="Descriptor-text">
                {learningPath.cpd_credits}
                {' '}
                {lpt('cpeCredits')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default LearningPathCard;

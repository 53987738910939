import React from 'react';

const Slide = ({
  color, className = '', onClick = (() => {}), children,
}) => (
  <div className={`Slide ${className}`} onClick={onClick}>
    {color && <div className="Slide-swatch" style={{ background: color }} />}
    <div className="Slide-content">
      {children}
    </div>
  </div>
);

export default Slide;

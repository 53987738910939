import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Option from "./Option";

const BooleanQuestion = ({
  id,
  question,
  index,
  readOnly,
  saveAnswerCallback,
  answer,
  answer_correct,
  lastEvaluation,
}) => {
  const { t } = useTranslation('Project');
  const options = [
    {
      value: 'true', id: `true-${id}`, option_text: t('Yes'), order_number: 0,
    },
    {
      value: 'false', id: `false-${id}`, option_text: t('No'), order_number: 1,
    },
  ];
  const [selectedOption, setSelectedOption] = useState();
  const [answerEvaluated, setAnswerEvaluated] = useState(answer != null);

  useEffect(() => {
    if (answer !== null) setSelectedOption(answer);
  }, [answer]);

  useEffect(() => {
    if (lastEvaluation != null) setAnswerEvaluated(true);
  }, [lastEvaluation]);

  const selectOption = (optionId) => {
    if (!answer_correct) {
      saveAnswerCallback(index, id, optionId);
      setSelectedOption(optionId);
      setAnswerEvaluated(false);
    }
  };

  return (
    <div className="question-container multiple-choice-question">
      <label
        className={`question-label ${readOnly ? 'read-only' : ''}`}
        htmlFor={`question_${id}`}
      >
        {t('question_text', { number: (index + 1), text: question })}
      </label>
      <div className="multiple-choice-question radio-options">
        {options.map((option) => (
          <Option
            {...option}
            {...{ index, readOnly }}
            question_id={id}
            answerEvaluation={answer_correct}
            setSelectedOption={selectOption}
            selectedOption={selectedOption}
            key={`question_${id}-option_${option.id}`}
            answerEvaluated={answerEvaluated}
          />
        ))}
      </div>
    </div>
  );
};
export default BooleanQuestion;

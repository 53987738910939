import LowImage from 'images/skill_levels/results-low.png';
import MediumImage from 'images/skill_levels/results-medium.png';
import HighImage from 'images/skill_levels/results-high.png';

export const SKILL_LEVEL_MAP = {
  0: "Skill Check",
  1: "Weak",
  2: "Needs Improvement",
  3: "Strong",
};

export const SKILL_LEVEL_CLASS = {
  0: "none",
  1: "low",
  2: "medium",
  3: "high",
};

export const SKILL_LEVEL_IMAGES = {
  1: LowImage,
  2: MediumImage,
  3: HighImage,
};

export const optionalityClass = (skillLevel, semesterMandatorySkillLevel) => (skillLevel <= semesterMandatorySkillLevel ? 'mandatory' : 'optional');

export const optionalityText = (skillLevel, semesterMandatorySkillLevel) => (skillLevel <= semesterMandatorySkillLevel ? 'Mandatory' : 'Optional');

export const imageForSkillLevel = (skillLevel) => SKILL_LEVEL_IMAGES[skillLevel];

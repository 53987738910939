import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import axiosClient from '../../../lib/api/axiosClient';
import { getLocalStorage, removeLocalStorage } from '../../../lib/utils/localStorage';
import Form from '../../DesignSystem/Forms/Form';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import Field from '../../DesignSystem/Forms/Field';
import Toasts from '../../DesignSystem/Toasts';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import NewToKubicle from '../../Common/Components/NewToKubicle';

const RequestPasswordReset = ({ isTokenExpired = false }) => {
  const { t } = useTranslation('RequestPasswordReset');
  const [data, setData] = useState({
    email: '',
  });
  const [resetDone, setResetDone] = useState(false);

  const [errors, setErrors] = useState({});
  const [networkError, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  useEffect(() => {
    setData(oldData => ({ ...oldData, email: getLocalStorage('userEmail') || '' }));
  }, []);

  const submit = async () => {
    if (actionInProgress) return;

    setErrors({});
    setNetworkError(null);
    setActionInProgress(true);

    if (!data.email || !data.email.includes('@')) {
      setErrors({ email: t('emailRequired') });
      setActionInProgress(false);
      return;
    }

    try {
      const res = await axiosClient.post('/api/v1/password.json', {
        user: {
          email: data.email,
        },
      }, { timeout: 20000 });
      setActionInProgress(false);

      if (res.data.success) setResetDone(true);
    } catch (error) {
      if (error.response?.data) {
        setErrors(error.response.data.errors);
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const handleChange = (name, value) => {
    setData(oldData => {
      oldData[name] = value;
      return oldData;
    });
  };

  const onChangeAccountClick = (e) => {
    e.preventDefault();
    removeLocalStorage('userEmail');
    removeLocalStorage('userSsoUrl');
    window.location.href = "/sign-in";
  };

  const renderFields = () => (
    <>
      <Field
        type="text"
        name="email"
        label={t('email')}
        onChange={handleChange}
        value={data.email}
        error={errors.email}
        containerClass="is-full-width"
        required
      />
    </>
  );

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit is-full-width ${actionInProgress ? 'is-inactive' : ''}`}
      disabled={actionInProgress}
      value={t('sendInstructions')}
    />
  );

  const renderForm = () => (
    <Form onSubmit={submit} disabled={actionInProgress}>
      <h2>{t('recoverPassword')}</h2>
      <p>{t('enterEmail')}</p>
      <Fieldset isSplit>
        {renderFields()}
        {renderSubmit()}
      </Fieldset>
      <p>
        <a id="change_account" onClick={onChangeAccountClick}>{t('signIn')}</a>
      </p>
    </Form>
  );

  const renderConfirmation = () => (
    <>
      <h2>{t('checkEmail')}</h2>
      <p>
        <Trans t={t} i18nKey="resetSent" values={{ email: data.email }}>
          <span className="u-bold" />
        </Trans>
      </p>
      <p>{t('checkSpam')}</p>
      <p>
        <a id="return_to_login" href="/sign-in">{t('returnToLogin')}</a>
      </p>
    </>
  );

  const renderBlurb = () => (
    <NewToKubicle marketing_site_url="https://kubicle.com" />
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
        {isTokenExpired && (
          <Toast
            state={ToastStates.ERROR}
            title={t('tokenExpiredTitle')}
          >
            <p>{t('tokenExpiredMessage')}</p>
          </Toast>
        )}
      </Toasts>
      <div className="SplitLayout SplitLayout--tabletReverse">
        <div className="SplitLayout-slide SplitLayout-slide--marketing">
          <div className="SplitLayout-slideInner">
            {renderBlurb()}
          </div>
        </div>
        <div className="SplitLayout-slide">
          <div className="SplitLayout-slideInner">
            {resetDone ? renderConfirmation() : renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestPasswordReset;

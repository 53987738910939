import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Toasts from '../../DesignSystem/Toasts';
import Form from '../../DesignSystem/Forms/Form';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import NewToKubicle from '../../Common/Components/NewToKubicle';
import Field from '../../DesignSystem/Forms/Field';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import axiosClient from '../../../lib/api/axiosClient';

const PasswordReset = ({
  resetPasswordToken,
  email,
}) => {
  const { t } = useTranslation('PasswordReset');

  const [data, setData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [formError, setFormError] = useState();
  const [networkError, setNetworkError] = useState();
  const [actionInProgress, setActionInProgress] = useState(false);

  const handleSubmit = async () => {
    if (actionInProgress) return;

    setFormError(null);
    setNetworkError(null);
    setActionInProgress(true);

    try {
      const res = await axiosClient.put('/api/v1/password.json', {
        user: {
          reset_password_token: resetPasswordToken,
          password: data.password,
          password_confirmation: data.passwordConfirmation,
        },
      });

      setActionInProgress(false);
      if (res.data.redirect) window.location.href = res.data.redirect;
    } catch (error) {
      if (error.response?.data) {
        const firstError = Object.values(error.response.data.errors)?.[0];
        setFormError(firstError);
      } else {
        setNetworkError(`Error: ${error.message}`);
      }
      setActionInProgress(false);
    }
  };

  const handleChange = (name, value) => {
    setData(oldData => ({
      ...oldData,
      [name]: value,
    }));
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      <h2>{t('resetYourPasswordTitle')}</h2>
      <p>
        <Trans t={t} i18nKey="resetYourPasswordParagraph" values={{ email }}>
          <span className="u-bold" />
        </Trans>
      </p>
      <Fieldset>
        <>
          <Field
            type="password"
            name="password"
            label={t('passwordLabel')}
            onChange={handleChange}
            value={data.password}
            isError={!!formError}
            containerClass="is-full-width"
            required
          />

          <Field
            type="password"
            name="passwordConfirmation"
            label={t('passwordConfirmationLabel')}
            onChange={handleChange}
            value={data.passwordConfirmation}
            error={formError}
            containerClass="is-full-width"
            required
          />
        </>

        <input
          type="submit"
          className={`Form-item Button Button--primary Button--formSubmit is-full-width ${actionInProgress ? 'is-inactive' : ''}`}
          disabled={actionInProgress}
          value={t('submitButtonLabel')}
        />
      </Fieldset>
    </Form>
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <div className="SplitLayout SplitLayout--tabletReverse">
        <div className="SplitLayout-slide SplitLayout-slide--marketing">
          <div className="SplitLayout-slideInner">
            <NewToKubicle marketing_site_url="https://kubicle.com" />
          </div>
        </div>
        <div className="SplitLayout-slide">
          <div className="SplitLayout-slideInner">
            {renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;

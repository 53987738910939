import React from 'react';

const ExpectedProgressIcon = ({ className = '' }) => (
  <div data-tip data-for="expected-progress" className="expected-progress-icon">
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49.47"
      height="47.93"
      viewBox="0 0 49.47 47.93"
    >
      <g id="Expected_Progress" data-name="Expected Progress" transform="translate(0 0.5)">
        <g id="Group_1470" data-name="Group 1470" transform="translate(-177.795 -38.07)">
          <g id="one-man-walking" transform="translate(180.138 38.07)">
            <path
              id="Path_2050"
              data-name="Path 2050"
              d="M46.429,36.095l-5.457-3.473a2.427,2.427,0,0,1-.865-1.2L38.6,25.449l0-.01a1.487,1.487,0,0,0-1.461-1.225h-7.35a1.469,1.469,0,0,0-.892.3,1.732,1.732,0,0,0-.151.089l-5.23,3.726a2.2,2.2,0,0,0-.824,2.045l1.62,8.653a1.67,1.67,0,0,0,1.6,1.325l.832-.045A1.364,1.364,0,0,0,28.023,38.7l-1.13-6.674a1.23,1.23,0,0,1,.442-1.06l.96-.675v9.786c-.012.033-.029.063-.04.1L23.406,55.361A2.379,2.379,0,0,0,25.06,58.4l.324.088a2.5,2.5,0,0,0,.653.087A2.6,2.6,0,0,0,28.5,56.785l4.6-14.417h.382l.84,4.174a17.29,17.29,0,0,1,.274,2.465l.189,8.526a1.607,1.607,0,0,0,1.627,1.608,1.752,1.752,0,0,0,.242-.017l1.6-.222a1.937,1.937,0,0,0,1.636-1.981l-.448-8.561c-.037-.72-.148-1.888-.247-2.606l-.624-4.534a1.464,1.464,0,0,0,.043-.341V35.943l5.868,3.378a1.5,1.5,0,0,0,.746.2,1.518,1.518,0,0,0,1.3-.728l.386-.642A1.523,1.523,0,0,0,46.429,36.095Z"
              transform="translate(0 -12.213)"
              fill="#cddbe5"
            />
            <path
              id="Path_2051"
              data-name="Path 2051"
              d="M37.983,10.741a5.371,5.371,0,1,0-5.37-5.371A5.377,5.377,0,0,0,37.983,10.741Z"
              transform="translate(-5.022)"
              fill="#cddbe5"
            />
          </g>
          <g id="one-man-walking-2" data-name="one-man-walking" transform="translate(155.138 38.07)" opacity="0.5">
            <path
              id="Path_2050-2"
              data-name="Path 2050"
              d="M46.429,36.095l-5.457-3.473a2.427,2.427,0,0,1-.865-1.2L38.6,25.449l0-.01a1.487,1.487,0,0,0-1.461-1.225h-7.35a1.469,1.469,0,0,0-.892.3,1.732,1.732,0,0,0-.151.089l-5.23,3.726a2.2,2.2,0,0,0-.824,2.045l1.62,8.653a1.67,1.67,0,0,0,1.6,1.325l.832-.045A1.364,1.364,0,0,0,28.023,38.7l-1.13-6.674a1.23,1.23,0,0,1,.442-1.06l.96-.675v9.786c-.012.033-.029.063-.04.1L23.406,55.361A2.379,2.379,0,0,0,25.06,58.4l.324.088a2.5,2.5,0,0,0,.653.087A2.6,2.6,0,0,0,28.5,56.785l4.6-14.417h.382l.84,4.174a17.29,17.29,0,0,1,.274,2.465l.189,8.526a1.607,1.607,0,0,0,1.627,1.608,1.752,1.752,0,0,0,.242-.017l1.6-.222a1.937,1.937,0,0,0,1.636-1.981l-.448-8.561c-.037-.72-.148-1.888-.247-2.606l-.624-4.534a1.464,1.464,0,0,0,.043-.341V35.943l5.868,3.378a1.5,1.5,0,0,0,.746.2,1.518,1.518,0,0,0,1.3-.728l.386-.642A1.523,1.523,0,0,0,46.429,36.095Z"
              transform="translate(0 -12.213)"
              fill="#cddbe5"
            />
            <path
              id="Path_2051-2"
              data-name="Path 2051"
              d="M37.983,10.741a5.371,5.371,0,1,0-5.37-5.371A5.377,5.377,0,0,0,37.983,10.741Z"
              transform="translate(-5.022)"
              fill="#cddbe5"
            />
          </g>
          <g id="one-man-walking-3" data-name="one-man-walking" transform="translate(167.138 38.07)">
            <path
              id="Path_2050-3"
              data-name="Path 2050"
              d="M46.429,36.095l-5.457-3.473a2.427,2.427,0,0,1-.865-1.2L38.6,25.449l0-.01a1.487,1.487,0,0,0-1.461-1.225h-7.35a1.469,1.469,0,0,0-.892.3,1.732,1.732,0,0,0-.151.089l-5.23,3.726a2.2,2.2,0,0,0-.824,2.045l1.62,8.653a1.67,1.67,0,0,0,1.6,1.325l.832-.045A1.364,1.364,0,0,0,28.023,38.7l-1.13-6.674a1.23,1.23,0,0,1,.442-1.06l.96-.675v9.786c-.012.033-.029.063-.04.1L23.406,55.361A2.379,2.379,0,0,0,25.06,58.4l.324.088a2.5,2.5,0,0,0,.653.087A2.6,2.6,0,0,0,28.5,56.785l4.6-14.417h.382l.84,4.174a17.29,17.29,0,0,1,.274,2.465l.189,8.526a1.607,1.607,0,0,0,1.627,1.608,1.752,1.752,0,0,0,.242-.017l1.6-.222a1.937,1.937,0,0,0,1.636-1.981l-.448-8.561c-.037-.72-.148-1.888-.247-2.606l-.624-4.534a1.464,1.464,0,0,0,.043-.341V35.943l5.868,3.378a1.5,1.5,0,0,0,.746.2,1.518,1.518,0,0,0,1.3-.728l.386-.642A1.523,1.523,0,0,0,46.429,36.095Z"
              transform="translate(0 -12.213)"
              fill="#fff"
              stroke="#003056"
              strokeWidth="1"
              strokeDasharray="2 1"
            />
            <path
              id="Path_2051-3"
              data-name="Path 2051"
              d="M37.983,10.741a5.371,5.371,0,1,0-5.37-5.371A5.377,5.377,0,0,0,37.983,10.741Z"
              transform="translate(-5.022)"
              fill="#fff"
              stroke="#003056"
              strokeWidth="1"
              strokeDasharray="2 1"
            />
          </g>
        </g>
      </g>
    </svg>

  </div>
);

export default ExpectedProgressIcon;

import React, { useEffect, useState } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Trans, useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import client from '../../../lib/api/axiosClient';
import DashboardTab from './DashboardTab';
import { getLocalStorage, setLocalStorage } from '../../../lib/utils/localStorage';
import LearningPathEmptyState from './LearningPathEmptyState';
import Modal from '../../DesignSystem/Modal';
import ajaxError from '../../../lib/utils/ajaxError';

const LearnDashboard = ({
  learning_group_id, user, greeting_image, uncelebrated_milestone, params,
  show_calendar_cta, has_library_access: hasLibraryAccess,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const [learningGroupId] = useState(learning_group_id);
  const [semesters, setSemesters] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [savedContents, setSavedContents] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [allContentCompleted, setAllContentCompleted] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarksLoaded, setBookmarksLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [learningPathEmptyState, setLearningPathEmptyState] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [pathToRemove, setPathToRemove] = useState();
  const [newLearningPathEnrollment, setNewLearningPathEnrollment] = useState(false);
  const [introLottieDisplayed, setIntroLottieDisplayed] = useState(false);
  const [showUncelebratedMilestone, setShowUncelebratedMilestone] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const contentRequests = [];

    if (learning_group_id !== null) {
      const requestSemesters = client.get(`/api/v1/learning_groups/${learning_group_id}/semesters.json?user_id=${user.id}`, { signal: abortController.signal });
      contentRequests.push(requestSemesters);

      requestSemesters
        .then(response => setSemesters(response.data.data))
        .catch(error => {
          if (!error.response?.status) return console.error(error);

          ajaxError(error.response.status);
        });
    }
    const requestLearningPaths = client.get(`/api/v1/users/${user.id}/user_learning_paths.json`, { signal: abortController.signal });

    contentRequests.push(requestLearningPaths);

    requestLearningPaths
      .then(response => setLearningPaths(response.data.data))
      .catch(error => {
        if (!error.response?.status) return console.error(error);

        ajaxError(error.response.status);
      });

    Promise
      .all(contentRequests)
      .then(() => setIsLoading(false))
      .catch(() => {});
  }, [learningGroupId]);

  useEffect(() => {
    setLearningPathEmptyState(semesters.length === 0 && learningPaths.length === 0);

    const learningPathsFinished = !learningPaths.some(lp => lp.progress < 100);
    const semestersFinished = !semesters.some(s => s.progress < 100);
    setAllContentCompleted(learningPathsFinished && semestersFinished);
  }, [semesters, learningPaths]);

  useEffect(() => {
    if (!isLoading) getTabsList();
  }, [isLoading]);

  useEffect(() => {
    if (!introLottieDisplayed && params && params.enrollment && tabIndex === indexOfTab(params.tab)) setNewLearningPathEnrollment(true);
    else setNewLearningPathEnrollment(false);
  }, [tabIndex, tabs, introLottieDisplayed]);

  const renderIcon = semester => {
    let iconClass = 'custom-icon icon-semester icon-color-yellow';
    if (semester.overdue) iconClass = 'fa fa-exclamation-circle red-icon';
    else if (semester.is_complete) iconClass = 'fa fa-check green-icon';
    else if (semester.gated) iconClass = 'fa fa-lock';

    return (<i className={iconClass} />);
  };

  const renderLearningPathIcon = learningPath => {
    if (learningPath.progress === 100) return (<i className="fa fa-check green-icon" />);

    return <i className="custom-icon icon-learning-path icon-color-blue" />;
  };

  const greeting = () => {
    const hour = new Date().getHours();
    if (hour >= 0 && hour <= 11) return 'goodMorning';
    if (hour >= 12 && hour <= 17) return 'goodAfternoon';

    return 'goodEvening';
  };

  const renderTabContent = semester => (
    <div>
      <span
        className="tab-title"
        data-tip
        data-for={`semester-${semester.id}`}
      >
        {renderIcon(semester)}
&nbsp;
        {semester.name}
      </span>
      <ReactTooltip id={`semester-${semester.id}`} place="top" type="light" effect="solid">
        {renderTooltipContent(semester)}
      </ReactTooltip>
    </div>
  );

  const getSemesterData = (semester_id, reload = false) => {
    const semesterIndex = semesters.findIndex(semester => semester.id === semester_id);
    if (!reload && semesters[semesterIndex].data != null) return;
    return client
      .get(`/api/v1/learning_groups/${learning_group_id}/semesters/${semester_id}.json?user_id=${user.id}`)
      .then(response => {
        setSemesters(currentSemesters => currentSemesters.map(s => {
          if (s.id === semester_id) {
            s.data = response.data;
            s.loaded = true;
          }
          return s;
        }));
      });
  };

  const getLearningPathData = (learning_path_id, reload = false) => {
    const learningPathIndex = learningPaths.findIndex(learningPath => learningPath.id === learning_path_id);
    if (!reload && learningPaths[learningPathIndex].loaded) return;

    client
      .get(`/api/v1/users/${user.id}/user_learning_paths/${learning_path_id}.json`)
      .then(response => {
        setLearningPaths(currentLearningPaths => currentLearningPaths.map(lp => {
          if (lp.id === learning_path_id) {
            lp.data = response.data;
            lp.loaded = true;
          }
          return lp;
        }));
      });
  };

  const getSavedContentData = (reload = false) => {
    if (!reload && savedContents.data != null) return;
    client
      .get(`/api/v1/users/${user.id}/user_saved_contents.json`)
      .then(response => {
        setSavedContents({ data: response.data, loaded: true });
      });
  };

  const apiCalls = {
    getBookmarks: () => {
      if (bookmarksLoaded) return;
      client
        .get(`/api/v1/users/${user.id}/bookmarks.json`)
        .then(response => {
          setBookmarks(response.data.data.bookmarks);
          setBookmarksLoaded(true);
        });
    },
    skipContent: content => new Promise(resolve => {
      client
        .get(`/api/v1/users/${user.id}/${content.content_type.toLowerCase()}s/${content.id}/skip.json`)
        .then(response => resolve(response))
        .catch(error => {
          if (error.response?.status !== 401) return console.error(error);

          window.location.reload();
        });
    }),
    restoreContent: content => new Promise(resolve => {
      client
        .get(`/api/v1/users/${user.id}/${content.content_type.toLowerCase()}s/${content.id}/restore.json`)
        .then(response => resolve(response))
        .catch(error => {
          if (error.response?.status !== 401) return console.error(error);

          window.location.reload();
        });
    }),
    removeFromSaved: content => new Promise(resolve => {
      client
        .delete(`/api/v1/users/${user.id}/user_saved_${content.content_type.toLowerCase()}s/${content.id}`)
        .then(response => resolve(response))
        .catch(error => {
          if (error.response?.status !== 401) return console.error(error);

          window.location.reload();
        });
    }),
    unenrollFromLearningPath: learningPathId => {
      setPathToRemove(learningPathId);
    },
  };

  const removeLearningPath = () => {
    client
      .post(`/api/v1/users/${user.id}/user_learning_paths/${pathToRemove}/unenroll.json`)
      .then(response => window.location.assign(response.data.redirect));
    setPathToRemove(null);
  };

  const renderModals = () => (
    <>
      {(uncelebrated_milestone && showUncelebratedMilestone) && (
        <Modal showConfetti pulse closeCallback={() => setShowUncelebratedMilestone(false)}>
          <div className="Modal-content">
            <h4 className="Modal-title">{t('milestoneCompleteHeading', { name: user.first_name })}</h4>
            <img className="Modal-image" src="/assets/learn_screen/milestone_complete.png" />
            <div className="Modal-description">
              <div>{uncelebrated_milestone.remaining > 0 ? t('milestoneCompleteDescription', { milestoneName: uncelebrated_milestone.name }) : t('semesterCompleteDescription')}</div>
            </div>
            {uncelebrated_milestone.remaining > 0 && <div className="Modal-ribbon">{t('milestoneCompleteRibbon', { count: uncelebrated_milestone.remaining })}</div>}
          </div>
          <div className="Modal-actions">
            <a onClick={() => setShowUncelebratedMilestone(false)} className="Modal-action Button Button--primary">{t('milestoneCompleteButton')}</a>
          </div>
        </Modal>
      )}
      {pathToRemove && (
        <Modal closeCallback={() => setPathToRemove(null)}>
          <div className="Modal-content">
            <h4 className="Modal-title">{t('removePathTitle')}</h4>
            <img className="Modal-image" src="/assets/learn_screen/learning_path_unenroll.png" />
            <div className="Modal-description">
              <div>{t('removePathDescription')}</div>
              <div>
                <Trans t={t} i18nKey="progressWillBeSaved">
                  Text
                  <span className="u-bold">Text</span>
                  Text
                </Trans>
              </div>
            </div>
          </div>
          <div className="Modal-actions">
            <a onClick={removeLearningPath} className="Modal-action Button Button--primary">{t('removePath')}</a>
            <a onClick={() => setPathToRemove(null)} className="Modal-action Button Button--secondary">{t('cancel')}</a>
          </div>
        </Modal>
      )}
    </>
  );

  const renderTooltipContent = semester => {
    if (semester.gated) {
      return (
        <>
          <strong>{t('semesterRestricted')}</strong>
          <br />
          {t('semesterToComplete')}
          <br />
          {t('toUnlockContent')}
        </>
      );
    } if (semester.progress_in_semester === 'completed') {
      return (
        <>
          <strong>{semester.name}</strong>
          <br />
          {t('semesterCompleted')}
        </>
      );
    } if (semester.end_date !== null) {
      if (semester.overdue) {
        let remainingDays = moment(semester.end_date).diff(moment(), 'days');
        if (remainingDays < 0) remainingDays = 0;

        return (
          <>
            <strong>{semester.name}</strong>
            <br />
            {t('deadline')}
            :
            {moment(semester.end_date).format('Do MMM YYYY')}
            <br />
            <i
              className="fa fa-exclamation-circle red-icon"
            />
            {t(`progress.${semester.progress_in_semester}`)}
            {' '}
            -
            {' '}
            {remainingDays}
            {' '}
            {t('dayRemaining', { count: remainingDays })}
          </>
        );
      }
      return (
        <>
          <strong>{semester.name}</strong>
          <br />
          {t('deadline')}
          :
          {moment(semester.end_date).format('Do MMM YYYY')}
        </>
      );
    }
    return (
      <>
        <strong>{semester.name}</strong>
      </>
    );
  };

  const getTabsList = () => {
    const arr = [];

    semesters.forEach(semester => {
      arr.push({ name: `semester-${semester.id}`, current: semester.current });
    });

    learningPaths.forEach(learningPath => {
      arr.push({ name: `learning-path-${learningPath.id}`, current: learningPath.current });
    });

    if (learningPathEmptyState) arr.push({ name: 'learning-path-empty-state', current: false });

    arr.push({ name: 'saved-content', current: false });

    setTabs(arr);
  };

  useEffect(() => {
    if (!isLoading) focusTab();
  }, [tabs]);

  const indexOfTab = tabName => tabs.findIndex(tab => tab.name === tabName);

  const focusTab = () => {
    let tabIndex;
    const localStorageTabIndex = tabs.findIndex(tab => tab.name === getLocalStorage('activeTab'));
    const firstCurrentTabIndex = tabs.findIndex(tab => tab.current === true);

    if (params.tab !== undefined) tabIndex = tabs.findIndex(tab => tab.name === params.tab);
    else if (localStorageTabIndex > -1) tabIndex = localStorageTabIndex;
    else if (firstCurrentTabIndex > -1) tabIndex = firstCurrentTabIndex;

    if (tabIndex > -1) setTabIndex(tabIndex);
  };

  useEffect(() => {
    if (tabIndex > -1 && tabs[tabIndex]) setLocalStorage('activeTab', tabs[tabIndex].name);
  }, [tabIndex]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="learn-screen-container">
          <div className="semester-content-wrapper semester-with-stats">
            <KubicleLoading />
          </div>
        </div>
      );
    }

    return (
      <>
        <h1 className="greetings">
          {t(greeting(), { name: user.first_name })}
          {' '}
          <img
            className="hand-wave"
            src={greeting_image}
          />
        </h1>
        <Tabs selectedIndex={tabIndex || 0} onSelect={index => setTabIndex(index)}>
          <TabList>
            {learningPathEmptyState && (
              <Tab
                className="react-tabs__tab is-learningPath"
                key="learning-path-empty-state-tab"
              >
                <div>
                  <span className="tab-title">
                    <i className="custom-icon icon-learning-path icon-color-blue" />
&nbsp;
                    {t('learningPaths')}
                  </span>
                </div>
              </Tab>
            )}
            {
              semesters.map(semester => (
                <Tab
                  className="react-tabs__tab is-semester"
                  key={`semester-${semester.id}-tab`}
                  disabled={semester.gated}
                >
                  {renderTabContent(semester)}
                </Tab>
              ))
            }
            {learningPaths.map(learningPath => (
              <Tab className="react-tabs__tab is-learningPath" key={`learning-path-${learningPath.id}-tab`}>
                <div>
                  <span className="tab-title">
                    {renderLearningPathIcon(learningPath)}
&nbsp;
                    {learningPath.name}
                  </span>
                </div>
              </Tab>
            ))}
            <Tab className="react-tabs__tab is-savedContent" key="saved-contents-tab">
              <div>
                <span className="tab-title">
                  <i className="custom-icon icon-saved-courses icon-color-green" />
&nbsp;
                  {t('savedContents')}
                </span>
              </div>
            </Tab>
          </TabList>
          {learningPathEmptyState && (
            <TabPanel key="learning-path-empty-state">
              <LearningPathEmptyState />
            </TabPanel>
          )}
          {
            semesters.map(semester => (
              <TabPanel key={semester.id}>
                <DashboardTab
                  learning_group_id={learning_group_id}
                  user_id={user.id}
                  tabType="semester"
                  tabData={semester}
                  tabName={semester.name}
                  getData={(reload = false) => getSemesterData(semester.id, reload)}
                  apiCalls={apiCalls}
                  hasLibraryAccess={hasLibraryAccess}
                  allContentCompleted={allContentCompleted}
                  showCalendarCta={show_calendar_cta && semester.end_date !== null}
                />
              </TabPanel>
            ))
          }
          {learningPaths && learningPaths.map(learningPath => (
            <TabPanel key={`learning-path-${learningPath.id}`}>
              <DashboardTab
                user_id={user.id}
                getData={(reload = false) => getLearningPathData(learningPath.id, reload)}
                tabData={learningPath}
                tabName={learningPath.name}
                tabType="learning_path"
                apiCalls={apiCalls}
                hasLibraryAccess={hasLibraryAccess}
                allContentCompleted={allContentCompleted}
                newLearningPathEnrollment={newLearningPathEnrollment}
                showNewLearningPath={() => {
                  setNewLearningPathEnrollment(false);
                  setIntroLottieDisplayed(true);
                }}
                params={params}
              />
            </TabPanel>
          ))}
          <TabPanel key={0}>
            <DashboardTab
              user_id={user.id}
              getData={(reload = false) => getSavedContentData(reload)}
              tabData={savedContents}
              tabName={t('savedContents')}
              tabType="saved"
              bookmarks={bookmarks}
              apiCalls={apiCalls}
              hasLibraryAccess={hasLibraryAccess}
              allContentCompleted={allContentCompleted}
            />
          </TabPanel>
        </Tabs>
      </>
    );
  };

  return (
    <>
      {renderContent()}
      {renderModals()}
    </>
  );
};

export default LearnDashboard;

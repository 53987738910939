import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import client from '../../../lib/api/axiosClient';

const LottieContainer = ({
  src, onAnimationComplete, className = '', cover = false, loop = true, background = 'transparent', children,
}) => {
  const [animData, setAnimData] = useState();
  const [showSelf, setShowSelf] = useState(true);

  useEffect(() => {
    client.get(src).then(jsonRes => {
      setAnimData(jsonRes.data);
    }).catch(forceComplete);
  }, [src]);

  const forceComplete = () => {
    if (typeof onAnimationComplete === 'function') return onAnimationComplete();
    setShowSelf(false);
  };

  if (showSelf === false) return null;

  return (
    <div className={`lottie-container ${cover ? 'cover' : 'contain'} ${className}`}>
      <Lottie
        className="lottie-player"
        animationData={animData}
        background={background}
        play
        loop={loop}
        onComplete={forceComplete}
      />
      {children}
    </div>
  );
};

export default LottieContainer;

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import axiosClient from '../../../lib/api/axiosClient';
import Form from '../../DesignSystem/Forms/Form';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import Field from '../../DesignSystem/Forms/Field';
import Toasts from '../../DesignSystem/Toasts';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Checkbox from '../../DesignSystem/Forms/Fields/Checkbox';

const IndividualTrial = () => {
  const { t } = useTranslation('IndividualTrial');
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    phone: '',
    terms_privacy_consent: false,
    receive_communications_consent: false,
  });

  const [errors, setErrors] = useState({});
  const [networkError, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  const submit = async () => {
    if (actionInProgress) return;

    setErrors({});
    setNetworkError(null);
    setActionInProgress(true);

    try {
      const res = await axiosClient.post('/api/v1/signup.json', {
        user: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          email_confirmation: data.email,
          password: data.password,
          password_confirmation: data.password,
          phone: data.phone,
          terms_privacy_consent: data.terms_privacy_consent,
          receive_communications_consent: data.receive_communications_consent,
        },
      }, { timeout: 20000 });
      setActionInProgress(false);

      if (res.data.redirect) window.location.href = res.data.redirect;
    } catch (error) {
      if (error.response?.data) {
        setErrors(error.response.data.errors);
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const handleChange = (name, value) => {
    setData(oldData => {
      oldData[name] = value;
      return oldData;
    });
  };

  const renderTermsLabel = () => (
    <Trans t={t} i18nKey="termsConsent">
      <a target="_blank" href="/terms" />
      <a target="_blank" href="/privacy" />
    </Trans>
  );

  const renderConsent = () => (
    <>
      <Checkbox
        id="terms_privacy_consent"
        name="terms_privacy_consent"
        containerClass="is-full-width"
        label={renderTermsLabel()}
        required
        onChange={handleChange}
        value={data.terms_privacy_consent}
        error={errors.terms_privacy_consent}
      />
      <Checkbox
        id="receive_communications_consent"
        name="receive_communications_consent"
        containerClass="is-full-width"
        label={t('commsConsent')}
        required
        onChange={handleChange}
        value={data.receive_communications_consent}
        error={errors.receive_communications_consent}
      />
    </>
  );

  const renderFields = () => (
    <>
      <Field
        type="text"
        name="first_name"
        label={t('firstName')}
        onChange={handleChange}
        value={data.first_name}
        error={errors.first_name}
        required
      />
      <Field
        type="text"
        name="last_name"
        label={t('lastName')}
        onChange={handleChange}
        value={data.last_name}
        error={errors.last_name}
        required
      />
      <Field
        type="text"
        name="email"
        label={t('email')}
        onChange={handleChange}
        value={data.email}
        error={errors.email}
        containerClass="is-full-width"
        required
      />
      <Field
        type="password"
        name="password"
        label={t('password')}
        onChange={handleChange}
        value={data.password}
        error={errors.password}
        containerClass="is-full-width"
        required
      />
      <Field
        type="tel"
        name="phone"
        label={t('phone')}
        onChange={handleChange}
        value={data.phone}
        error={errors.phone}
        containerClass="is-full-width"
        required
      />
    </>
  );

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit is-full-width ${actionInProgress ? 'is-inactive' : ''}`}
      id="email_sign_up"
      disabled={actionInProgress}
      value={t('continueToPayment')}
    />
  );

  const renderBlurb = () => (
    <div className="Blurb u-textWhite">
      <h2 className="Blurb-title">{t('blurbHeading')}</h2>
      <hr className="Separator Separator--blurb" />
      <div className="Blurb-descriptors">
        <div className="Descriptor u-textWhite">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">
            <Trans t={t} i18nKey="blurbDescriptor1">
              <span className="u-bold" />
            </Trans>
          </span>
        </div>
        <div className="Descriptor u-textWhite">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">
            <Trans t={t} i18nKey="blurbDescriptor2">
              <span className="u-bold" />
            </Trans>
          </span>
        </div>
        <div className="Descriptor u-textWhite">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">
            <Trans t={t} i18nKey="blurbDescriptor3">
              <span className="u-bold" />
            </Trans>
          </span>
        </div>
        <div className="Descriptor u-textWhite">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">
            <Trans t={t} i18nKey="blurbDescriptor4">
              <span className="u-bold" />
            </Trans>
          </span>
        </div>
      </div>
      <hr className="Separator Separator--blurb" />
      <p className="Blurb-text">
        {t('teamTraining')}
        <br />
        <a className="u-textWhite u-bold u-underline" href="https://kubicle.com/b2b-demo-request/">
          {t('bookDemo')}
        </a>
      </p>
    </div>
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <div className="SplitLayout SplitLayout--tabletReverse">
        <div className="SplitLayout-slide SplitLayout-slide--marketing">
          <div className="SplitLayout-slideInner">
            {renderBlurb()}
          </div>
        </div>
        <div className="SplitLayout-slide">
          <div className="SplitLayout-slideInner">
            <Form onSubmit={submit} disabled={actionInProgress}>
              <h2>{t('accountDetails')}</h2>
              <Fieldset isSplit>
                {renderFields()}
                {renderConsent()}
                {renderSubmit()}
              </Fieldset>
            </Form>
            <p className="m-top-m">
              <Trans t={t} i18nKey="privacy">
                <a href="/privacy" target="_blank" />
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualTrial;

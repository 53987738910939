import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressDonut from '../../Common/Components/ProgressDonut';
import ContentIcon from '../../Common/Components/ContentIcon';
import ContentActions from './ContentActions';
import Tooltip from '../../DesignSystem/Tooltip';

const Content = ({
  content,
  active,
  setActiveCallback,
  reloadCallback,
  apiCalls,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const startLink = `/${content.content_type.toLowerCase()}s/${content.slug}`;
  const examLink = `/exam_submissions/new?course_id=${content.id}`;

  return (
    <>
      <div className={`course-box course-box-learning-path ${active ? 'active' : ''}`}>
        <div className="course-box-header" onClick={setActiveCallback}>
          <div className="course-title course-title-flex">
            <ContentIcon content={content} />
            <span>
              {content.software_name}
              :
              {' '}
              {content.name}
            </span>
          </div>
          <div className="course-details">
            {content.learning_context === 'semester' && (
              <div className="course-status">
                {content.optional ? t('optional') : t('mandatory')}
              </div>
            )}
            <div className="course-progress">
              <ProgressDonut percentage={content.progress} />
              <span>
                {content.progress}
                %
              </span>
            </div>
            <div className="course-action">
              <i className={`fa ${active ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
            </div>
          </div>
        </div>
        <div className={`course-box-content ${active ? 'active' : ''}`}>
          <a
            href={(content.content_type === 'Course' && content.percent_watched === 100 && content.has_live_exam) ? examLink : startLink}
            className="course-image"
          >
            <img className="course-img" src={content.image} />
            <div className="course-image-overlay" style={{ backgroundColor: `${content.subject_colour}44` }}>
              <i className="fa fa-play-circle" style={{ color: content.subject_colour }} />
            </div>
            <span className="duration-pill">
              {content.duration}
              {' '}
              {t('minutesShort')}
            </span>
          </a>
          <div className="course-content">
            <p>{content.description}</p>
            <ContentActions content={content} apiCalls={apiCalls} reloadCallback={reloadCallback} />
          </div>
        </div>
      </div>
      <Tooltip
        id={`content-icon-${content.content_type}-${content.id}`}
        place="bottom"
        type="secondary"
      >
        {content.software_name}
        {' '}
        {content.content_type}
      </Tooltip>
    </>
  );
};

export default Content;

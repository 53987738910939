import React from 'react';
import { slugify } from '../../../lib/utils/string';

const Breadcrumbs = ({ sections, href }) => (
  <a href={href} className="breadcrumbs navy">
    {sections.map((section, index) => {
      const keySlug = slugify(section);
      if (index === 0) return <span key={`breadcrumb-${keySlug}`} className="breadcrumb">{section}</span>;

      if (index === sections.length - 1) {
        return (
          <React.Fragment key={`breadcrumb-${keySlug}`}>
            <span className="breadcrumb">&gt;</span>
            <span className="breadcrumb-active">{section}</span>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={`breadcrumb-${keySlug}`}>
          <span className="breadcrumb">&gt;</span>
          <span className="breadcrumb">{section}</span>
        </React.Fragment>
      );
    })}
  </a>
);

export default Breadcrumbs;

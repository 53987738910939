import React from 'react';
import PropTypes from 'prop-types';
import { imageForSkillLevel } from "../../../lib/utils/skillLevelType";
import SkillStatus from './SkillStatus';

const skillMessage = [
  `But don't worry, our training will strengthen you up in no time!`,
  `Looks like you could do with some help to strengthen up in this area.`,
  `You seem to have this one covered.`,
];

const selectionMessage = [`Strengthen your knowledge by adding this course to your program.`,
  `Improve your knowledge by adding this course to your program.`,
  `Skip this course or add it to your program to verify your knowledge.`];

function message(skillLevel, courseSelection) {
  if(courseSelection) return selectionMessage[skillLevel - 1];

  return skillMessage[skillLevel - 1];
}

const SkillLevelSummary = ({ skillLevel, summaryCTA, courseSelection }) => (
  <div className="skill-level-summary">
    <div className="image-section">
      <img src={imageForSkillLevel(skillLevel)} alt="" />
    </div>
    <div className="content-section">
      <h3>
        Your result:
        <SkillStatus skillLevel={skillLevel} />
      </h3>
      <p>{message(skillLevel, courseSelection)}</p>
      {summaryCTA}
    </div>
  </div>
);

SkillLevelSummary.propTypes = {
  skillLevel: PropTypes.number.isRequired,
};

export default SkillLevelSummary;

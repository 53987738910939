export const openWindowLeft = (link) => {
  const width = window.screen.width / 2;

  window.open(
    link,
    '_blank',
    `location=no,height=${window.screen.height},width=${width},top=0,left=0,scrollbars=yes,status=yes`
  );
};

export const openWindowRight = (link) => {
  const width = window.screen.width / 2;

  window.open(
    link,
    '_blank',
    `location=no,height=${window.screen.height},width=${width},top=0,left=${width},scrollbars=yes,status=yes`
  );
};

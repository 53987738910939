import React from 'react';
import { SKILL_LEVEL_CLASS } from "../../../lib/utils/skillLevelType";

export default function SkillCheckIcon({ skillLevel }) {
  return (
    <svg
      className={`skill-check-icon ${SKILL_LEVEL_CLASS[skillLevel]}-icon`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 73 72.75"
      enableBackground="new 0 0 73 72.75"
    >

      <circle cx="36.5" cy="36.25" r="34.015" />

      <g>
        <g>
          <rect x="19.62" y="34.868" className={skillLevel < 1 ? 'full' : 'empty'} width="8.504" height="15.632" />
        </g>
      </g>
      <g>

        <g>
          <rect x="32.332" y="27.051" className={skillLevel < 2 ? 'full' : 'empty'} width="8.504" height="23.449" />
        </g>
      </g>
      <g>
        <g>
          <rect x="45.665" y="19.234" className={skillLevel < 3 ? 'full' : 'empty'} width="8.504" height="31.266" />
        </g>
      </g>
    </svg>
  );
}

SkillCheckIcon.defaultProps = {
  skillLevel: 0,
};

import React from 'react';
import Option from "../../DesignSystem/Option";
import RecommendedLesson from "./RecommendedLesson";

const IncorrectAnswer = ({ answer }) => {
  const renderAnswer = () => {
    if (answer.type === 'OpenEndedQuestion') {
      return (
        <>
          <p className="u-bold">{answer.question_text}</p>
          <input className="TextInput is-incorrect" type="text" id={`incorrect-answer-${answer.question_id}`} readOnly value={answer.answer} />
        </>
      );
    } if (answer.type === 'BooleanQuestion') {
      return (
        <>
          <p className="u-bold">{answer.question_text}</p>
          <Option
            option={{ answer_text: "No" }}
            disabled
            id={`option-${answer.question_id}-no`}
            key={`option-${answer.question_id}-no`}
            name={`incorrect-answer-${answer.question_id}`}
            selected={answer.answer === "false"}
            correct={false}
          />
          <Option
            option={{ answer_text: "Yes" }}
            disabled
            id={`option-${answer.question_id}-yes`}
            key={`option-${answer.question_id}-yes`}
            name={`incorrect-answer-${answer.question_id}`}
            selected={answer.answer === "true"}
            correct={false}
          />
        </>
      );
    }
    return (
      <>
        <p className="u-bold">{answer.question_text}</p>
        {answer.options.map(option => (
          <Option
            option={option}
            selected={option.id === answer.answer.id}
            disabled
            id={`option-${answer.question_id}-${option.id}`}
            key={`option-${answer.question_id}-${option.id}`}
            name={`incorrect-answer-${answer.question_id}`}
            correct={false}
          />
        ))}
      </>
    );
  };

  const renderLessons = () => {
    if(answer.lessons.length === 0) return null;

    return (
      <>
        {answer.lessons.map(lesson => <RecommendedLesson key={`recommended-lesson-${answer.id}-${lesson.id}`} lesson={lesson} />)}
      </>
    );
  };

  return (
    <>
      <hr />
      {renderAnswer()}
      {renderLessons()}
    </>
  );
};

export default IncorrectAnswer;

import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressDonut from '../../Common/Components/ProgressDonut';
import Slide from '../../DesignSystem/Slide';

const LearningPathSlide = ({
  learningPathId, name, progress, togglePathEnrollment,
}) => {
  const { t } = useTranslation('LearningPaths');
  const [isRemoved, setIsRemoved] = useState(false);

  return (
    <>
      <Slide className="Slide--learningPath">
        <span className="u-ellipsis">
          {name}
        </span>
        <span>
          <ProgressDonut percentage={progress} />
          {` ${progress}%`}
        </span>
        <span className="u-textRight">
          {!isRemoved && (
            <a onClick={() => {
              togglePathEnrollment(false, learningPathId);
              setIsRemoved(true);
            }}
            >
              <i className="fa fa-minus-circle" aria-hidden="true" />
              {` ${t('remove')}`}
            </a>
          )}
          {isRemoved && (
            <a onClick={() => {
              togglePathEnrollment(true, learningPathId);
              setIsRemoved(false);
            }}
            >
              <i className="fa fa-undo" aria-hidden="true" />
              {` ${t('undo')}`}
            </a>
          )}
        </span>
      </Slide>
    </>
  );
};

export default LearningPathSlide;

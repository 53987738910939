import React from "react";
import { useTranslation } from "react-i18next";

const CTAPanel = ({
  user_id,
  learningPath,
  className,
  buttonOnly = false,
  renderEnrollButton,
}) => {
  const { t } = useTranslation('LearningPaths');

  const renderText = () => {
    if (learningPath.enrolled) {
      return (
        <>
          <h4 className="CTAPanel-title">{t('continueLearningTitle')}</h4>
          <div className="CTAPanel-tagline">
            <p>{t('alreadyEnrolledText1')}</p>
            <p>{t('alreadyEnrolledText2')}</p>
          </div>
        </>
      );
    }
    if (learningPath.complete) {
      return (
        <>
          <h4 className="CTAPanel-title">{t('congratulations')}</h4>
          <span className="CTAPanel-tagline">{t('completedLearningPath')}</span>
        </>
      );
    }
    return (
      <>
        <h4 className="CTAPanel-title">{t('getStarted')}</h4>
        <span className="CTAPanel-tagline">{t('learningPathsDescription')}</span>
      </>
    );
  };

  const renderDescriptors = () => {
    if (learningPath.enrolled) return <></>;

    if (learningPath.complete) {
      return (
        <div className="CTAPanel-descriptors">
          <div className="Descriptor Descriptor--CTAPanel">
            <i className="Descriptor-icon fa fa-check" />
            <span className="Descriptor-text">{t('downloadDiplomaText')}</span>
          </div>
          <div className="Descriptor Descriptor--CTAPanel">
            <i className="Descriptor-icon fa fa-check" />
            <span className="Descriptor-text">{t('shareDiplomaText')}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="CTAPanel-descriptors">
        <div className="Descriptor Descriptor--CTAPanel">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">{t('bannerDescriptor1')}</span>
        </div>
        <div className="Descriptor Descriptor--CTAPanel">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">{t('bannerDescriptor2')}</span>
        </div>
        <div className="Descriptor Descriptor--CTAPanel">
          <i className="Descriptor-icon fa fa-check" />
          <span className="Descriptor-text">{t('bannerDescriptor3')}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={`Panel CTAPanel ${className}`}>
      {!buttonOnly && renderText()}
      {!buttonOnly && renderDescriptors()}
      {renderEnrollButton('CTAPanel-button')}
      {user_id === null && <div className="CTAPanel-helpText">{t('pricingInfo')}</div>}
    </div>
  );
};

export default CTAPanel;

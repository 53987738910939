import React from 'react';
import AnswerIcon from "./AnswerIcon";
import { classNameForAnswer } from "./Reducers/AnswersReducer";

const MultipleChoiceQuestion = ({
  id, question, options, answersState, answersDispatch, index, readOnly,
}) => {
  const answer = answersState[index];

  return (
    <div className="question-container multiple-choice-question">
      <span className="question-number">
        {index + 1}
        .
      </span>
      <label className={`question-label ${readOnly ? 'read-only' : ''}`} htmlFor={`question_${id}`}>{question}</label>
      <div className="question-options">
        {options.map((option) => (
          <Option
            {...option}
            {...{
              answersState, answersDispatch, index, readOnly,
            }}
            answerEvaluation={answer.answerEvaluation}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;

const Option = ({
  id, option_text, question_id, answersState, answersDispatch, index, answerEvaluation, readOnly,
}) => {
  const onClick = () => {
    if(!readOnly) answersDispatch({ type: 'updateAnswer', index, value: id });
  };
  const { userAnswer } = answersState[index];
  const checked = userAnswer && userAnswer.toString() === id.toString();
  const inputId = `option_${id}`;

  const option = [
    <input id={inputId} type="radio" name={`question_${question_id}`} value={id} checked={checked} />,
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label onClick={onClick} htmlFor={inputId} className={`${checked ? classNameForAnswer(answerEvaluation) : ''} ${readOnly ? 'read-only' : ''}`}>
      {option_text}
      {' '}
      {checked && <AnswerIcon answerEvaluation={answerEvaluation} />}
    </label>,
  ];

  return option;
};

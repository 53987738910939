import React from 'react';
import PropTypes from 'prop-types';
import { SKILL_LEVEL_CLASS, SKILL_LEVEL_MAP } from '../../../lib/utils/skillLevelType';
import SkillCheckIcon from './SkillCheckIcon';

const SkillStatus = ({ skillLevel }) => (
  <div className={`skill-level skill-level-${SKILL_LEVEL_CLASS[skillLevel]}`}>
    <span className="skill-level-text">{SKILL_LEVEL_MAP[skillLevel]}</span>
    {' '}
    <SkillCheckIcon skillLevel={skillLevel} />
  </div>
);

SkillStatus.propTypes = {
  skillLevel: PropTypes.number.isRequired,
};

export default SkillStatus;

import React from 'react';
import Modal from '../../../DesignSystem/Modal';

const ModalDialog = ({
  onOk,
  okText,
  disableOk = false,
  onCancel,
  cancelText,
  children,
}) => {
  const okButton = (
    <a onClick={onOk} className="Modal-action Button Button--primary">
      {okText}
    </a>
  );
  const disabledOkButton = (
    <span className="Modal-action Button Button--primary is-inactive">
      {okText}
    </span>
  );

  return (
    <>
      <div className="Modals">
        <Modal className="Modal--dialog" allowClose={false}>
          <div className="Modal-content">
            {children}
          </div>
          <div className="Modal-actions">
            <a onClick={onCancel} className="Modal-action Button Button--secondary">{cancelText}</a>
            {disableOk ? disabledOkButton : okButton}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ModalDialog;

import { useTranslation } from "react-i18next";
import React from "react";

const RequiresSkillCheckPanel = ({ semesterId }) => {
  const { t } = useTranslation('LearnDashboard');

  return (
    <div className="empty-panel take-skill-check">
      <div className="instructions">
        <h2>{t(('completeSkillCheck'))}</h2>
        <p>{t("pleaseComplete")}</p>
        <a className="button small" href={`/skill_checks/new?semester_id=${semesterId}`}>{t('takeSkillCheck')}</a>
      </div>
      <div className="panel-image">
        <img src="/assets/take-skill-check.png" />
      </div>
    </div>
  );
};

export default RequiresSkillCheckPanel;

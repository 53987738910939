import React from 'react';
import { useTranslation } from "react-i18next";
import SkillLevelIcon from "../../Common/Components/SkillLevelIcon";

const UpcomingCourse = ({ course, index }) => {
  const { t } = useTranslation('Library');

  return (
    <div className="course-panel upcoming library-panel">
      <div className="panel-image">
        <img src={course.image} alt={course.name} />
      </div>
      <div className="panel-details">
        <p className="course-title">
          {index}
          .
          {course.name}
        </p>
        <p>
          {course.description}
        </p>
        <div className="course-details">
          <div className="coming-soon">
            <i className="fa fa-calendar-plus-o" aria-hidden="true" />
            <span>
              {t('comingSoon')}
            </span>
          </div>
          <SkillLevelIcon level_name={course.level} className="course-level" />
          <div className="course-credits">
            <i className="custom-icon icon-cpe-cpd icon-color-grey" aria-hidden="true" />
            <span>
              {t('cpdCredits')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingCourse;

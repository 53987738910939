import React from 'react';
import useToggle from "../../../lib/hooks/useToggle";

const Collapsible = ({ prompt, children }) => {
  const [messageVisible, toggleMessageVisible] = useToggle(false);

  return (
    <div className="collapsible">
      <a
        className="collapsible-prompt"
        onClick={() => {
          toggleMessageVisible();
        }}
      >
        {prompt}
        {' '}
        <i className={`fa fa-chevron-right ${messageVisible ? 'active' : ''}`} />
      </a>
      <div className={`collapsible-content ${messageVisible ? 'active' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Collapsible;

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import Tooltip from '../../DesignSystem/Tooltip';

const SemesterSummary = ({
  startDate, endDate, milestones, className = '',
}) => {
  const [totalHours, setTotalHours] = useState();
  const [totalCpdCredits, setTotalCpdCredits] = useState();
  const [totalWeeks, setTotalWeeks] = useState();
  const [hoursPerWeek, setHoursPerWeek] = useState();

  const { t } = useTranslation('SemesterForm');

  const milestoneDurationReducer = (acc, ms) => acc + ms.contents.reduce(contentDurationReducer, 0);
  const milestoneCpdReducer = (acc, ms) => acc + ms.contents.reduce(contentCpdReducer, 0);

  const contentDurationReducer = (acc, content) => acc + content.duration;
  const contentCpdReducer = (acc, content) => acc + parseFloat(content.cpd_credits);

  const calculateSummary = () => {
    const totalMinutes = milestones.reduce(milestoneDurationReducer, 0);
    const semesterCpdCredits = milestones.reduce(milestoneCpdReducer, 0);
    const semesterHours = (totalMinutes / 60);
    setTotalHours(Math.round(semesterHours * 10) / 10);
    setTotalCpdCredits(semesterCpdCredits);

    if (!startDate || !endDate) {
      setTotalWeeks(undefined);
      setHoursPerWeek(undefined);
      return;
    }

    const semesterWeeks = moment.duration(moment(endDate).diff(moment(startDate))).asWeeks();
    const weekHrs = semesterHours / semesterWeeks;
    setTotalWeeks(Math.round(semesterWeeks * 2) / 2);
    setHoursPerWeek(Math.round(weekHrs * 10) / 10);
  };

  useEffect(() => {
    calculateSummary();
  }, [milestones, startDate, endDate]);

  return (
    <>
      <div
        className={`Summary ${className} ${hoursPerWeek >= 1.1 ? 'Summary--warning' : ''}`}
        data-tip
        data-for="content-exceeded-message"
      >
        <div className="Summary-heading">{t('semesterSummary')}</div>
        <div className="Summary-content">
          {totalWeeks !== undefined
          && (
            <Trans t={t} i18nKey="semesterDurationWeeks" values={{ count: totalWeeks }}>
              <span className="u-bold" />
            </Trans>
          )}
          <Trans t={t} i18nKey="semesterLearningHours" values={{ count: totalHours }}>
            <span className="u-bold" />
          </Trans>
          {hoursPerWeek !== undefined
          && (
            <Trans t={t} i18nKey="semesterHoursPerWeek" values={{ count: hoursPerWeek }}>
              <span className="u-bold" />
            </Trans>
          )}
          <Trans t={t} i18nKey="semesterCpdCredits" values={{ count: totalCpdCredits }}>
            <span className="u-bold" />
          </Trans>
        </div>
      </div>
      {hoursPerWeek >= 1.1 && (
        <Tooltip
          place="bottom"
          id="content-exceeded-message"
        >
          <Trans t={t} i18nKey="contentExceededBody">
            <br />
          </Trans>
        </Tooltip>
      )}
    </>
  );
};

export default SemesterSummary;

import React from 'react';

const SubjectIcon = ({ icon, colour, className = '' }) => (
  <span
    className={`SubjectIcon ${className}`}
    style={{ backgroundColor: colour, textAlign: 'center' }}
  >
    <i
      className="SubjectIcon-inner"
      dangerouslySetInnerHTML={{
        __html: icon,
      }}
    />
  </span>
);

export default SubjectIcon;

import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ExerciseTab from './ExerciseTab';
import OverviewSection from './OverviewSection';
import SolutionSection from './SolutionSection';
import QuestionSection from './QuestionSection';
import { answersInit, answersReducer } from './Reducers/AnswersReducer';
import client from '../../lib/api/axiosClient';

const OVERVIEW_SECTION_TAB_NUMBER = 1;
const QUESTIONS_SECTION_TAB_NUMBER = 2;
const SOLUTION_SECTION_TAB_NUMBER = 3;

const Exercise = ({
  exerciseId,
  instructions,
  solutionInstructions,
  questions,
  exerciseFileLink,
  exerciseImageThumbnail,
  exerciseImageLink,
  overview,
  muxPlaybackId,
  nextAction,
}) => {
  const [activeTabNumber, setActiveTabNumber] = useState(OVERVIEW_SECTION_TAB_NUMBER);
  const [answersState, answersDispatch] = useReducer(answersReducer, questions, answersInit);
  const { t } = useTranslation('Exercises');

  const checkAnswers = () => {
    client
      .post('/api/v1/exercise_submissions', {
        exercise_submission: {
          exercise_id: exerciseId,
          answers_attributes: answersState.map(answer => ({
            question_id: answer.questionId,
            answer: answer.userAnswer,
          })),
        },
      })
      .then(({ data }) => {
        answersDispatch({
          type: 'setAnswersFromServer',
          questions,
          data: data.exercise_submission.answers,
        });
      });
  };
  const setOverviewActive = () => {
    checkAnswers();
    setActiveTabNumber(SOLUTION_SECTION_TAB_NUMBER);
  };

  const ViewSolutionButton = () => (
    <button
      className="right button exercise-button green next-section-button"
      onClick={() => setActiveTabNumber(SOLUTION_SECTION_TAB_NUMBER)}
    >
      {t('ViewSolution')}
    </button>
  );

  const QuestionSectionButton = () => (
    <a
      className="button exercise-button green right next-section-button"
      onClick={() => setActiveTabNumber(QUESTIONS_SECTION_TAB_NUMBER)}
    >
      {t('Exercise')}
      {' '}
      <i className="fa fa-arrow-right" />
    </a>
  );

  return (
    <div className="exercise">
      <ExerciseTab {...{ activeTabNumber, setActiveTabNumber }} tabNumber={OVERVIEW_SECTION_TAB_NUMBER}>
        {t('Overview')}
      </ExerciseTab>
      <ExerciseTab {...{ activeTabNumber, setActiveTabNumber }} tabNumber={QUESTIONS_SECTION_TAB_NUMBER}>
        {t('Exercise')}
      </ExerciseTab>
      <ExerciseTab
        {...{ activeTabNumber }}
        setActiveTabNumber={setOverviewActive}
        tabNumber={SOLUTION_SECTION_TAB_NUMBER}
      >
        {t('Solution')}
      </ExerciseTab>
      {activeTabNumber === OVERVIEW_SECTION_TAB_NUMBER
      && (
        <OverviewSection
          {...{ exerciseFileLink, overview, setActiveTabNumber }}
          nextSectionButton={QuestionSectionButton}
        />
      )}
      {activeTabNumber === QUESTIONS_SECTION_TAB_NUMBER
      && (
        <QuestionSection
          instructions={instructions}
          questions={questions}
          answersState={answersState}
          answersDispatch={answersDispatch}
          exerciseId={exerciseId}
          nextSectionButton={ViewSolutionButton}
          checkAnswers={checkAnswers}
          exerciseImageLink={exerciseImageLink}
          exerciseImageThumbnail={exerciseImageThumbnail}
        />
      )}
      {activeTabNumber === SOLUTION_SECTION_TAB_NUMBER
      && (
        <SolutionSection
          muxPlaybackId={muxPlaybackId}
          answersState={answersState}
          answersDispatch={answersDispatch}
          exerciseId={exerciseId}
          nextAction={nextAction}
          solutionInstructions={solutionInstructions}
        />
      )}
    </div>
  );
};

export default Exercise;

import React from 'react';
import { ProgressBar, Step } from "react-step-progress-bar";
import ReactTooltip from "react-tooltip";

const ProjectProgressBar = ({
  steps, selectedStep, currentStep, changeStepCallback, projectSlug,
}) => {
  const stepPercentage = 100 / (steps.length + 1);
  const transitionDuration = 600;

  const changeStep = (step) => {
    if (step <= currentStep) changeStepCallback(step);
  };

  const getStepState = (step, index) => {
    const stepState = [];
    if (index > currentStep) stepState.push('incomplete');
    if (index === currentStep) stepState.push('in-progress');
    if (index === selectedStep) stepState.push('active');
    if (step.correct != null) stepState.push(step.correct ? 'correct' : 'incorrect');
    return stepState.join(' ');
  };

  const renderProgressBar = () => (
    <div className="project-progress-bar">
      <ProgressBar
        percent={stepPercentage * (currentStep + 1)}
        filledBackground="#fff"
      >
        <Step transitionDuration={transitionDuration} key="first">
          {({ transitionState }) => (
            <div className={`project-step transition-${transitionState} correct`}>
              <a href={`/projects/${projectSlug}`} style={{ lineHeight: 0 }}>
                <i
                  className="fa fa-home"
                />
              </a>
            </div>
          )}
        </Step>
        {steps.map((step, index) => (
          <Step transitionDuration={transitionDuration} key={step.id}>
            {({ transitionState }) => (
              <>
                <div
                  className={`${getStepState(step, index)} transition-${transitionState} project-step`}
                  onClick={() => changeStep(index)}
                  data-tip
                  data-for={`projectStep${step.id}`}
                >
                  {index + 1}
                </div>
              </>
            )}
          </Step>

        ))}
        <Step transitionDuration={transitionDuration} key="last">
          {({ transitionState }) => (
            <div
              className={`${currentStep === steps.length ? '' : 'incomplete'} ${selectedStep === steps.length ? 'active' : ''} transition-${transitionState} project-step`}
              onClick={() => changeStep(steps.length)}
              data-tip
              data-for="finalProjectStep"
            >
              <i className="fa fa-flag" />
            </div>
          )}
        </Step>
      </ProgressBar>
      {steps.map((step) => (
        <ReactTooltip
          key={`projectStep_${step.id}_tooltip`}
          id={`projectStep${step.id}`}
          place="top"
          type="light"
          effect="solid"
        >
          {step.name}
        </ReactTooltip>
      ))}
      <ReactTooltip id="finalProjectStep" place="top" type="light" effect="solid">
        Conclusions
      </ReactTooltip>
    </div>
  );

  return renderProgressBar();
};

export default ProjectProgressBar;

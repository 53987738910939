import React from 'react';

const AheadOfScheduleIcon = ({ className = '' }) => (
  <>
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="47.064"
      height="45.427"
      viewBox="0 0 47.064 45.427"
    >
      <g id="Ahead_of_Schedule" data-name="Ahead of Schedule" transform="translate(-38 -6.905)">
        <g id="Group_1460" data-name="Group 1460" transform="translate(38 6.905)">
          <g id="Group_1459" data-name="Group 1459" transform="translate(0)">
            <path
              id="Path_2046"
              data-name="Path 2046"
              d="M47.262,90.405l-.115-.111a2.853,2.853,0,0,0-3.934,0l-4.235,4.138a.6.6,0,0,1-.813.018l-5.938-5.3a3.163,3.163,0,0,0-2.113-.8H21.28a.827.827,0,0,0-.559.215l-7.487,6.873a2.707,2.707,0,0,0-.167,3.814,2.811,2.811,0,0,0,2.042.864h.014a2.827,2.827,0,0,0,2.03-.864l5.287-5.41h1.893L11.994,107.179H4.383A3.275,3.275,0,0,0,1,110.148a3.05,3.05,0,0,0,.886,2.327,3.311,3.311,0,0,0,2.354.978H15.6a.824.824,0,0,0,.592-.249l5.819-6.006,4.893,4.948-1.477,9.128a3.145,3.145,0,0,0,1.784,3.661,3.389,3.389,0,0,0,1.351.282A3.321,3.321,0,0,0,30,124.893a3.156,3.156,0,0,0,1.725-2.13l2.524-13.088a.768.768,0,0,0-.225-.7l-6.735-6.5,5.351-5.166,3.73,3.606a2.892,2.892,0,0,0,3.9,0l7-6.766a2.583,2.583,0,0,0,0-3.741Z"
              transform="translate(-1 -79.791)"
              fill="#08c2bb"
            />
            <path
              id="Path_2047"
              data-name="Path 2047"
              d="M359.808,20.323a5.709,5.709,0,1,0-5.709-5.709A5.714,5.714,0,0,0,359.808,20.323Z"
              transform="translate(-324.178 -8.905)"
              fill="#08c2bb"
            />
          </g>
        </g>
      </g>
    </svg>

  </>
);

export default AheadOfScheduleIcon;

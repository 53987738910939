import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, List, Heading,
} from 'ckeditor5';

export default ({
  initialContent,
  onContentChange,
  disabled,
}) => {
  const [editor, setEditor] = useState(null);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList'],
        },
        plugins: [
          Bold, Essentials, Italic, Mention, Paragraph, Undo, List, Heading,
        ],
      }}
      data={initialContent}
      onReady={editor => {
        editor.editing.view.change(writer => {
          writer.setStyle(
            'height',
            '300px',
            editor.editing.view.document.getRoot()
          );
        });
        setEditor(editor);
      }}
      onChange={_event => {
        onContentChange(editor.getData());
      }}
      disabled={disabled}
    />
  );
};
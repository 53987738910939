import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import client from '../../../lib/api/axiosClient';
import LearningPathCard from "./LearningPathCard";

const LearningPaths = () => {
  const { t } = useTranslation('LearningPaths');
  const [learningPaths, setLearningPaths] = useState(null);
  const [filteredPaths, setFilteredPaths] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterIndex, setFilterIndex] = useState(-1);

  const defaultFilter = [{
    value: -1, label: 'All',
  }];

  useEffect(() => {
    loadLearningPaths();
  }, []);

  useEffect(() => {
    if (!learningPaths) return;

    const softwareList = learningPaths.reduce((acc, learningPath) => {
      if(acc.indexOf(learningPath.software_name) !== -1) return acc;
      acc.push(learningPath.software_name);
      return acc;
    }, []);
    setFilters(defaultFilter.concat(softwareList.map((elem, index) => ({ value: index, label: elem }))));
  }, [learningPaths]);

  useEffect(() => {
    if (!learningPaths) return;

    setFilteredPaths(learningPaths.filter(learningPath => filterIndex === -1 || learningPath.software_name === filteredSoftwareName()));
  }, [learningPaths, filters, filterIndex]);

  useEffect(() => {
    if (filterIndex === -1) return;

    const filteredName = filteredSoftwareName();

    const url = new URL(window.location);
    url.searchParams.set('_feFilterTool', filteredName);
    const historyUrl = `${url.pathname}?${url.searchParams.toString()}`;
    window.history.replaceState(null, '', historyUrl);
  }, [filterIndex]);

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const filteredName = params.get('_feFilterTool');
    if (filteredName == null) return;

    const preSelectedFilterValue = filters.find(f => f.label === filteredName)?.value;

    if (preSelectedFilterValue !== undefined) setFilterIndex(preSelectedFilterValue);
  }, [filters]);

  const updateFilter = (selectedOption) => {
    if (selectedOption.value === -1) {
      const url = new URL(window.location);
      url.searchParams.delete('_feFilterTool');
      const historyUrl = `${url.pathname}?${url.searchParams.toString()}`;
      window.history.replaceState(null, '', historyUrl);
    }

    setFilterIndex(parseInt(selectedOption.value, 10));
  };

  const filteredSoftwareName = () => filters.find(f => f.value === filterIndex)?.label;

  const filterSelectionValue = () => {
    if (filterIndex === -1) return undefined;

    return filters.find(f => f.value === filterIndex);
  };

  const loadLearningPaths = () => {
    client
      .get(`/api/v1/learning_paths.json`)
      .then(response => {
        setLearningPaths(response.data.data.learning_paths);
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.status === 401) window.location.reload();
      });
  };

  if (!learningPaths) return null;

  return (
    <>
      <div className="Banner Banner--grid" style={{ backgroundImage: "url('/assets/banners/learning_paths.png')" }}>
        <div className="Banner-container">
          <div className="Banner-content">
            <h2 className="u-textWhite">
              {t('learningPathsTitle')}
            </h2>
            <p className="u-textWhite">
              {t('learningPathsSubtitle')}
            </p>
          </div>
        </div>
      </div>
      <div className="Ribbon">
        <div className="Ribbon-container">
          <div className="Descriptor Descriptor--ribbon">
            <i className="Descriptor-icon custom-icon icon-sme" />
            <span className="Descriptor-text">{t('ribbonCurated')}</span>
          </div>
          <div className="Descriptor Descriptor--ribbon">
            <i className="Descriptor-icon custom-icon icon-project" />
            <span className="Descriptor-text">{t('ribbonProjects')}</span>
          </div>
          <div className="Descriptor Descriptor--ribbon">
            <i className="Descriptor-icon custom-icon icon-cpe-cpd" />
            <span className="Descriptor-text">{t('ribbonCpe')}</span>
          </div>
          <div className="Descriptor Descriptor--ribbon">
            <i className="Descriptor-icon custom-icon icon-diploma" />
            <span className="Descriptor-text">{t('ribbonDiploma')}</span>
          </div>
        </div>
      </div>
      <section className="Page">
        <div className="Page-content">
          <div className="Filters">
            <div className="Filter">
              <label className="Filter-label">
                {t('filterByTool')}
                :
              </label>
              <Select
                className="Filter-select"
                isSearchable
                value={filterSelectionValue()}
                defaultValue={defaultFilter}
                onChange={updateFilter}
                options={filters}
                classNamePrefix="react-select"
              />
            </div>
          </div>
          <div className="Showcase u-gridGap-ml">
            {filteredPaths.map(learningPath => <LearningPathCard key={`learning-path-${learningPath.id}`} learningPath={learningPath} />)}
          </div>
        </div>
      </section>
    </>
  );
};

export default LearningPaths;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ContentActions = ({
  content,
  apiCalls,
  reloadCallback,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const startLink = `/${content.content_type.toLowerCase()}s/${content.slug}`;
  const examLink = `/exam_submissions/new?course_id=${content.id}`;
  const [currentAction, setCurrentAction] = useState();

  useEffect(() => {
    setCurrentAction(null);
  }, [content.skipped]);

  const buttons = {
    downloadCertificate: () => {
      if(!content.certificate_url) return <></>;
      return (
        <a
          key={`${content.content_type}-${content.id}-button-certificate`}
          className="content-action button small with-icon"
          href={content.certificate_url}
        >
          {t('downloadCertificate')}
          <i
            className="fa fa-download"
          />
        </a>
      );
    },
    goToContent: (buttonText, extraClass = '') => (
      <a
        key={`${content.content_type}-${content.id}-button-start`}
        className={`content-action button small with-icon ${extraClass}`}
        href={startLink}
      >
        {buttonText}
        <i className="fa fa-play-circle" />
      </a>
    ),
    goToExam: (buttonText, extraClass = '') => (
      <a
        key={`${content.content_type}-${content.id}-button-exam`}
        className={`content-action button small with-icon ${extraClass}`}
        href={examLink}
      >
        {buttonText}
        <i className="fa fa-play-circle" />
      </a>
    ),
    additional: (extraClass = '') => {
      if (content.learning_context === 'saved') {
        return (
          <a
            key={`${content.content_type}-${content.id}-button-unsave`}
            className={`content-action button small ${extraClass}`}
            onClick={() => {
              setCurrentAction('removingFromSaved');
              apiCalls.removeFromSaved(content)
                .then(() => {
                  reloadCallback()
                    .then(setCurrentAction(null));
                });
            }}
          >
            {t('removeFromSaved')}
          </a>
        );
      } if (content.learning_context === 'semester' && content.optional) {
        if (content.skipped === true) {
          return (
            <a
              key={`${content.content_type}-${content.id}-button-restore`}
              className={`content-action button small ${extraClass}`}
              onClick={() => {
                setCurrentAction('restoring');
                apiCalls.restoreContent(content)
                  .then(() => {
                    reloadCallback()
                      .then(setCurrentAction(null));
                  });
              }}
            >
              {t('returnToDo')}
            </a>
          );
        }
        return (
          <a
            key={`${content.content_type}-${content.id}-button-skip`}
            className={`content-action button small ${extraClass}`}
            onClick={() => {
              setCurrentAction('skipping');
              apiCalls.skipContent(content)
                .then(() => {
                  reloadCallback()
                    .then(setCurrentAction(null));
                });
            }}
          >
            {t('markAsCompleted')}
          </a>
        );
      }
    },
  };

  const states = {
    projectComplete: () => [
      buttons.downloadCertificate(),
      buttons.goToContent(t('reviewProject'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    courseComplete: () => [
      buttons.downloadCertificate(),
      buttons.goToContent(t('rewatch'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    start: () => [
      buttons.goToContent(t('start')),
      buttons.additional('white-bordered'),
    ],
    takeExam: () => [
      buttons.goToExam(t('takeExam')),
      buttons.goToContent(t('rewatch'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    continue: () => [
      buttons.goToContent(t('resume')),
      buttons.additional('white-bordered'),
    ],
  };

  const actionInProgress = () => (
    <span className="pending-action">
      <i className="fa fa-circle-o-notch fa-spin" />
&nbsp;
      {t(currentAction)}
      ...
    </span>
  );

  const getButtons = () => {
    if (content.completed === true) {
      if (content.content_type === 'Project') return states.projectComplete();
      return states.courseComplete();
    }
    const watchedExaminableCourse = content.content_type === 'Course' && content.percent_watched === 100 && content.has_live_exam;
    if (watchedExaminableCourse) return states.takeExam();
    if (content.progress === 0) return states.start();
    return states.continue();
  };

  if (currentAction != null) return actionInProgress();

  return <>{getButtons()}</>;
};

export default ContentActions;

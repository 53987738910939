import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import client from '../../../lib/api/axiosClient';
import Form from '../../DesignSystem/Forms/Form';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import Field from '../../DesignSystem/Forms/Field';
import Toasts from '../../DesignSystem/Toasts';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Checkbox from '../../DesignSystem/Forms/Fields/Checkbox';

const Portal = ({
  learning_group_id,
  organisation_name,
  learning_group_name,
  enable_sso,
  enable_email,
  locations,
  titles,
  bypass_consent,
  signup_portal_custom_message,
}) => {
  const { t } = useTranslation('LearningGroupPortal');
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    sign_up_code: '',
    terms_privacy_consent: false,
    receive_communications_consent: false,
    location: null,
    title: null,
  });

  const [errors, setErrors] = useState({});
  const [networkError, setNetworkError] = useState(null);
  const [locationOptions, setLocationOptions] = useState(null);
  const [titleOptions, setTitleOptions] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  useEffect(() => {
    if (locations) setLocationOptions(locations.map(location => ({ label: location, value: location })));
    if (titles) setTitleOptions(titles.map(title => ({ label: title, value: title })));
  }, []);

  const submit = async () => {
    if (actionInProgress) return;

    setErrors({});
    setNetworkError(null);
    setActionInProgress(true);

    try {
      const res = await client.post('/api/v1/create_through_portal.json', {
        user: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          email_confirmation: data.email,
          password: data.password,
          password_confirmation: data.password,
          title: data.title,
          location: data.location,
          sign_up_code: data.sign_up_code,
          learning_group_id,
          terms_privacy_consent: data.terms_privacy_consent,
          receive_communications_consent: data.receive_communications_consent,
        },
      }, { timeout: 20000 });
      setActionInProgress(false);

      if (res.data.redirect) window.location.href = res.data.redirect;
    } catch (error) {
      if (error?.response?.data) {
        setErrors(error.response.data.errors);
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const handleChange = (name, value) => {
    setData(oldData => {
      oldData[name] = value;
      return oldData;
    });
  };

  const renderTermsLabel = () => (
    <Trans t={t} i18nKey="termsConsent">
      <a target="_blank" href="/terms" />
      <a target="_blank" href="/privacy" />
    </Trans>
  );

  const renderConsent = () => (
    <>
      <Checkbox
        id="terms_privacy_consent"
        name="terms_privacy_consent"
        containerClass="is-full-width"
        label={renderTermsLabel()}
        required
        onChange={handleChange}
        value={data.terms_privacy_consent}
        error={errors.terms_privacy_consent}
      />
      <Checkbox
        id="receive_communications_consent"
        name="receive_communications_consent"
        containerClass="is-full-width"
        label={t('commsConsent')}
        onChange={handleChange}
        value={data.receive_communications_consent}
        error={errors.receive_communications_consent}
      />
    </>
  );

  const renderSSO = () => (
    <>
      <Fieldset>
        <h2>{t('ssoSignupHeading')}</h2>
        <p>{t('ssoSignupText')}</p>
        <a
          href="./portal_sso"
          id="sso_sign_up"
          className={`Form-item Button ${enable_email ? 'Button--secondary' : 'Button--primary'} Button--formSubmit ${actionInProgress ? 'is-inactive' : ''}`}
        >
          {t('ssoSignup')}
        </a>
      </Fieldset>
      {enable_email && <hr className="Separator" />}
    </>
  );

  const renderLocation = () => (
    <Field
      type="select"
      name="location"
      label={t('location')}
      isSearchable={false}
      options={locationOptions}
      onChange={handleChange}
      value={data.location}
      error={errors.location_check}
      containerClass={titleOptions ? '' : 'is-full-width'}
      required
    />
  );

  const renderStandardFields = () => (
    <>
      <Field
        type="text"
        name="first_name"
        label={t('firstName')}
        onChange={handleChange}
        value={data.first_name}
        error={errors.first_name}
        required
      />
      <Field
        type="text"
        name="last_name"
        label={t('lastName')}
        onChange={handleChange}
        value={data.last_name}
        error={errors.last_name}
        required
      />
      <Field
        type="text"
        name="email"
        label={t('email')}
        onChange={handleChange}
        value={data.email}
        error={errors.email}
        required
      />
      <Field
        type="password"
        name="password"
        label={t('password')}
        onChange={handleChange}
        value={data.password}
        error={errors.password}
        required
      />
    </>
  );

  const renderSignupCode = () => (
    <Field
      type="text"
      name="sign_up_code"
      label={t('signupCode')}
      hint={t('signupCodeHint')}
      onChange={handleChange}
      containerClass="is-full-width"
      required
      value={data.sign_up_code}
      error={errors.sign_up_code}
    />
  );

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit is-full-width ${actionInProgress ? 'is-inactive' : ''}`}
      id="email_sign_up"
      disabled={actionInProgress}
      value={t('emailSignup')}
    />
  );

  const renderTitle = () => (
    <Field
      type="select"
      name="title"
      label={t('title')}
      isSearchable={false}
      options={titleOptions}
      onChange={handleChange}
      value={data.title}
      error={errors.title_check}
      containerClass={locationOptions ? '' : 'is-full-width'}
      required
    />
  );

  const renderBlurb = () => {
    const showSignUpPortalMessage = !!signup_portal_custom_message;
    const showEmailMessage = !signup_portal_custom_message && enable_email;
    const showSsoMessage = !signup_portal_custom_message && enable_sso;

    return (
      <div className="Blurb u-textWhite">
        <img className="Blurb-logo" src="/assets/logo/kubicle-logo-white-200px.svg" />
        <hr className="Separator Separator--blurb" />
        <h2 className="Blurb-title">{t('signupPortal', { organisation_name })}</h2>
        <p className="Blurb-text">
          <span className="u-bold">
            {t('learningGroup')}
            :&nbsp;
          </span>
          <span>{learning_group_name}</span>
        </p>
        {showSignUpPortalMessage
          && <div className="custom-message-area" dangerouslySetInnerHTML={{ __html: signup_portal_custom_message }} />}
        {showSsoMessage
          && <p className="Blurb-text">{t(enable_email ? 'ssoInstructions' : 'ssoOnlyInstructions')}</p>}
        {showEmailMessage
          && (
            <p
              className="Blurb-text"
            >
              {t(enable_sso ? 'emailInstructions' : 'emailOnlyInstructions', { organisation_name })}
            </p>
          )}
      </div>
    );
  };

  return (
    <>
      <Toasts>
        {errors.learning_group && (
          <Toast
            state={ToastStates.ERROR}
            title={t('noLicensesTitle')}
          >
            <p>{t('noLicensesBody')}</p>
          </Toast>
        )}
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <div className="SplitLayout">
        <div className="SplitLayout-slide SplitLayout-slide--marketing">
          <div className="SplitLayout-slideInner">
            {renderBlurb()}
          </div>
        </div>
        <div className="SplitLayout-slide">
          <div className="SplitLayout-slideInner">
            <Form onSubmit={submit} disabled={actionInProgress}>
              {enable_sso && renderSSO()}
              {enable_email
                && (
                  <>
                    <h2>{t('emailSignupHeading')}</h2>
                    <Fieldset isSplit>
                      {renderStandardFields()}
                      {locationOptions && renderLocation()}
                      {titleOptions && renderTitle()}
                      {renderSignupCode()}
                      {!bypass_consent && renderConsent()}
                      {renderSubmit()}
                    </Fieldset>
                  </>
                )}
            </Form>
            <p className="m-top-l">
              <Trans t={t} i18nKey="privacy">
                <a href="/privacy" target="_blank" />
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portal;

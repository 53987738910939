import React from 'react';
import ReactTooltip from "react-tooltip";

const GlobalMetricsIcons = ({
  certificatesPath,
  certificatesCount,
  diplomasCount,
  displayDiploma,
}) => {
  const renderLabel = () => {
    if (displayDiploma) return 'Certificates and Diplomas';

    return 'Certificates';
  };

  return (
    <>
      <a href={certificatesPath} data-tip data-for="globalmetrics">
        <img
          src="/assets/svg_icons/globalmetric_cert.svg"
          width={24}
          id="globalmetric-cert"
        />
        {' '}
        {certificatesCount}
        {displayDiploma && (
          <>

            <img
              src="/assets/svg_icons/globalmetric_diploma.svg"
              width={32}
              id="globalmetric-diploma"
            />
            {' '}
            {diplomasCount}
          </>
        )}
      </a>
      <ReactTooltip id="globalmetrics" place="bottom" type="light" effect="solid">
        {renderLabel()}
      </ReactTooltip>
    </>
  );
};

export default GlobalMetricsIcons;

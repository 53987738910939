import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AnswerIcon from './AnswerIcon';

const OpenEndedQuestion = ({
  id,
  question,
  placeholder,
  instructions,
  index,
  readOnly,
  saveAnswerCallback,
  answer,
  answer_correct,
  lastEvaluation,
}) => {
  const { t } = useTranslation('Project');
  const [isFocused, setIsFocused] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [answerEvaluated, setAnswerEvaluated] = useState(answer != null);

  useEffect(() => {
    if (answer !== null) setCurrentAnswer(answer);
  }, [question]);

  useEffect(() => {
    if (lastEvaluation != null) setAnswerEvaluated(true);
  }, [lastEvaluation]);

  const updateAnswer = (event) => {
    saveAnswerCallback(index, id, event.target.value);
    setCurrentAnswer(event.target.value);
    setAnswerEvaluated(false);
  };

  const cssClassFromAnswerState = () => {
    if (!answerEvaluated) return '';
    switch (answer_correct) {
      case null:
        return '';
      case true:
        return 'correct';
      case false:
        return 'incorrect';
      default:
        return '';
    }
  };

  return (
    <div className="question-container open-ended-question">
      <label
        className={`question-label ${readOnly ? 'read-only' : ''}`}
        htmlFor={`question_${id}`}
      >
        {t('question_text', { number: (index + 1), text: question })}
      </label>
      <div className="question-input">
        <input
          id={`question_${id}`}
          type="text"
          className={`${cssClassFromAnswerState()}`}
          onChange={updateAnswer}
          value={currentAnswer}
          disabled={answer_correct}
          placeholder={placeholder}
          onFocus={() => !readOnly && setIsFocused(true)}
          onBlur={() => !readOnly && setIsFocused(false)}
          readOnly={readOnly}
        />
        {answerEvaluated && (
          <AnswerIcon
            answerEvaluation={answer_correct}
          />
        )}
      </div>
      <span className={`contextual-instruction ${isFocused ? 'visible' : ''}`}>{instructions}</span>
    </div>
  );
};

export default OpenEndedQuestion;

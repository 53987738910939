import React from 'react';

const Accordion = ({
  summary,
  children,
  className = '',
  showChevron = true,
  open = false,
  large = false,
  openable = true,
  ...props
}) => {
  const openableOnClick = event => {
    if (!openable) event.preventDefault();
  };

  return (
    <details {...props} className={`Accordion ${large ? 'Accordion--large' : ''} ${className}`} open={open} onClick={openableOnClick}>
      <summary className="Accordion-summary">
        <div className="Accordion-summaryText">
          {summary}
          {showChevron && <i className="Accordion-toggle" />}
        </div>
      </summary>
      <div className="Accordion-content">
        {children}
      </div>
    </details>
  );
};

export default Accordion;

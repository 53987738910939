import React from 'react';

const CompletedIcon = ({ className = '' }) => (
  <>
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31.015"
      height="60.989"
      viewBox="0 0 31.015 60.989"
    >
      <g id="Complete" transform="translate(-216 -56.702)">
        <g id="Complete-2" data-name="Complete" transform="translate(151.66 67.898)">
          <g id="Group_1533" data-name="Group 1533" transform="translate(64.34 0)">
            <path
              id="Path_2096"
              data-name="Path 2096"
              d="M68.309,13.653a2.8,2.8,0,0,1,1.5-.43l9.4-.011c3.156-2.649,2.165-9.084,1.445-11.529A1.342,1.342,0,0,1,81.764.043a1.579,1.579,0,0,1,1.884.967c.139.471,3.334,11.582-3.834,15.145-.084.042-.266.119-.266.119l.029,31.5a2.347,2.347,0,0,1-4.648,0V32.509H73.81V47.773a2.33,2.33,0,0,1-4.619,0v-31.1c-2.725,2.829-1.776,8.844-1.084,11.191A1.343,1.343,0,0,1,67,29.5a1.775,1.775,0,0,1-.388.043,1.524,1.524,0,0,1-1.5-1.01C64.979,28.081,62.124,17.61,68.309,13.653Z"
              transform="translate(-64.34 0)"
              fill="#08c2bb"
            />
          </g>
          <path
            id="Path_2097"
            data-name="Path 2097"
            d="M37.669,10.114a5.057,5.057,0,1,0-5.056-5.057A5.063,5.063,0,0,0,37.669,10.114Z"
            transform="translate(36.594 1.366)"
            fill="#08c2bb"
          />
        </g>
        <path
          id="Flag"
          d="M14.513,6.005l1.7-2.27a.668.668,0,0,0-.534-1.068H7.67a.668.668,0,1,0-1.335,0V14.016H5.668a.668.668,0,1,0,0,1.335h2.67c.369,0-1.714.112-1.714-.256s2.082-1.079,1.714-1.079H7.67V9.343h8.011a.668.668,0,0,0,.534-1.068Z"
          transform="matrix(0.966, 0.259, -0.259, 0.966, 231.741, 53.476)"
          fill="#08c2bb"
        />
      </g>
    </svg>
  </>
);

export default CompletedIcon;

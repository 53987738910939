import React from 'react';

const SkillLevelIcon = ({ level_name, className = '', icon_height = '21px' }) => (
  <div className={`${level_name.toString().toLowerCase()} ${className}`}>
    <svg
      className="skill-level-icon"
      width="24px"
      height={icon_height}
      viewBox="0 0 26 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs />
      <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000, 0.000000)">
        <rect className="skill-level-icon-square-1" x="0" y="16" width="6" height="8" rx="2" />
        <rect className="skill-level-icon-square-2" x="9" y="8" width="6" height="16" rx="2" />
        <rect className="skill-level-icon-square-3" x="18" y="0" width="6" height="24" rx="2" />
      </g>
    </svg>
    <span>{level_name}</span>
  </div>
);

export default SkillLevelIcon;

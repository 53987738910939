import React from 'react';

const AnswerIcon = ({ answerEvaluation }) => {
  switch (answerEvaluation) {
    case null:
      return <></>;
    case true:
      return <i className="answer-icon correct fa fa-check-circle" />;
    case false:
      return <i className="answer-icon incorrect fa fa-times-circle" />;
    default:
      return <></>;
  }
};

export default AnswerIcon;

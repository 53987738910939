import React from 'react';
import NotificationMessage from "../Components/NotificationMessage";

const CellsIcon = () => (
  <div className="cells-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="56.793" height="52.06" viewBox="0 0 56.793 52.06">
      <path
        id="cells"
        d="M49.694,1H7.1A7.108,7.108,0,0,0,0,8.1V45.961a7.108,7.108,0,0,0,7.1,7.1H49.694a7.108,7.108,0,0,0,7.1-7.1V8.1A7.108,7.108,0,0,0,49.694,1ZM17.748,17.565H26.03v7.1H17.748Zm-4.733,7.1H4.733v-7.1h8.282Zm17.748-7.1h8.282v7.1H30.763Zm13.015,0H52.06v7.1H43.778ZM4.733,29.4h8.282v7.1H4.733Zm13.015,0H26.03v7.1H17.748ZM26.03,41.228v7.1H17.748v-7.1Zm4.733,0h8.282v7.1H30.763Zm0-4.733V29.4h8.282v7.1Zm13.015-7.1H52.06v7.1H43.778ZM4.733,45.961V41.228h8.282v7.1H7.1A2.37,2.37,0,0,1,4.733,45.961Zm44.961,2.366H43.778v-7.1H52.06v4.733A2.37,2.37,0,0,1,49.694,48.327Z"
        transform="translate(0 -1)"
        fill="#a0bacd"
      />
    </svg>
  </div>
);

const ResultEmptyState = () => (
  <div className="results-empty-state">
    <div className="empty-state-content">
      <CellsIcon />
      <h2>No Results Yet</h2>
      <p>
        Click &ldquo;Run Code&rdquo; to see results here.
      </p>
    </div>
  </div>
);

const BasicResultEmptyState = () => (
  <div className="basic-results-empty-state">
    <p>
      Click &ldquo;Run Code&rdquo; to see results here.
    </p>
  </div>
);

export const ResultsSection = ({ result, fields }) => (
  <div className="results-section">
    <ResultTable result={result} fields={fields} emptyState={() => <ResultEmptyState />} />
  </div>
);

export const BasicResultsSection = ({ errorMessage, result, fields }) => (
  errorMessage
    ? <ErrorMessage errorMessage={errorMessage} />
    : (
      <div className="results-section">
        <ResultTable result={result} fields={fields} emptyState={() => <BasicResultEmptyState />} />
      </div>
    ));

const ResultTable = ({ result, fields, emptyState }) => {
  if (fields.length > 0) {
    const headingRow = fields.map((heading) => <th>{heading}</th>);
    const resultRows = result.map((row) => <ResultRow row={row} />);

    return (
      <table>
        <thead>
          <tr>
            {headingRow}
          </tr>
        </thead>
        <tbody>
          {resultRows}
        </tbody>
      </table>
    );
  }
  return emptyState();
};

export const ResultRow = ({ row }) => {
  const resultCells = row.map((value) => <ResultCell value={value} />);

  return (<tr>{resultCells}</tr>);
};

export const ResultCell = ({ value }) => {
  const isNull = value === null;

  return (
    <td className={isNull ? 'is-null' : ''}>
      {isNull ? 'NULL' : value.toString()}
    </td>
  );
};

const ErrorMessage = ({ errorMessage }) => (
  <NotificationMessage className="error-section">
    <h2>
      <i className="fa fa-exclamation-circle" />
      An error occurred:
    </h2>
    <p>{errorMessage}</p>
  </NotificationMessage>
);

import $ from 'jquery';
import moment from 'moment';

require('jquery-countdown');

export default class ExamTimer {
  constructor() {
    this.selector = '#exam-countdown';
    const examSubmissionData = $('[name="exam-submission-data"]');
    const examLength = examSubmissionData.data('exam-time');
    this.timeExam(examLength);
  }

  static submitExam() {
    $('form#exam_submission_form').submit();
  }

  updateTimer(hours, minutes) {
    if (hours === 0 && minutes === 10) blinker(this.selector);

    if (hours === 0 && minutes === 5) blinker(this.selector);

    if (hours === 0 && minutes === 0) blinker(this.selector);

    function blinker(selector) {
      $(selector).fadeOut(500);
      $(selector).fadeIn(500);
    }
  }

  timeExam(exam_length_minutes) {
    const self = this;
    const end_date = moment().add(exam_length_minutes, 'minutes').toDate();

    $(this.selector).countdown(end_date, function (event) {
      $(this).html(event.strftime('%H:%M:%S'));
    }).on('update.countdown', (event) => {
      self.updateTimer(event.offset.hours, event.offset.minutes);
    }).on('finish.countdown', $.proxy(function () {
      $(this.selector).remove();
      $('#exam-duration').text('The exam is over.');
      $('form#exam_submission_form').append('<input type="hidden" name="auto_submit" value="true">');
      this.constructor.submitExam();
      window.onbeforeunload = null;
    }, this));
  }
}

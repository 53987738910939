import React from "react";
import AnswerIcon from "./AnswerIcon";

const Option = ({
  id,
  option_text,
  question_id,
  value,
  answerEvaluation,
  readOnly,
  setSelectedOption,
  selectedOption,
  answerEvaluated,
}) => {
  const submitValue = value != null ? value : id;
  const checked = submitValue === selectedOption;
  const inputId = `option_${id}`;
  return (
    <div>
      <input
        id={inputId}
        type="radio"
        name={`question_${question_id}`}
        value={value || id}
        checked={checked}
        onClick={() => {
          setSelectedOption(submitValue);
        }}
        readOnly
      />
      <label
        onClick={() => {
          setSelectedOption(submitValue);
        }}
        htmlFor={inputId}
        className={`${checked && getEvaluationClass(answerEvaluation)} ${readOnly ? 'read-only' : ''}`}
      >
        {option_text}
        {' '}
        {checked && answerEvaluated && <AnswerIcon answerEvaluation={answerEvaluation} />}
      </label>
    </div>
  );
};

const getEvaluationClass = (evaluation) => {
  switch (evaluation) {
    case null:
      return '';
    case true:
      return 'correct-option';
    case false:
      return 'incorrect-option';
    default:
      return '';
  }
};

export default Option;

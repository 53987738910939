import React from 'react';
import { useTranslation } from 'react-i18next';

import MultipleChoiceQuestion from "./MultipleChoiceQuestion";

const BooleanQuestion = (question) => {
  const { t } = useTranslation('Exercises');
  const { id } = question;

  return (
    <MultipleChoiceQuestion
      {...question}
      options={[
        { id: 'true', option_text: t('Yes'), question_id: id },
        { id: 'false', option_text: t('No'), question_id: id },
      ]}
    />
  );
};
export default BooleanQuestion;

import React from 'react';
import { useTranslation } from 'react-i18next';
import SemesterCompletePanel from "./SemesterCompletePanel";
import EmptyStatePanel from "./EmptyStatePanel";
import SavedContentEmptyState from "./SavedContentEmptyState";

const EmptyState = (props) => {
  const { t } = useTranslation('LearnDashboard');
  const { filter, tabType } = props;

  if (tabType === 'saved') {
    if (filter === 'todo' || filter === 'all') return <SavedContentEmptyState />;

    return (
      <EmptyStatePanel
        title={t(`savedContent.emptyStates.${filter}Title`)}
        content={t(`savedContent.emptyStates.${filter}Content`)}
      />
    );
  }

  switch (filter) {
    case 'todo':
      return <SemesterCompletePanel {...props} />;
    case 'skipped':
      return <EmptyStatePanel title={t('youHaventSkipped')} content={t('whenYouSkip')} />;
    case 'done':
      return <EmptyStatePanel title={t('youHaventCompleted')} content={t('whenYouComplete')} />;
    case 'all':
      return <EmptyStatePanel title={t('youHaventBeenAssigned')} />;
    default:
      return <EmptyStatePanel title={t('youHaventBeenAssigned')} />;
  }
};

export default EmptyState;

import React from 'react';
import { useTranslation } from "react-i18next";

const LearningPathCard = ({ learningPath }) => {
  const { t } = useTranslation('LearningPaths');

  return (
    <a className="Card Card--slideTile is-link" href={learningPath.url} id={`learning-path-card-${learningPath.id}`}>
      {learningPath.enrolled && <div className="Card-bottomPill">{t('enrolled')}</div>}
      <div className="Card-tile">
        <img className="Card-image" src={learningPath.image} />
        <div className="Card-content">
          <div className="Card-title">
            {learningPath.name}
          </div>
          <div className="Card-descriptors">
            <div className="Descriptor Descriptor--card">
              <i className="Descriptor-icon fa fa-clock-o" />
              <span className="Descriptor-text">
                {learningPath.hours}
                {' '}
                {t('hour', { count: learningPath.hours })}
              </span>
            </div>
            <div className="Descriptor Descriptor--card">
              <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
              <span className="Descriptor-text">
                {learningPath.cpd_credits}
                {' '}
                {t('cpeCredits')}
              </span>
            </div>
          </div>
          <div className="Card-description">
            {learningPath.description}
          </div>
          <span className="Card-moreInfo">{t('learnMore')}</span>
        </div>
      </div>
    </a>
  );
};

export default LearningPathCard;

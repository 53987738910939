/* eslint-disable react/destructuring-assignment,react/sort-comp,react/require-default-props */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import SkillCheck from '../Components/SkillCheck';
import SemesterStatus from '../Components/SemesterStatus';
import ExamLink from '../Components/ExamLink';

class SkillCheckContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillLevel: this.props.skillLevel,
      skillCheckOpen: false,
    };
    this.setCourseState = this.setCourseState.bind(this);
  }

  render() {
    return (
      <div className="course-meta absolute">
        {this.semesterStatus()}
        {this.lock()}
        {this.skillContent()}
      </div>
    );
  }

  semesterStatus() {
    if (this.state.skillLevel > 0 && this.props.semesterMandatorySkillLevel) {
      return (
        <SemesterStatus
          skillLevel={this.state.skillLevel}
          semesterMandatorySkillLevel={this.props.semesterMandatorySkillLevel}
        />
      );
    }
  }

  skillContent() {
    const summaryCta = [
      <a className="button" href={`/courses/${this.props.courseSlug}`}>
        {this.props.percentComplete > 0 ? 'Continue Watching' : 'Start Learning'}
      </a>,
    ];

    if (this.props.examsVisible && this.state.skillLevel === 3) summaryCta.unshift((<ExamLink courseId={this.props.courseId} />));

    return (
      <SkillCheck
        skillLevel={this.state.skillLevel}
        semesterMandatorySkillLevel={this.props.semesterMandatorySkillLevel}
        courseSlug={this.props.courseSlug}
        courseName={this.props.courseName}
        skillCheckOpen={this.state.skillCheckOpen}
        summaryCTA={summaryCta}
        courseComplete={this.props.courseComplete}
        certificateUrl={this.props.certificateUrl}
        setCourseState={this.setCourseState}
        addActiveToParent
      />
    );
  }

  lock() {
    if (this.props.locked) {
      return (
        <div className="locked-course">
          <i className="fa fa-lock" />
        </div>
      );
    }
  }

  setCourseState(data) {
    this.setState(data);
  }
}

SkillCheckContainer.defaultProps = {
  examsVisible: true,
};

SkillCheckContainer.propTypes = {
  courseId: PropTypes.number.isRequired,
  courseSlug: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  courseComplete: PropTypes.bool.isRequired,
  certificateUrl: PropTypes.string,
  examsVisible: PropTypes.bool,
  semesterMandatorySkillLevel: PropTypes.number,
  skillLevel: PropTypes.number.isRequired,
};

export default SkillCheckContainer;

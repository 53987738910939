import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBarSection from '../../Common/Components/ProgressBarSection';
import useJobProgressMonitor from '../../../lib/hooks/useJobProgressMonitor';
import { timeInWords } from '../../../lib/utils/dateTimeHelpers';

const Reports = ({ monitor_id }) => {
  const {
    percentage_complete, seconds_elapsed, seconds_remaining, result,
  } = useJobProgressMonitor(monitor_id);
  const { t } = useTranslation('General');

  return (
    <ProgressBarSection percentage_complete={percentage_complete}>
      <div className="container report-container">
        <h1>{result ? t('ReportDownload') : t('ReportGenerating')}</h1>
        <ul>
          <li>
            {t('Progress')}
            :
            {' '}
            {percentage_complete}
            %
          </li>
          <li>
            {t('TimeElapsed')}
            :
            {' '}
            {t(...timeInWords(seconds_elapsed))}
          </li>
          <li>
            {t('TimeRemaining')}
            :
            {' '}
            {t(...timeInWords(seconds_remaining))}
          </li>
        </ul>
        <a
          id="download_link"
          href={result ? result.report_file.url : '#'}
          className={`button ${result ? '' : 'hidden'}`}
        >
          {t('DownloadReport')}
        </a>
      </div>
    </ProgressBarSection>
  );
};

export default Reports;

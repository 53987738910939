import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import NotificationMessage from '../../Common/Components/NotificationMessage';
import client from '../../../lib/api/axiosClient';
import BooleanQuestion from './BooleanQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import OpenEndedQuestion from './OpenEndedQuestion';
import usePageFocus from '../../../lib/hooks/usePageFocus';
import SqlEditor, { RowCount } from '../../Common/SqlEditor/SqlEditor';
import { BasicResultsSection } from '../../Common/SqlEditor/ResultsSection';
import useSqlEditor from '../../../lib/hooks/useSqlEditor';
import ProjectAssignment from './ProjectAssignment';
import HtmlRendererWithVideo from '../../HtmlRenderWithVideo';

const questionTypes = {
  BooleanQuestion,
  MultipleChoiceQuestion,
  OpenEndedQuestion,
};

const ProjectStep = ({
  projectId,
  step,
  selectedStep,
  totalStepsNumber,
  reloadProjectCallback,
  setStepCallback,
  breadcrumbFunction,
}) => {
  const { t } = useTranslation('Project');

  const [answers, setAnswers] = useState([]); // Tracking of answers
  const [lastEvaluation, setLastEvaluation] = useState(); // Used to re-trigger form validation
  const [shouldTrackTime, setShouldTrackTime] = useState(true); // Used to trigger time tracking
  const [highlightHint, setHighlightHint] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const hintRef = useRef(null);

  const {
    query, setQuery, fields, runQuery, status, errorMessage, result,
  } = useSqlEditor({ defaultSql: step.sql_editor_placeholder, schema: 'canine_charity' });

  const focus = usePageFocus();

  useEffect(() => {
    setAnswers([]);
    setLastEvaluation(null);
  }, [selectedStep]);

  useEffect(() => {
    if (shouldTrackTime) trackTime();
  }, [shouldTrackTime]);

  const scrollToHint = evt => {
    evt.preventDefault();
    hintRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    setTimeout(() => {
      setHighlightHint(true);
      setTimeout(() => {
        setHighlightHint(false);
      }, 2000);
    }, 500);
  };

  const trackTime = () => {
    const timeWithoutFocus = focus.getTimeWithoutFocus();
    if (step.status !== 'passed' && timeWithoutFocus < 300) client.get(`/api/v1/projects/${projectId}/project_attempts/track_time.json?project_step_id=${step.id}`);

    setShouldTrackTime(false);
    setTimeout((() => setShouldTrackTime(true)), 10000);
  };

  // Save the current answer inside the array of user provided answers
  const saveAnswer = (id, questionId, userAnswer) => {
    const newAnswers = answers;
    newAnswers[id] = { index: id, question_id: questionId, answer: userAnswer };
    setAnswers(newAnswers);
  };

  const revealHint = evt => {
    evt.preventDefault();
    client
      .get(`/api/v1/projects/${projectId}/project_attempts/get_hint.json?project_step_id=${step.id}`)
      .then(_response => {
        reloadProjectCallback(false);
      })
      .catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  };

  const submitAnswers = () => {
    if (actionInProgress === true) return;

    setActionInProgress(true);

    client
      .post(`/api/v1/projects/${projectId}/project_attempts/submit_answers.json?project_step_id=${step.id}`, { answers })
      .then(_response => {
        // Do not change steps automatically
        reloadProjectCallback(false).then(() => {
          // Update last evaluation
          setLastEvaluation(new Date());
          setActionInProgress(false);
        });
      })
      .catch(error => {
        if (error.response?.status === 401) {
          window.location.reload();
        } else {
          setActionInProgress(false);
          console.error(error);
        }
      });
  };

  const previousButton = () => {
    if (selectedStep > 0) {
      return (
        <a
          className="Button Button--secondary m-top-m"
          onClick={() => setStepCallback(selectedStep - 1)}
        >
          {t('previous')}
        </a>
      );
    }
  };

  const nextButton = () => {
    if (selectedStep < totalStepsNumber && step.status === 'passed') {
      return (
        <a
          className="Button Button--primary m-top-m"
          onClick={() => setStepCallback(selectedStep + 1)}
        >
          {t('next')}
        </a>
      );
    }
  };
  const submitButton = () => {
    if (step.status !== 'passed') {
      return (
        <a
          className={`Button Button--primary m-top-m ${actionInProgress ? 'is-inactive' : ''}`}
          onClick={() => submitAnswers()}
        >
          {t('submit')}
        </a>
      );
    }
  };

  const renderDownloads = () => {
    if (step.downloads.length > 0) {
      return (
        <div className="project-files boxed">
          <h2 className="sidebar-heading">
            <i className="fa fa-download" />
            {' '}
            {t('project_files')}
          </h2>
          {step.downloads.map(download => (
            <div key={download.id}>
              <a href={download.url} download target="_blank" rel="noreferrer">{download.name}</a>
            </div>
          ))}
        </div>
      );
    }
  };

  const renderHelpfulLessons = () => {
    if (step.lessons.length > 0) {
      return (
        <div className="helpful-lessons boxed">
          <h2 className="sidebar-heading">
            <i className="fa fa-play-circle" />
            {' '}
            {t('helpful_lessons')}
          </h2>
          {step.lessons.map(lesson => (
            <div key={lesson.id}>
              <a href={lesson.url} target="_blank" rel="noreferrer">{lesson.title}</a>
            </div>
          ))}
        </div>
      );
    }
  };

  const renderHints = () => {
    if (step.allow_hint) {
      return (
        <div id="hint" ref={hintRef} className={`hint boxed ${highlightHint ? 'js-highlight' : ''}`}>
          <h2 className="sidebar-heading">
            <i className="fa fa-question-circle" />
            {' '}
            {t('hint')}
          </h2>
          {!step.hint_shown
            && <a href="#" onClick={revealHint}>{t('show_hint')}</a>}
          {step.hint}
        </div>
      );
    }
  };

  const renderEvaluationMessage = () => {
    if (lastEvaluation == null) return null;

    if (step.status === 'passed') {
      return (
        <NotificationMessage
          className="success-section"
        >
          <h2>{t('correct')}</h2>
          <p>{(selectedStep + 1 === totalStepsNumber) ? t('click_next_complete_project') : t('click_next_move_to_next_step')}</p>
        </NotificationMessage>
      );
    }
    if (step.questions.find(q => q.answer == null)) {
      return (
        <NotificationMessage
          className="incomplete-section"
        >
          <h2>{t('answer_incomplete')}</h2>
          <p>{t('answer_all_questions')}</p>
        </NotificationMessage>
      );
    }
    return (
      <NotificationMessage
        className="error-section"
      >
        <h2>{t('incorrect_answer')}</h2>
        <p>
          {step.allow_hint === true
            ? (
              <Trans ns="Project" i18nKey="try_again_use_hint_link">
                Please try again. If you are stuck you can reveal a
                {' '}
                <a href="#hint" onClick={scrollToHint}>hint</a>
                {' '}
                on the top right side of the screen.
              </Trans>
            )
            : (
              <Trans ns="Project" i18nKey="try_again">
                Please try again.
              </Trans>
            )}
        </p>
      </NotificationMessage>
    );
  };

  const renderContent = () => (
    <div>
      <div className="project-main-screen container">
        {breadcrumbFunction()}
        <div className="page-title">
          <h1>{t('step_title', { step_number: (selectedStep + 1), total: totalStepsNumber, title: step.name })}</h1>
        </div>
        <div>
          <div className="main-content">
            <HtmlRendererWithVideo htmlContent={step.content} />
            <div className="sidebar">
              {renderDownloads()}
              {renderHelpfulLessons()}
              {renderHints()}
            </div>
          </div>
          { step.show_sql_editor && (
            <div className="editor-container">
              <form>
                <SqlEditor {...{
                  query,
                  setQuery,
                  status,
                }}
                />
                <div className="button-section">
                  <div className="button-wrapper">
                    <button className="Button Button--primary" onClick={runQuery}>{t('runCode')}</button>
                  </div>
                  {fields.length > 0 ? <RowCount {...{ result }} /> : ''}
                </div>
              </form>
              <BasicResultsSection {...{ errorMessage, result, fields }} />
            </div>
          )}
          {step.is_assignment && (
            <ProjectAssignment
              projectId={projectId}
              reloadProjectCallback={reloadProjectCallback}
              step={step}
              selectedStep={selectedStep}
              totalStepsNumber={totalStepsNumber}
              setStepCallback={setStepCallback}
            />
          )}
          {!step.is_assignment && (
            <div className="step-questions boxed">
              {step.questions && step.questions.length > 0
                ? (
                  <div className="section">
                    <div className="project-heading">
                      <h2>{t('questions')}</h2>
                    </div>
                    {
                      (step.questions.map((question, index) => {
                        const QuestionType = questionTypes[question.type];
                        return (
                          <QuestionType
                            key={question.id}
                            {...question}
                            saveAnswerCallback={saveAnswer}
                            index={index}
                            lastEvaluation={lastEvaluation}
                          >
                            {question.type}
                          </QuestionType>
                        );
                      }))
                    }
                  </div>
                ) : ''}
              {renderEvaluationMessage()}
              {previousButton()}
              {submitButton()}
              {nextButton()}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return renderContent();
};

export default ProjectStep;

import $ from 'jquery';

export function initHelperPopup(message, elementToAttach, enableClose = true) {
  showPopup(message, elementToAttach, enableClose);
  if (enableClose) { // if false is passed in then you must call closeHelperPopup off some other button click
    bindClosePopup();
  }
}

export function closeHelperPopup() {
  const $helperPopup = $('#js_helper_popup');
  $helperPopup.remove();
  hideOverlay();
  repositionElement($helperPopup.data('highlighted-element'));
}

function showPopup(message, elementToAttach, enableClose) {
  let hide_close_class = '';

  if (!enableClose) hide_close_class = 'no-close-button';

  const html = `<div class="helper-popup shepherd-theme-arrows shepherd-element shepherd-element-attached-bottom shepherd-element-attached-center shepherd-target-attached-top shepherd-target-attached-center shepherd-open" id="js_helper_popup" data-highlighted-element=${elementToAttach}>
                <div class="shepherd-content">
                  <div class="shepherd-text">
                    ${message}
                  </div>
                  <i class="fa fa-times ${hide_close_class}" id="js_helper_popup_close"></i>
                </div>
              </div>`;

  const $popUp = $(html);
  const $elementToAttach = $(elementToAttach);
  positionPopup($popUp, $elementToAttach);
  positionElementFixed($elementToAttach);
  showOverlay();
}

function bindClosePopup() {
  $('#js_helper_popup_close').click(closeHelperPopup);
  $('.helper-overlay').click(closeHelperPopup);
}

function positionPopup($popUp, $elementToAttach) {
  $('body').append($popUp);
  const position = $elementToAttach.offset();
  position.top -= $popUp.outerHeight();
  position.left = position.left - ($popUp.outerWidth() / 2) + ($elementToAttach.outerWidth() / 2);
  $popUp.offset(position);
}

function positionElementFixed($element) {
  const position = $element.offset();
  $element.addClass('fixed-element-for-popup');
  $element.offset(position);
  $('html').addClass('no-scroll');
}

function repositionElement(highlightedElementId) {
  const $highlightedElement = $(highlightedElementId);
  $highlightedElement.removeClass('fixed-element-for-popup');
  $highlightedElement.css({ top: "", left: "" });
  $('html').removeClass('no-scroll');
}

function showOverlay() {
  $('.helper-overlay').addClass('active');
}

function hideOverlay() {
  $('.helper-overlay').removeClass('active');
}

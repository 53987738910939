import React from 'react';
import { useTranslation } from 'react-i18next';

function SignInForm({
  className = '', headerClassName = '', hasHeaderBlurb = false, headerText, children,
}) {
  const { t } = useTranslation('SignInPanel');

  return (
    <div className={`Form Form-transition sign-in-form ${className}`}>
      <h2 className={`Form-heading ${headerClassName}`}>
        {headerText || t('defaultHeaderText')}
      </h2>
      {hasHeaderBlurb && <p>{t('headerBlurb')}</p>}
      <div className="SignInForm-content">
        {children}
      </div>
    </div>
  );
}

export default SignInForm;

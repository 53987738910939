import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const SolutionSection = props => {
  const { t } = useTranslation('Exercises');
  const {
    muxPlaybackId, nextAction, solutionInstructions,
  } = props;

  return (
    <div className="exercise-section exercise-solution-section">
      {muxPlaybackId
      && <VideoPlayer playbackId={muxPlaybackId} nextAction={nextAction} showPlayRate />}
      {solutionInstructions
      && (
        <div className="exercise-section exercise-questions-section">
          <h3>{t('Solution')}</h3>
          <div dangerouslySetInnerHTML={{ __html: solutionInstructions }} />
        </div>
      )}
    </div>
  );
};

export default SolutionSection;

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import SkillLevelIcon from "../../Common/Components/SkillLevelIcon";

const Content = ({
  canSaveContent, content, index, saveContentCallback, removeFromSavedCallback,
}) => {
  const { t } = useTranslation('Library');
  const [contentSaved, setContentSaved] = useState(content.saved);
  const [showTick, setShowTick] = useState(false);

  const saveContent = () => {
    if (!canSaveContent) return;

    saveContentCallback();
    setContentSaved(true);
    setShowTick(true);
    setTimeout(() => {
      setShowTick(false);
    }, 2500);
  };

  const removeFromSaved = () => {
    if (!canSaveContent) return;

    removeFromSavedCallback();
    setContentSaved(false);
  };

  const renderSaveButton = () => {
    if (!canSaveContent) return;

    if (contentSaved) {
      if (showTick) {
        return (
          <div className="add-button">
            <i
              onClick={removeFromSaved}
              className="fa fa-check-circle pull-right green-icon"
            />
          </div>
        );
      }
      return (
        <div className="remove-button">
          <i
            onClick={removeFromSaved}
            className="fa fa-minus-circle pull-right red-icon"
            data-tip
            data-for={`remove-${content.id}`}
          />

          <i
            onClick={removeFromSaved}
            className="fa fa-check-circle pull-right green-icon"
            data-tip
            data-for={`remove-${content.id}`}
          />
          <ReactTooltip id={`remove-${content.id}`} place="top" type="light" effect="solid">
            {t('removeFrom')}
            <br />
            <strong>{t('savedContent')}</strong>
          </ReactTooltip>
        </div>
      );
    }
    return (
      <div className="add-button">
        <i
          onClick={saveContent}
          className="fa fa-plus-circle pull-right blue-icon"
          data-tip
          data-for={`save-${content.id}`}
        />
        <ReactTooltip id={`save-${content.id}`} place="top" type="light" effect="solid">
          {t('addTo')}
          <br />
          <strong>{t('savedContent')}</strong>
        </ReactTooltip>
      </div>
    );
  };

  return (
    <div className={`course-panel ${content.content_type.toLowerCase()} library-panel library-panel-react`}>
      <div className="panel-image">
        <a href={content.link}>
          <img src={content.image} alt={content.name} />
        </a>
      </div>
      <div className="panel-details">
        <p className="content-type-subtitle">{content.content_type}</p>
        <p className="course-title">
          <a href={content.link}>
            {index}
            .
            {' '}
            {content.name}
          </a>
        </p>
        <p>{content.description}</p>
        <div className="course-details">
          {content.content_type === 'Project' && (
            <div className="content-type">
              <i
                className="custom-icon icon-project icon-color-navy"
                aria-hidden="true"
              />
              <span>{t('project')}</span>
            </div>
          )}
          {content.content_type === 'Course' && <SkillLevelIcon level_name={content.level} className="course-level" />}
          {content.content_type === 'Course' && (
            <div className="course-lessons-count">
              <i className="custom-icon icon-lesson icon-color-navy" aria-hidden="true" />
              <span>
                {content.lessons}
                {' '}
                {t('liveLesson', { count: content.lessons })}
              </span>
            </div>
          )}
          {content.content_type === 'Project' && (
            <div className="project-steps-count">
              <i className="fa fa-tasks" aria-hidden="true" />
              <span>
                {content.steps}
                {' '}
                {t('projectStep', { count: content.steps })}
              </span>
            </div>
          )}
          <div className="course-time">
            <i className="fa fa-clock-o" aria-hidden="true" />
            <span>
              {content.duration}
              {' '}
              {t('minute', { count: content.duration })}
            </span>
          </div>
          <div className="course-credits">
            <i className="custom-icon icon-cpe-cpd icon-color-navy" aria-hidden="true" />
            <span>
              {t('cpdCredits')}
            </span>
          </div>
        </div>
        {renderSaveButton()}
      </div>
    </div>
  );
};

export default Content;

import React from 'react';

const Form = ({
  onSubmit, action, disabled, children, className = '', isXhr = true,
}) => {
  const submit = event => {
    if (isXhr) {
      event.preventDefault();
      if (!disabled) onSubmit(event);
    }
  };

  return (
    <form className={`Form ${className} ${disabled ? 'is-disabled' : ''}`} onSubmit={submit} action={action} method="post">
      {children}
    </form>
  );
};

export default Form;

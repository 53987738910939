import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const AccountDeactivatedPage = ({
  firstName,
  certificatesPath,
}) => {
  const { t } = useTranslation('AccountDeactivations');

  return (
    <>
      <div className="EmptyState">
        <img className="EmptyState-image" src="/assets/empty_states/deactivated_learner.png" />
        <h4 className="EmptyState-title">{t('accountDeactivatedTitle')}</h4>
        <div className="EmptyState-description">
          <p className="u-textCenter">
            <Trans t={t} i18nKey="paragraph1" values={{ firstName }} />
          </p>
          <p className="u-textCenter">
            {t('paragraph2')}
          </p>
          <p className="u-textCenter">
            <a
              rel="noreferrer"
              href="https://support.kubicle.com/deactivated-learners"
              target="_blank"
              className="u-underline is-external"
            >
              {t('understandingDeactivationSupportLinkText')}
            </a>
          </p>
          <p className="u-textCenter">
            <Trans t={t} i18nKey="paragraph3">
              <a href={certificatesPath} className="u-underline">access them here</a>
            </Trans>
          </p>
        </div>
      </div>
    </>
  );
};

export default AccountDeactivatedPage;

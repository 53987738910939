import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import axiosClient from '../../lib/api/axiosClient';
import Form from '../DesignSystem/Forms/Form';
import Fieldset from '../DesignSystem/Forms/Fieldset';
import Field from '../DesignSystem/Forms/Field';
import Toasts from '../DesignSystem/Toasts';
import Toast, { ToastStates } from '../DesignSystem/Toast';

const defaultLoginUrl = '/api/v1/sign-in.json';

const SignInPasswordStep = ({ onChangeAccountClick, email, ssoUrl }) => {
  const { t } = useTranslation('SignInPanel');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const [networkError, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  const formUrl = () => (ssoUrl || defaultLoginUrl);
  const submitLabel = () => (ssoUrl ? t('ssoSubmit') : t('passwordSubmit'));

  const onSubmit = async () => {
    if (actionInProgress) return;

    setNetworkError(null);
    setActionInProgress(true);

    if (password.trim() === '') {
      setErrors({ password: t('passwordEmpty') });
      setActionInProgress(false);
      return;
    }

    try {
      const res = await axiosClient.post(formUrl(), {
        user: {
          email,
          password,
        },
      }, { timeout: 20000 });

      setActionInProgress(false);

      if (res.data.redirect) window.location.href = res.data.redirect;
    } catch (error) {
      if (error.response?.data?.error) {
        setErrors({ password: error.response.data.error });
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit ${actionInProgress ? 'is-inactive' : ''}`}
      disabled={actionInProgress}
      value={submitLabel()}
    />
  );

  const renderFields = () => (
    <>
      <Field
        id="user_password"
        type="password"
        name="password"
        label={t('password')}
        value={password}
        error={errors.password}
        autoFocus="autofocus"
        onChange={(_, newPassword) => setPassword(newPassword)}
        required
      />
    </>
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <Form
        {...(ssoUrl ? ({
          action: ssoUrl.split('?')[0], method: 'post', isXhr: false, onSubmit: (() => {}),
        }) : { onSubmit })}
        disabled={actionInProgress}
      >
        <Fieldset>
          {!ssoUrl && renderFields()}
          {ssoUrl && (
            <input
              type="hidden"
              name="authenticity_token"
              value={document?.querySelector('meta[name=csrf-token]')?.content}
            />
          )}
          {renderSubmit(actionInProgress)}
          {!ssoUrl && (
            <a href="/users/password/new">{t('forgotPassword')}</a>
          )}
          <a id="change_account" onClick={onChangeAccountClick}>{t('changeAccount')}</a>
        </Fieldset>
      </Form>
    </>
  );
};

export default SignInPasswordStep;

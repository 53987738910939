import React, { useState, useEffect } from 'react';

const ProgressDonut = ({ size = 24, percentage = 0 }) => {
  const unitSize = size / 6;
  const [displayPercent, setDisplayPercent] = useState(0);

  const getArraySize = () => unitSize * 4 * Math.PI;
  const getCompleteSize = () => {
    const arraySize = getArraySize();
    return arraySize - (displayPercent * (arraySize / 100));
  };

  useEffect(() => {
    setTimeout(() => {
      setDisplayPercent(percentage);
    }, 500);
  }, []);

  return (
    <div className="progress-donut">
      <svg width={unitSize * 6} height={unitSize * 6} style={{ transform: "rotate(-90deg)" }} xmlns="http://www.w3.org/2000/svg">
        <circle
          className="progress-donut-incomplete"
          r={unitSize * 2}
          cy={unitSize * 3}
          cx={unitSize * 3}
          strokeWidth={unitSize}
          stroke="#ADADAD"
          fill="none"
        />
        <circle
          className="progress-donut-complete"
          strokeDasharray={getArraySize()}
          strokeDashoffset={getCompleteSize()}
          r={unitSize * 2}
          cy={unitSize * 3}
          cx={unitSize * 3}
          strokeWidth={unitSize}
          stroke="#005DA2"
          style={{ transition: "stroke-dashoffset 2s ease" }}
          fill="none"
        />
      </svg>
    </div>
  );
};

export default ProgressDonut;

import React, { useState } from 'react';
import { classNameForAnswer } from "./Reducers/AnswersReducer";
import AnswerIcon from "./AnswerIcon";

const OpenEndedQuestion = ({
  id, question, placeholder, instructions, answersState, answersDispatch, index, readOnly,
}) => {
  const onChange = (e) => {
    answersDispatch({ type: 'updateAnswer', index, value: e.target.value });
  };
  const answer = answersState[index];
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="question-container open-ended-question">
      <span className="question-number">
        {index + 1}
        .
      </span>
      <label className={`question-label ${readOnly ? 'read-only' : ''}`} htmlFor={`question_${id}`}>{question}</label>
      <input
        id={`question_${id}`}
        type="text"
        className={classNameForAnswer(answer.answerEvaluation)}
        onChange={onChange}
        value={answer.userAnswer ?? ''}
        placeholder={placeholder}
        onFocus={() => !readOnly && setIsFocused(true)}
        onBlur={() => !readOnly && setIsFocused(false)}
        readOnly={readOnly}
      />
      <AnswerIcon answerEvaluation={answer.answerEvaluation} />
      <span className={`contextual-instruction ${isFocused ? 'visible' : ''}`}>{instructions}</span>
    </div>
  );
};

export default OpenEndedQuestion;

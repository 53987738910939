import React from 'react';
import { useTranslation } from 'react-i18next';

import { allAnswersEvaluated } from "./Reducers/AnswersReducer";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import BooleanQuestion from "./BooleanQuestion";
import OpenEndedQuestion from "./OpenEndedQuestion";
import ExerciseImage from "./ExerciseImage";

const questionTypes = {
  BooleanQuestion,
  MultipleChoiceQuestion,
  OpenEndedQuestion,
};

const QuestionSection = ({
  questions, instructions, exerciseImageLink, exerciseImageThumbnail, answersState, answersDispatch, nextSectionButton, checkAnswers, readOnly,
}) => {
  const { t } = useTranslation('Exercises');

  return (
    <div className="exercise-section exercise-questions-section">

      {exerciseImageLink
      && (
        <div className="section">
          <h3>{t('ExerciseImage')}</h3>
          <ExerciseImage exerciseImageLink={exerciseImageLink} exerciseImageThumbnail={exerciseImageThumbnail} />
        </div>
      )}

      {instructions
      && (
        <div className="section">
          <h3>{t('Instructions')}</h3>
          <div dangerouslySetInnerHTML={{ __html: instructions }} />
        </div>
      )}

      {questions && questions.length > 0
        ? (
          <div className="section">
            <h3>{t('Questions')}</h3>
            {
              (questions.map((question, index) => {
                const QuestionType = questionTypes[question.type];
                return (
                  <QuestionType
                    key={`question-${question.id}`}
                    {...question}
                    {...{
                      answersState, answersDispatch, index, readOnly,
                    }}
                  />
                );
              }))
            }
          </div>
        ) : ''}

      {!readOnly && !allAnswersEvaluated(answersState)
      && (
        <button className="right button exercise-button hollow next-section-button" onClick={checkAnswers}>
          {t('CheckAnswers')}
        </button>
      )}
      {!readOnly && allAnswersEvaluated(answersState) && nextSectionButton()}
    </div>
  );
};

export default QuestionSection;

import React from 'react';
import { useTranslation } from "react-i18next";
import LottieContainer from '../../Common/Components/LottieContainer';

const FinalStep = ({
  selectedStep,
  conclusions,
  setStepCallback,
  skillsCovered,
  exampleUseCases,
  certificateUrl = null,
  userId,
}) => {
  const { t } = useTranslation('Project');

  const previousButton = () => {
    if (selectedStep > 0) {
      return (
        <a onClick={() => setStepCallback(0)}>{t('review_answers')}</a>
      );
    }
  };

  const renderCertificateButton = () => {
    if (certificateUrl !== null) {
      return (
        <a className="button" onClick={() => handleLinkedInSharing(certificateUrl)}>
          {t('share_certificate')}
          <i
            className="button-icon fa fa-linkedin-square"
          />
        </a>
      );
    }
  };

  const renderDashboardButton = () => {
    if (selectedStep > 0) {
      return (
        <a
          className={`button ${certificateUrl === null ? '' : 'white-bordered'}`}
          href={`/users/${userId}/my_kubicle`}
        >
          {t('return_to_dashboard')}
        </a>
      );
    }
  };

  const handleLinkedInSharing = (sharingUrl) => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharingUrl)}`, 'sharer', 'toolbar=0, status=0, width=626, height=436');
    return false;
  };

  const congratulationsText = () => {
    if (certificateUrl === null) return t('you_have_not_completed_project');

    return (
      <>
        <div className="page-title">
          <h1>
            {t('congratulations')}
          </h1>
        </div>
        <p>
          {t('you_can_view_your_certificate')}
          {' '}
          <a
            href={`/certificates?user_id=${userId}`}
          >
            {t('certificates_and_diplomas_dashboard')}
          </a>
        </p>
      </>
    );
  };

  const renderContent = () => (
    <div>
      <div className="panel panel-centered project-top-panel project-complete-panel">
        <LottieContainer
          src="/assets/lottie/project-celebrate.json"
          cover
        />
        <div className="title-section gradient-title">
          <div className="container">
            {congratulationsText()}
            {renderCertificateButton()}
            {renderDashboardButton()}
          </div>
        </div>
      </div>
      <div className="project-main-screen container">
        <div className="main-content">
          <div className="step-description boxed">
            <div className="project-heading">
              <h2>{t('conclusion')}</h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: conclusions }} />
            {previousButton()}
          </div>
          <div className="sidebar">
            <div className="project-files boxed">
              <h2 className="sidebar-heading">
                <i
                  className="custom-icon icon-lightbulb icon-color-navy"
                />
                {' '}
                {t('skills_gained')}
              </h2>
              <div dangerouslySetInnerHTML={{ __html: skillsCovered }} />

            </div>
            <div className="helpful-lessons boxed">
              <h2 className="sidebar-heading">
                <i
                  className="custom-icon icon-reading icon-color-navy"
                />
                {' '}
                {t('other_use_cases')}
              </h2>
              <div dangerouslySetInnerHTML={{ __html: exampleUseCases }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );

  return renderContent();
};

export default FinalStep;

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const Resources = ({ downloads }) => {
  const { t } = useTranslation('LearnDashboard');

  const renderResources = () => {
    if (downloads === undefined || downloads.length === 0) return (<></>);

    return (
      <div className="downloads">
        <h3>{t('resources')}</h3>
        {downloads.map((download) => (
          <div className="download" key={download.id}>
            <a href={download.filename.url} target="_blank" rel="noreferrer">
              <i className="fa fa-download" />
              <span>{download.name}</span>
            </a>
          </div>
        ))}
      </div>
    );
  };

  return renderResources();
};

export default Resources;

/*

User event tracking.

This code is responsible for time tracking on any pages that log user events.
It runs when there is a `data-user-event-id` on the page. It only tracks on the current active page. The current active
page is the page that the user has most recently interacted with - this is tracked using local storage.
This means that if a user opens another program, the page will continue to track - i.e. if using excel for an exam or exercise.

Internal documentation for time tracking is located here:

  https://thekubicle.atlassian.net/wiki/spaces/PROD/pages/638713857/User+Time+Tracking+Summary

 */

import $ from 'jquery';
import ajaxError from '../utils/ajaxError';
import client from '../api/axiosClient';

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;

let timeElapsed = 0;
let inactivityTimeout;
let shouldTrackActivity = true;

export default function () {
  if (getUserEventId()) {
    setAsActiveTab();
    bindResetInactivityTimeout();
    pollUserEventWithTimeUpdates();
  }
}

function bindResetInactivityTimeout() {
  document.onmousemove = resetInactivityTimeout;
  document.onkeypress = resetInactivityTimeout;
  $(window).on('focus', resetInactivityTimeout);

  window._wq = window._wq || [];
  _wq.push({
    id: "_all",
    onReady: (video) => {
      video.bind("secondchange", () => {
        if (isActiveTab()) resetInactivityTimeout();
      });
    },
  });
}

function resetInactivityTimeout() {
  setAsActiveTab();

  if (shouldTrackActivity === false) {
    shouldTrackActivity = true;
    pollUserEventWithTimeUpdates();
  }

  clearTimeout(inactivityTimeout);

  inactivityTimeout = setTimeout(() => {
    shouldTrackActivity = false;
  }, getInactivityTimeoutInterval());
}

function pollUserEventWithTimeUpdates() {
  setTimeout(() => {
    timeElapsed += 10;

    client
      .put(`/api/v1/user_events/${getUserEventId()}.json`, { seconds_elapsed: timeElapsed })
      .then(() => {
        if (shouldTrackActivity && isActiveTab()) pollUserEventWithTimeUpdates();
        else shouldTrackActivity = false;
      })
      .catch(({ response }) => {
        if(response) ajaxError(response.status);
        else console.error(error);

        shouldTrackActivity = false;
      });
  }, 10 * SECONDS);
}

function getInactivityTimeoutInterval() {
  const userEventKind = $('body').data('userEventKind');

  switch (userEventKind) {
    case 'watch_lesson':
      return 35 * SECONDS;
    case 'watch_exercise':
      return 2 * MINUTES;
    case 'take_exam':
      return 10 * MINUTES;
    default:
      return 30 * SECONDS;
  }
}

function setAsActiveTab() {
  localStorage.setItem('currentActiveTab', getUserEventId());
}

function isActiveTab() {
  return getUserEventId().toString() === localStorage.getItem('currentActiveTab');
}

let getUserEventId = (function () {
  let eventId;

  function _getUserEventId() {
    if (eventId === undefined) eventId = $('body').data('userEventId');

    return eventId;
  }

  return _getUserEventId;
})();

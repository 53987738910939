import React, { useState } from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import SemesterProgressBar from "./SemesterProgressBar";

const SemesterStatistics = ({
  semester, progress, pacing,
}) => {
  const { t } = useTranslation('LearnDashboard');

  const renderProgressBar = () => {
    if (!progress.all_courses_optional) {
      return (
        <SemesterProgressBar
          className="semester-progress-bar"
          skillCheckRequired={semester.requires_skill_check}
          currentProgress={progress.current_progress}
          expectedProgress={progress.estimated_progress}
          allCoursesOptional={progress.all_courses_optional}
          progress={progress.progress}
        />
      );
    }
    return (
      <></>
    );
  };

  const semesterProgress = () => {
    if (progress.requires_skill_check) {
      return (
        <p>
          <span className="stat-label">
            {t(`semesterProgress`)}
            :
          </span>
          {' '}
          {t(`progress.notStarted`)}
        </p>
      );
    } if (progress.all_courses_optional) {
      return (
        <p>
          <span className="stat-label">
            {t(`semesterProgress`)}
            :
          </span>
          {' '}
          {t(`progress.exempt`)}
        </p>
      );
    } if (progress.progress === undefined) {
      return (
        <p><span className="stat-label">{t(`semesterProgress`)}</span></p>
      );
    } if (progress.current_progress === 100) {
      return (
        <p>
          <span className="stat-label">{t(`semesterProgress`)}</span>
          :
          {' '}
          {t(`progress.completed`)}
        </p>
      );
    }
    return (
      <p>
        <span className="stat-label">{t(`semesterProgress`)}</span>
        :
        {' '}
        {t(`progress.${progress.progress}`)}
      </p>
    );
  };

  const renderOverdueDeadline = () => {
    if (!progress.all_courses_optional) {
      if (progress.overdue) {
        let remainingDays = moment(semester.end_date).diff(moment(), 'days');
        if (remainingDays < 0) remainingDays = 0;

        return (
          <span className="overdue-deadline">
            <i
              className="fa fa-exclamation-circle red-icon"
            />
            {' '}
            {remainingDays}
            {' '}
            {t('dayRemaining', { count: remainingDays })}
          </span>
        );
      }
    }
  };

  const renderSemesterEndDate = () => {
    if (semester.has_deadline) {
      return (
        <div className="semester-end-date-container">
          <p className="semester-end-date">
            <span
              className="stat-label"
            >
              {t(`endDate`)}
              :
            </span>
            {' '}
            {moment(semester.end_date).format('Do MMM YYYY')}
          </p>
          {renderOverdueDeadline()}
        </div>
      );
    }
  };

  const renderPacing = () => {
    if (semester.has_deadline && !semester.requires_skill_check && progress.current_progress < 100 && moment(semester.end_date).diff(moment()) > 0) {
      return (
        <>
          <h3>{t('recommendedPacing')}</h3>
          <p className="pacing">
            <span
              className="pacing-value"
            >
              {pacing.courses_week}
            </span>
            {' '}
            {t(`courseWeek`, { count: pacing.courses_week })}
          </p>
          <p className="pacing">
            <span
              className="pacing-value"
            >
              {pacing.hours_week}
            </span>
            {' '}
            {t(`hourWeek`, { count: pacing.hours_week })}
          </p>
        </>
      );
    }
  };

  const renderStatistics = () => (
    <>
      <div className="semester-stats">
        {semesterProgress()}
        {renderProgressBar()}
        {renderSemesterEndDate()}
        {renderPacing()}
      </div>
    </>
  );

  return (
    <>
      {renderStatistics()}
    </>
  );
};

export default SemesterStatistics;

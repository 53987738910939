import React from 'react';
import PropTypes from 'prop-types';
import { optionalityClass, optionalityText } from "../../../lib/utils/skillLevelType";

const SemesterStatus = ({ skillLevel, semesterMandatorySkillLevel }) => (
  <div className={`optionality ${optionalityClass(skillLevel, semesterMandatorySkillLevel)}`}>
    <i className="fa fa-book" aria-hidden="true" />
    <span className="hide-for-small">
      {optionalityText(skillLevel, semesterMandatorySkillLevel)}
      {' '}
      Course
    </span>
  </div>
);

SemesterStatus.propTypes = {
  skillLevel: PropTypes.number.isRequired,
  semesterMandatorySkillLevel: PropTypes.number.isRequired,
};

export default SemesterStatus;

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import client from '../../../lib/api/axiosClient';

const RecommendedLesson = ({ lesson }) => {
  const { t } = useTranslation('Exam');
  const [bookmark, setBookmark] = useState(lesson.bookmark_id);

  const toggleBookmark = () => {
    if (bookmark) {
      return client.delete(`/api/v1/lessons/${lesson.id}/bookmarks/${bookmark}.json`).then(() => {
        setBookmark(null);
      });
    }

    return client.post(`/api/v1/lessons/${lesson.id}/bookmarks.json`).then(res => {
      setBookmark(res.data.id);
    });
  };

  return (
    <div className="Lesson">
      <a href={lesson.path} className="Lesson-image">
        <img src={lesson.image} />
      </a>
      <div className="Lesson-content">
        <h4 className="Lesson-heading">{t('review')}</h4>
        <span className="Lesson-title">
          <a href={lesson.path}>{lesson.title}</a>
        &nbsp;
          <a onClick={toggleBookmark} className={`fa ${bookmark ? 'fa-star' : 'fa-star-o'} u-textYellow`} />
        </span>
        <span className="Lesson-duration">
          {Math.floor(lesson.duration / 60)}
          :
          {String(lesson.duration % 60).padStart(2, "0")}
        </span>
      </div>
    </div>
  );
};

export default RecommendedLesson;

import React from 'react';

const ContentIcon = ({ content, className = '' }) => (
  <span
    className={`SubjectIcon SubjectIcon--background ${className}`}
    style={{ backgroundColor: content.subject_colour }}
    data-for={`content-icon-${content.content_type}-${content.id}`}
    data-tip
  >
    <i className={`SubjectIcon-inner custom-icon icon-${content.content_type.toLowerCase()}`} />
  </span>
);

export default ContentIcon;

import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import LottieContainer from '../../Common/Components/LottieContainer';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Toasts from '../../DesignSystem/Toasts';

const WelcomeScreen = ({ user, button_url, email_sent }) => {
  const { t } = useTranslation('WelcomeScreen');
  const [textVisible, setTextVisible] = useState(false);

  const showText = () => setTextVisible(true);

  useEffect(() => {
    setTimeout(showText, 5000);
  }, []);

  return (
    <div className="SplashScreen">
      <LottieContainer
        className="SplashScreen-image"
        src="/assets/lottie/welcome-kubicle.json"
        cover={false}
        loop={false}
        onAnimationComplete={showText}
      >
        <div className={`SplashScreen-imageOverlay ${textVisible ? 'is-visible' : ''}`}>
          <h1 className="SplashScreen-imageOverlayTitle">{t('welcome', { name: user.first_name })}</h1>
          <a
            href={button_url}
            className="SplashScreen-imageOverlayAction Button Button--primary"
          >
            {t('startLearning')}
          </a>
        </div>
      </LottieContainer>
      <Toasts>
        {email_sent && textVisible && (
          <Toast
            state={ToastStates.SUCCESS}
            title={t('registrationCompleteTitle')}
          >
            <p>
              <Trans t={t} i18nKey="registrationCompleteBody" values={{ email: user.email }}>
                <span className="u-bold" />
              </Trans>
            </p>
            <p>
              <Trans t={t} i18nKey="customerSuccessLink">
                <a target="_blank" href="mailto:support@kubicle.com?subject=Incorrect Signup Email" rel="noreferrer" />
              </Trans>
            </p>
          </Toast>
        )}
      </Toasts>
    </div>
  );
};

export default WelcomeScreen;

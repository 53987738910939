import React from 'react';

const ExerciseTab = ({
  children, tabNumber, activeTabNumber, setActiveTabNumber,
}) => (
  <div
    className={`exercise-tab ${tabNumber === activeTabNumber ? 'active' : ''} tab-${tabNumber} ${activeTabNumber > tabNumber ? 'completed' : ''}`}
    onClick={() => setActiveTabNumber(tabNumber)}
  >
    {children}
  </div>
);

export default ExerciseTab;

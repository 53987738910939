import React from 'react';

const SectionMessageStates = {
  INFO: { icon: 'fa fa-info', modifier: 'SectionMessage--info' },
  WARNING: { icon: 'fa fa-exclamation', modifier: 'SectionMessage--warning' },
  ERROR: { icon: 'fa fa-times', modifier: 'SectionMessage--error' },
  SUCCESS: { icon: 'fa fa-check', modifier: 'SectionMessage--success' },
};

const SectionMessage = ({
  title,
  body,
  showIcon = true,
  state = SectionMessageStates.INFO,
  tooltipId = null,
  className = '',
}) => (
  <div
    className={`SectionMessage ${state.modifier} ${className}`}
    {...(tooltipId != null ? { 'data-tip': true, 'data-for': tooltipId } : {})}
  >
    {showIcon && <i className={`SectionMessage-icon ${state.icon}`} />}
    <div className="SectionMessage-text">
      {title && <div className="SectionMessage-title">{title}</div>}
      {body && <div className="SectionMessage-body">{body}</div>}
    </div>
  </div>
);

export { SectionMessageStates };
export default SectionMessage;

import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const dropzoneStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  borderWidth: 4,
  borderRadius: 4,
  borderStyle: 'dashed',
  transition: 'border .3s ease-in-out',
  height: '420px',
  overflow: 'auto',
};

const SubmissionResult = ({
  step, fileName,
}) => {
  const { t } = useTranslation('ProjectSubmission');

  const AssessmentHeading = ({ children }) => (
    <Typography variant="h3" sx={{ mb: 2 }}>
      {children}
    </Typography>
  );

  const renderEvaluationMessage = () => (
    <>
      <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
        {t('totalScore', {
          total: step.assessment_submission.score_totals.total,
          maximum: step.assessment_submission.score_totals.maximum,
        })}
      </Typography>
      <ul>
        {Object.entries(step.assessment_submission.feedback).map(([criteriaName, feedbackResult]) => (
          <li key={criteriaName}>
            <b>
              {t('criteriaScore', {
                name: criteriaName,
                score: feedbackResult.score,
                maximum: step.assessment_submission.score_totals.criteria_totals[criteriaName],
              })}
            </b>: {feedbackResult.feedback}
          </li>
        ))}
      </ul>
    </>
  );

  const renderAssessmentResultDetails = () => (
    <Box sx={{ maxHeight: 420 }}>
      <Box sx={{ py: 2 }}>
        <AssessmentHeading>{t('feedbackHeading')}</AssessmentHeading>
        {renderEvaluationMessage()}
      </Box>
    </Box>
  );

  const evaluateSubmission = () => (
    <Box sx={dropzoneStyle}>
      {renderAssessmentResultDetails()}
    </Box>
  );

  const renderAssessmentResultMessage = () => {
    const result = step.status === 'passed' ? 'passed' : 'failed';

    return (
      <Box>
        <AssessmentHeading>{t('assessmentResults.'.concat(result, 'Heading'))}</AssessmentHeading>
        <Typography><b>{t('assignmentSubmission')}</b> { fileName }</Typography>
        <Typography><b>{t('assessmentResults.result')}</b> {t('assessmentResults.'.concat(result))}</Typography>
      </Box>
    );
  };

  const ProjectAssignmentStyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
    height: '100%',
  }));

  return (
    <ProjectAssignmentStyledGrid container alignItems="center">
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        {renderAssessmentResultMessage()}
      </ProjectAssignmentStyledGrid>
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        { evaluateSubmission() }
      </ProjectAssignmentStyledGrid>
    </ProjectAssignmentStyledGrid>
  );
};

export default SubmissionResult;
import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import client from '../../../lib/api/axiosClient';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import GridPanels from '../../DesignSystem/GridPanels';
import GridPanel from '../../DesignSystem/GridPanel';
import SubjectIcon from '../../DesignSystem/SubjectIcon';
import breakpoints from '../../../lib/layout/breakpoints.json';

const Library = () => {
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation('Library');
  const smallScreenSize = breakpoints.breakpoints.sm;
  const isMobile = !useMediaQuery(`(min-width:${smallScreenSize})`);

  const loadCategories = () => {
    client
      .get('/api/v1/categories.json')
      .then(response => {
        setCategories(response.data.data.categories);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) window.location.reload();
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const renderCategory = category => (
    <div className="m-top-l" key={`category-${category.id}`}>
      <h2 className="m-bottom-s">{category.name}</h2>
      <p className="large">{category.description}</p>
      <GridPanels cols={isMobile ? 1 : 2}>
        {category.subjects.map(subject => renderSubject(subject))}
      </GridPanels>
    </div>
  );

  const renderSubject = subject => (
    <GridPanel key={`subject-${subject.id}`} isLink href={`/library/${subject.slug}?u=true`}>
      <div className="ContentHeading m-top-none m-bottom-none">
        <SubjectIcon className="ContentHeading-icon SubjectIcon--large" icon={subject.logo} colour={subject.colour} />
        <div className="ContentHeading-text">
          <h3 className="m-all-none">{subject.name}</h3>
          <div className="Showcase Showcase--flex m-all-none u-gridGap-sm">
            <span className="Descriptor u-fontLarge">
              <Trans t={t} i18nKey="totalCourses" values={{ count: subject.totals.courses }}>
                <span className="u-bold" />
                &nbsp;
              </Trans>
            </span>
            <span className="Descriptor u-fontLarge">
              <Trans t={t} i18nKey="totalLessons" values={{ count: subject.totals.lessons }}>
                <span className="u-bold" />
                &nbsp;
              </Trans>
            </span>
            {subject.totals.projects > 0 && (
              <span className="Descriptor u-fontLarge">
                <Trans t={t} i18nKey="totalProjects" values={{ count: subject.totals.projects }}>
                  <span className="u-bold" />
                &nbsp;
                </Trans>
              </span>
            )}
          </div>
        </div>
      </div>
    </GridPanel>
  );

  if (!categories || categories.length === 0) return <KubicleLoading />;

  return (
    <>
      <div className="Page">
        <div className="Page-content m-bottom-xl">
          <h1 className="m-top-ml">{t('library')}</h1>
          {categories.map(category => renderCategory(category))}
        </div>
      </div>
    </>
  );
};

export default Library;

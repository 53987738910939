import React from 'react';
import { useTranslation } from "react-i18next";
import Slide from "../../../DesignSystem/Slide";
import ContentIcon from "../../../Common/Components/ContentIcon";

const ContentItem = ({ content }) => {
  const {
    can_access: canAccess,
    subject_name: subjectName,
    content_type: contentType,
    subject_colour: subjectColour,
    duration,
    name,
  } = content;

  const { t } = useTranslation('Common');

  return (
    <Slide className={contentType === 'Project' ? 'Slide--project' : ''} color={subjectColour}>
      <div className="ContentHeading ContentHeading--slide">
        <ContentIcon className="ContentHeading-icon SubjectIcon--large" content={content} />
        <div className="ContentHeading-text">
          <span className="ContentHeading-subject">{subjectName}</span>
          <span className="ContentHeading-content">{name}</span>
        </div>
      </div>
      <div className="Slide-details">
        {!canAccess && <i className="SubjectIcon SubjectIcon--small SubjectIcon--background SubjectIcon--circle u-bgYellow u-textWhite m-right-none fa fa-exclamation" />}
        <span>{t('durationMinutes', { minutes: duration })}</span>
      </div>
    </Slide>
  );
};

export default ContentItem;

import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

require('codemirror/mode/sql/sql');

const SqlEditor = ({ query, setQuery, status }) => (
  <CodeMirror
    value={query}
    options={{
      mode: 'text/x-pgsql',
      lineNumbers: true,
    }}
    onBeforeChange={(editor, data, value) => {
      setQuery(value);
    }}
    onChange={(_editor, _data, _value) => {}}
    className={status}
  />
);

export const RowCount = ({ result }) => (
  <div className="row-count">
    Number of Rows:
    {' '}
    <span>{result.length}</span>
  </div>
);

export default SqlEditor;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalDialog from './ModalDialog';
import client from '../../../../lib/api/axiosClient';
import ContentItem from './ContentItem';
import { onlyUnique } from '../../../../lib/utils/array';

const LearningPathContentModal = ({
  baseNewSemesterUrl,
  learningPathId,
  onCancel,
}) => {
  const { t } = useTranslation('Semester');
  const { t: lpt } = useTranslation('LearningPaths');

  const [learningPath, setLearningPath] = useState(null);

  useEffect(() => {
    loadLearningPath(learningPathId);
  }, []);

  const loadLearningPath = () => {
    client
      .get(`/api/v1/learning_paths/${learningPathId}.json`)
      .then(response => {
        const learningPath = response.data.data.learning_path;
        setLearningPath(learningPath);
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.status === 401) window.location.reload();
      });
  };

  const isAllContentAvailable = () => learningPath.contents.every((item) => item.can_access);

  const unavailableSubjectNames = () => learningPath
    .contents
    .filter((item) => !item.can_access)
    .map((item) => item.subject_name)
    .filter(onlyUnique);

  const onOk = () => {
    const baseUrlObj = new URL(baseNewSemesterUrl);
    baseUrlObj.searchParams.append('learning_path_id', learningPath.id);
    window.location.href = baseUrlObj.href;
  };

  if (learningPath === null) return null;

  return (
    <ModalDialog
      okText={t('createNewSemesterModalSelectGuide')}
      cancelText={t('createNewSemesterModalCancel')}
      onOk={onOk}
      disableOk={!isAllContentAvailable()}
      onCancel={onCancel}
    >
      <div className="Modal-header">
        {learningPath.subject_logo
          && (
            <i
              className="SubjectIcon SubjectIcon--background SubjectIcon--extraLarge ContentHeading-icon"
              style={{ backgroundColor: learningPath.subject_colour }}
              dangerouslySetInnerHTML={{ __html: learningPath.subject_logo }}
            />
          )}
        <h4 className="Modal-title">
          {t('createNewSemesterModalLearningPath')}
          :&nbsp;
          {learningPath.name}
        </h4>
      </div>
      <div>
        <p>{learningPath.description}</p>
        {!isAllContentAvailable()
          && (
            <p className="u-textYellow u-bold m-bottom-m">
              {t('createNewSemesterModalUnavailableContent', {
                subjectsStr: unavailableSubjectNames()
                  .join(', '),
              })}
            </p>
          )}

        {isAllContentAvailable()
          && (
            <div className="Showcase Showcase--flex">
              <div className="Descriptor u-textBlack">
                <i className="Descriptor-icon fa fa-clock-o" />
                <span className="Descriptor-text">
                  {learningPath.hours}
                  {lpt('hour', { count: learningPath.hours })}
                </span>
              </div>
              <div className="Descriptor u-textBlack">
                <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
                <span className="Descriptor-text">
                  {learningPath.cpd_credits}
                  {lpt('cpeCredits')}
                </span>
              </div>
              {learningPath.prerequisite_path.id
              && (
                <div className="Descriptor u-textBlack">
                  <i className="Descriptor-icon custom-icon icon-learning-path icon-color-black" />
                  <span className="Descriptor-text">
                    {t('createNewSemesterModalPrerequisite')}
                    {learningPath.prerequisite_path.name}
                  </span>
                </div>
              )}
            </div>
          )}
      </div>

      <div className="ScrollBox">
        {learningPath.contents.map(content => (
          <ContentItem
            content={content}
            key={`content-${content.content_type.toLowerCase()}-${content.id}`}
          />
        ))}
      </div>
    </ModalDialog>
  );
};

export default LearningPathContentModal;

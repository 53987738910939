import React, { useState } from 'react';
import ReactConfetti from 'react-confetti';
import useScreenSize from '../../lib/hooks/useScreenSize';

const Modal = ({
  className = '', closeCallback, allowClose = true, pulse = false, showConfetti = false, children,
}) => {
  const [showSelf, setShowSelf] = useState(true);
  const size = useScreenSize();

  const close = () => {
    if (!allowClose) return;

    if (closeCallback) closeCallback();
    else setShowSelf(false);
  };

  const stopEvent = event => event.stopPropagation();

  if (showSelf === false) return null;

  return (
    <div className={`Modal ${className} ${pulse ? 'has-pulse' : ''}`} onClick={close}>
      {showConfetti && (
        <ReactConfetti
          width={size.width}
          height={size.height}
          confettiSource={{
            x: 0,
            y: 100,
            w: size.width,
            h: 100,
          }}
          recycle={false}
          numberOfPieces={600}
          gravity={0.05}
          colors={['#1176BB', '#14A6D2', '#003663', '#F3A536', '#F4511E', '#00A9A2']}
        />
      )}
      <div className="Modal-panel" onClick={stopEvent}>
        {allowClose && (
          <a className="Modal-closeButton" onClick={close}>
            <i className="fa fa-times" title="Close" />
          </a>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentIcon from '../../Common/Components/ContentIcon';

const Content = ({ index, content }) => {
  const { t } = useTranslation('LearningPaths');

  const renderStepNumber = () => {
    if (!content.can_access) return <div className="ProgressBlocks-stepNumber"><i className="fa fa-lock" /></div>;
    if (content.completed) {
      return (
        <div className="ProgressBlocks-stepNumber is-complete">
          <i className="fa fa-check" />
        </div>
      );
    }
    return <div className="ProgressBlocks-stepNumber">{index + 1}</div>;
  };

  return (
    <>
      <div className="ProgressBlocks-step">
        <div className={`ProgressBlocks-stepSeparator ${index === 0 ? 'u-invisible' : ''}`} />
        {renderStepNumber()}
        <div className="ProgressBlocks-stepSeparator" />
      </div>
      <div className="ProgressBlocks-content">
        <div className="Panel Panel--invisible p-top-none p-bottom-none">
          <div className="ContentHeading">
            <ContentIcon className="ContentHeading-icon SubjectIcon--large" content={content} />
            <div className="ContentHeading-text">
              <span className="ContentHeading-subject">{content.subject_name}</span>
              <span className="ContentHeading-content">{content.name}</span>
            </div>
          </div>
          {!content.can_access && <p className="u-textYellow">{t('noLicense')}</p>}
          <p>{content.description}</p>
          <div className="Showcase Showcase--flex">
            <div className="Descriptor u-textBlack">
              <i
                className={`Descriptor-icon custom-icon icon-color-black icon-${content.content_type.toLowerCase()}`}
              />
              <span className="Descriptor-text">{content.content_type}</span>
            </div>
            <div className="Descriptor u-textBlack">
              <i className="Descriptor-icon fa fa-clock-o" />
              <span className="Descriptor-text">
                {content.duration}
                {' '}
                {t('minute', { count: content.duration })}
              </span>
            </div>
            <div className="Descriptor u-textBlack">
              <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
              <span className="Descriptor-text">
                {content.cpd_credits}
                {' '}
                {t('cpdCredits')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;

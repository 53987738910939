import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ProgressBar from '../../DesignSystem/ProgressBar';
import Accordion from '../../DesignSystem/Accordion';
import { GetTrackerStateForContent, ProgressTracker, ProgressTrackerStep } from '../../DesignSystem/ProgressTracker';
import ContentIcon from '../../Common/Components/ContentIcon';
import SubjectIcon from '../../DesignSystem/SubjectIcon';
import ProgressDonut from '../../Common/Components/ProgressDonut';
import ContentActions from './ContentActions';
import Tooltip from '../../DesignSystem/Tooltip';

const Milestone = ({
  milestone,
  active,
  milestoneLocked,
  unlockRequirement,
  firstLocked,
  apiCalls,
  getData,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const [percentageComplete, setPercentageComplete] = useState(0);
  const [milestoneCompleted, setMilestoneCompleted] = useState(false);
  const [activeContentIndex, setActiveContentIndex] = useState(-1);
  const [locked, setLocked] = useState(milestoneLocked);

  useEffect(() => {
    let cumulativePercentageComplete = 0;

    milestone.contents.forEach(content => {
      cumulativePercentageComplete += (content.optional && content.skipped) ? 100 : content.progress;
    });

    const percentageContentsCompleted = Math.ceil(cumulativePercentageComplete / milestone.contents.length);
    setPercentageComplete(percentageContentsCompleted);
    if (percentageContentsCompleted === 100) setMilestoneCompleted(true);
    else setMilestoneCompleted(false);
  }, [milestone, milestone.contents]);

  useEffect(() => {
    let activeContentSet = false;
    milestone.contents.forEach((content, index) => {
      if (!activeContentSet && !content.completed && !content.skipped) {
        setActiveContentIndex(index);
        activeContentSet = true;
      }
    });
  });

  useEffect(() => {
    if (milestoneCompleted) setLocked(false);
    else setLocked(milestoneLocked);
  });

  const milestoneStatus = () => {
    if (milestoneCompleted) return ': Completed';
    if (locked) return `: Unlocks after ${unlockRequirement}`;

    return `: ${percentageComplete}% Complete`;
  };

  const milestoneStatusClass = () => {
    if (milestoneCompleted) return 'is-complete';
    if (locked) return `is-locked ${firstLocked ? 'first-locked' : ''}`;

    return '';
  };

  const contentStatus = content => {
    if (!content.optional) return t('mandatory');
    if (content.skipped) return t('skipped');

    return t('optional');
  };

  const contentStatusClass = () => {
    if (locked) return 'content-locked';

    return '';
  };

  const contentLink = content => {
    const startLink = `/${content.content_type.toLowerCase()}s/${content.slug}`;
    const examLink = `/exam_submissions/new?course_id=${content.id}`;

    const watchedExaminableCourse = content.content_type === 'Course' && content.percent_watched === 100 && content.has_live_exam;
    if (watchedExaminableCourse && !content.completed) return examLink;
    return startLink;
  };

  const milestoneOverdue = () => moment(milestone.deadline).isBefore(Date.today);

  function renderProgressTracker() {
    if (milestone.requires_skill_check) {
      return (
        <div className="ContentSummary">
          <div className="ContentSummary-imageContainer">
            <img className="ContentSummary-image" src="/assets/take-skill-check.png" />
          </div>
          <div className="ContentSummary-content">
            <h2>{t('milestoneSkillCheckTitle')}</h2>
            <p>{t('milestoneSkillCheckContent')}</p>
            {!milestoneLocked && (
              <a
                className="content-action button small"
                href={`/skill_checks/new?milestone_id=${milestone.id}`}
              >
                {t('takeSkillCheck')}
              </a>
            ) }
          </div>
        </div>
      );
    }
    return (
      <ProgressTracker>
        {milestone.contents.map((content, index) => (
          <ProgressTrackerStep
            key={`milestone_${milestone.id}_progress_tracker_step_for_content_${content.id}`}
            state={GetTrackerStateForContent(content)}
          >
            <Accordion
              open={active && index === activeContentIndex}
              showChevron={!locked}
              data-test-id="contentDetails"
              className={contentStatusClass()}
              openable={!locked}
              summary={(
                <>
                  <ContentIcon content={content} />
                  <span className="u-grow1">
                    {content.software_name}
                    :
                    {' '}
                    {content.name}
                  </span>
                  {content.learning_context === 'semester' && (
                    <div className="course-status">
                      {contentStatus(content)}
                    </div>
                  )}
                  <div className="ContentHeading-progress">
                    <ProgressDonut className="ContentHeading-donut" percentage={content.progress} />
                    <span className="ContentHeading-percentage">
                      {content.progress}
                      %
                    </span>
                  </div>
                </>
              )}
            >
              <div className="ContentSummary">
                <a
                  href={contentLink(content)}
                  className="ContentSummary-imageContainer"
                >
                  <img className="ContentSummary-image" src={content.image} />
                  <div
                    className="ContentSummary-imageOverlay"
                    style={{ backgroundColor: `${content.subject_colour}44` }}
                  >
                    <i
                      className="ContentSummary-imageOverlayIcon fa fa-play-circle"
                      style={{ color: content.subject_colour }}
                    />
                  </div>
                  <span className="ContentSummary-durationPill">
                    {content.duration}
                    {' '}
                    {t('minutesShort')}
                  </span>
                </a>
                <div className="ContentSummary-content">
                  <p>{content.description}</p>
                  <ContentActions
                    content={content}
                    apiCalls={apiCalls}
                    reloadCallback={() => getData(true)}
                  />
                </div>
              </div>
            </Accordion>
          </ProgressTrackerStep>
        ))}
      </ProgressTracker>
    );
  }

  return (
    <>
      <Accordion
        large
        data-tip
        data-for={`milestone-locked-${milestone.id}`}
        data-test-id="milestoneDetails"
        open={active}
        showChevron={false}
        className={milestoneStatusClass()}
        summary={(
          <>
            <SubjectIcon
              className="SubjectIcon--large"
              icon={milestone.subject.logo}
              colour={milestoneLocked ? '#ADADAD' : milestone.subject.colour}
            />
            <span className="u-grow1">
              <div className="u-flexRow">
                <span>
                  <span>{milestone.name}</span>
                  <span className="u-normal">{milestoneStatus()}</span>
                </span>
                {milestone.deadline && !milestoneCompleted && (
                  <span className={`u-grow1 u-textRight ${milestoneOverdue() ? 'u-textRed' : ''}`}>
                    {milestoneOverdue() && <i className="fa fa-exclamation-circle red-icon" />}
                    &nbsp;
                    <span className="u-bold m-right-xs">{t('milestoneDueDateLabel')}</span>
                    <span className="u-normal">{moment(milestone.deadline).format('Do MMMM YYYY')}</span>
                  </span>
                )}
              </div>
              {percentageComplete < 100 && !milestoneCompleted && !milestoneLocked && (
                <div className="u-flexRow">
                  <ProgressBar
                    className="ProgressBar--flat ProgressBar--slim m-bottom-none"
                    percentage={percentageComplete}
                    showPercentageText={false}
                    pulse={false}
                  />
                </div>
              )}
            </span>
          </>
        )}
      >
        {renderProgressTracker()}
      </Accordion>
      {locked && (
        <Tooltip
          id={`milestone-locked-${milestone.id}`}
          heading={t('milestoneLockedTitle')}
          text={t('milestoneLockedBody')}
        />
      )}
    </>

  );
};

export default Milestone;

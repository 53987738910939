import React, {
  useMemo, useCallback,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Grid, Input, Link, ThemeProvider, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import LottieContainer from './LottieContainer';
import KubicleMuiTheme from '../../../lib/utils/muiTheme';

const StyledLottie = styled(LottieContainer)(({ theme }) => ({
  height: '200px',
  margin: theme.spacing(1),
}));

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 3,
  borderRadius: 4,
  borderColor: KubicleMuiTheme.palette.grey[100],
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  transition: 'border .3s ease-in-out',
  'min-height': '420px',
};

const activeStyle = {
  borderColor: KubicleMuiTheme.palette.colors.Denim2,
  backgroundColor: KubicleMuiTheme.palette.colors.Denim6,
};

const acceptStyle = {
  borderColor: KubicleMuiTheme.palette.colors.RobinsEgg2,
  backgroundColor: KubicleMuiTheme.palette.colors.RobinsEgg6,
};

const rejectStyle = {
  borderColor: KubicleMuiTheme.palette.colors.Grenadier2,
  backgroundColor: KubicleMuiTheme.palette.colors.Grenadier6,
};

const FileDropzone = ({
  returnFiles,
  multipleFiles,
  accepted_files = {},
}) => {
  const { t } = useTranslation('FileDropzone');
  const maxSizeMegaBytes = 10;
  const maxSizeBytes = maxSizeMegaBytes * 1024 * 1024;

  const getAcceptedFileTypes = () => {
    const file_types = {};
    if (accepted_files.text === true) file_types['text/plain'] = ['.txt'];
    if (accepted_files.image === true) file_types['image/*'] = ['.png', '.jpg', '.jpeg'];

    return file_types;
  };

  const getHumanReadableAcceptedFileFormats = () => {
    const file_types = getAcceptedFileTypes();
    const human_file_types = [];

    Object.keys(file_types).forEach(key => {
      human_file_types.push(file_types[key]);
    });

    return human_file_types.flat().join(' ');
  };

  const onDrop = useCallback(acceptedFiles => {
    returnFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: getAcceptedFileTypes(),
    maxSize: maxSizeBytes,
    multiple: !!multipleFiles,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ]);

  return (
    <ThemeProvider theme={KubicleMuiTheme}>
      <Grid container {...getRootProps()} alignItems="center" sx={style}>
        <Input {...getInputProps()} />
        <StyledLottie src="/assets/lottie/projects/assignment-choose-file.json" onAnimationComplete={() => {}} />
        { acceptedFiles && acceptedFiles.map(file => <p key={file.name}>{ file.name }</p>) }
        <Button variant="contained" endIcon={<UploadIcon />} onClick={open}>Select File</Button>
        { fileRejections && fileRejections.length > 0 && (
          <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
            <Box><b>{t('invalidFile')}</b></Box>
          </Grid>
        )}
        { acceptedFiles && acceptedFiles.length > 0 && (
          <Box display="flex" alignItems="center" textAlign="center" sx={{ mt: 2 }}>
            <Typography>
              {t('aiAgreement')}
              <Link href="https://support.kubicle.com/assignments-within-projects-on-kubicle" target="_blank"> {t('learnMore')}.</Link>
            </Typography>
          </Box>
        )}
        { acceptedFiles && acceptedFiles.length <= 0 && (
          <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
            <Box><b>{t('maxFileSize')}:</b> {maxSizeMegaBytes}MB</Box>
            <Box sx={{ mt: 0.5 }}><b>{t('fileFormatsAllowed')}:</b> {getHumanReadableAcceptedFileFormats()}</Box>
            <Box sx={{ mt: 2 }}>
              <Typography>
                <Link sx={{ textDecoration: 'underline' }} href="https://support.kubicle.com/exporting-png-or-jpeg-files-for-ai-assessments-in-projects" target="_blank">
                  <b>{t('troubleshootingUploads')}</b>
                </Link>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default FileDropzone;

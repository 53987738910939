import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box, Grid, Typography, Stack, Button,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { useTranslation } from 'react-i18next';

const SubmissionError = ({
  errorType,
  onBackButtonClick,
  submissionFile,
}) => {
  const { t } = useTranslation('ProjectSubmission', { keyPrefix: `errors.${errorType}` });
  const theme = useTheme();

  return (
    <Grid xs={12} sx={{ p: theme.spacing(1), py: 10, height: '100%' }}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="flex-end" mb={2}>
          <ErrorIcon fontSize="small" color="error" />
          <Typography variant="h5" color="error">
            {t('title')}
          </Typography>
        </Stack>
        {submissionFile && (
          <Stack direction="row" spacing={1} alignItems="flex-end" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Assignment Submission:</Typography>
            <Typography color="error"> {submissionFile} </Typography>
          </Stack>
        )}
        <Typography>{t('description')}</Typography>
        <Button variant="contained" endIcon={<AssignmentReturnIcon />} onClick={onBackButtonClick} sx={{ mt: 2 }}>
          <Typography variant="button">Resubmit</Typography>
        </Button>
      </Box>
    </Grid>
  );
};

export default SubmissionError;

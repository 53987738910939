import React from 'react';
import { useTranslation } from 'react-i18next';

const OverviewSection = ({
  overview, nextSectionButton,
}) => {
  const { t } = useTranslation('Exercises');

  return (
    <div className="exercise-section exercise-overview-section">
      <div className="content-section">
        <p className="overview-intro">
          <i className="fa fa-info-circle" />
          {' '}
          {t('OverviewIntro')}
        </p>
        <span dangerouslySetInnerHTML={{ __html: overview }} />
      </div>
      {nextSectionButton()}
    </div>
  );
};

export default OverviewSection;

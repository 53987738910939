import React, { useEffect, useState } from 'react';

import { getLocalStorage, setLocalStorage, removeLocalStorage } from '../../lib/utils/localStorage';
import SignInForm from './SignInForm';
import SignInEmailStep from './SignInEmailStep';
import SignInPasswordStep from './SignInPasswordStep';
import NewToKubicle from '../Common/Components/NewToKubicle';
import axiosClient from "../../lib/api/axiosClient";
import LoadingCube from "../Common/Components/LoadingCube";

const SignInPanel = ({ marketing_site_url }) => {
  const [data, setData] = useState({
    email: '',
    ssoUrl: null,
  });
  const [loading, setLoading] = useState(true);
  const headerText = email => (email ? `Hello, ${email}` : 'Sign in to your account');

  useEffect(() => {
    const email = getLocalStorage('userEmail');
    if (email) {
      axiosClient.get('/api/v1/sign-in.json', {
        params: {
          email,
        },
      }, { timeout: 20000 }).then(res => {
        if (res.data.sso_url) setData(oldData => ({ ...oldData, ssoUrl: res.data.sso_url }));
        else setData(oldData => ({ ...oldData, ssoUrl: null }));

        setData(oldData => ({ ...oldData, email }));
        setLoading(false);
      }).catch(() => {
        setData(oldData => ({ ...oldData, email: null }));
        setData(oldData => ({ ...oldData, ssoUrl: null }));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const signInFormStep = () => {
    if (data.email) {
      return (
        <SignInPasswordStep
          onChangeAccountClick={() => {
            removeLocalStorage('userEmail');
            removeLocalStorage('userSsoUrl');
            setData(oldData => ({ ...oldData, email: null }));
            setData(oldData => ({ ...oldData, ssoUrl: null }));
          }}
          email={data.email}
          ssoUrl={data.ssoUrl}
        />
      );
    }

    return (
      <SignInEmailStep
        onSubmitSuccess={submitData => {
          if (submitData?.email) {
            setLocalStorage('userEmail', submitData.email);
            setData(oldData => ({ ...oldData, email: submitData.email }));
          }

          if (!submitData?.ssoUrl) return;
          setData(oldData => ({ ...oldData, ssoUrl: submitData.ssoUrl }));
        }}
      />
    );
  };

  return (
    <div className="SplitLayout SplitLayout--tabletReverse">
      <div className="SplitLayout-slide SplitLayout-slide--marketing SplitLayout-slide--mobileFull">
        <div className="SplitLayout-slideInner">
          <NewToKubicle marketing_site_url={marketing_site_url} />
        </div>
      </div>
      <div className="SplitLayout-slide SplitLayout-slide--mobileFull">
        <div className="SplitLayout-slideInner">
          {loading ? <LoadingCube /> : (
            <SignInForm
              className={data.email ? 'step-2' : ''}
              headerClassName={data.email ? 'u-breakWord' : ''}
              headerText={headerText(data.email)}
              hasHeaderBlurb={!data.email}
            >
              {signInFormStep()}
            </SignInForm>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInPanel;

import React from 'react';

const Option = ({
  option, name, selected, correct, disabled, id, onChange = () => {},
}) => {
  const getOptionClass = () => {
    if (!selected || correct == null) return '';

    return `is-${correct ? 'correct' : 'incorrect'}`;
  };

  return (
    <div className="Option">
      <label className={`Option ${getOptionClass()}`}>
        {disabled && <input type="radio" id={id} className="Option-radio" name={name} checked={selected} readOnly value={option.id} />}
        {!disabled && <input type="radio" id={id} className="Option-radio" name={name} defaultChecked={selected} onChange={onChange} value={option.id} />}
        <span className="Option-label">{option.answer_text}</span>
      </label>
    </div>
  );
};

export default Option;

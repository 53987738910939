import React from 'react';

const BehindOfScheduleIcon = ({ className = '' }) => (
  <>
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18.685"
      height="47.104"
      viewBox="0 0 18.685 47.104"
    >
      <g id="Behind_Schedule" data-name="Behind Schedule" transform="translate(-123 -66.98)">
        <g id="Group_1536" data-name="Group 1536" transform="translate(123 66.98)">
          <g id="man" transform="translate(0 12.28)">
            <path
              id="Path_2090"
              data-name="Path 2090"
              d="M167.083,123.191H156.338a3.654,3.654,0,0,0-3.649,3.649V140.1a1.285,1.285,0,1,0,2.571,0V129.24a.714.714,0,0,1,1.428,0v25.98a2.154,2.154,0,0,0,4.306,0V140.383a.714.714,0,1,1,1.428,0V155.22a2.154,2.154,0,0,0,4.306,0V129.24a.714.714,0,0,1,1.428,0V140.1a1.285,1.285,0,0,0,2.571,0V126.841A3.654,3.654,0,0,0,167.083,123.191Z"
              transform="translate(-152.369 -122.87)"
              fill="#d43014"
            />
            <path
              id="Path_2091"
              data-name="Path 2091"
              d="M161.36,154.465a2.478,2.478,0,0,1-2.475-2.475V137.152a.393.393,0,0,0-.786,0V151.99a2.475,2.475,0,0,1-4.949,0V126.01a.393.393,0,1,0-.785,0v10.857a1.607,1.607,0,0,1-3.214,0V123.611a3.975,3.975,0,0,1,3.971-3.971h10.743a3.975,3.975,0,0,1,3.971,3.971v13.256a1.607,1.607,0,0,1-3.214,0V126.01a.393.393,0,0,0-.785,0v25.98a2.478,2.478,0,0,1-2.476,2.475Zm-2.868-18.348a1.037,1.037,0,0,1,1.035,1.035V151.99a1.832,1.832,0,0,0,3.664,0V126.01a1.035,1.035,0,0,1,2.071,0v10.857a.964.964,0,1,0,1.928,0V123.611a3.332,3.332,0,0,0-3.328-3.328H153.12a3.332,3.332,0,0,0-3.328,3.328v13.256a.964.964,0,0,0,1.928,0V126.01a1.035,1.035,0,0,1,2.071,0v25.98a1.832,1.832,0,0,0,3.664,0V137.152a1.037,1.037,0,0,1,1.038-1.035Z"
              transform="translate(-149.151 -119.64)"
              fill="#d43014"
            />
          </g>
          <path
            id="Path_2095"
            data-name="Path 2095"
            d="M37.872,10.518a5.259,5.259,0,1,0-5.259-5.259A5.265,5.265,0,0,0,37.872,10.518Z"
            transform="translate(-28.53 0)"
            fill="#d43014"
          />
        </g>
      </g>
    </svg>
  </>
);

export default BehindOfScheduleIcon;

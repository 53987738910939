import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import LearningPathCard from './LearningPathCard';
import LearningPathContentModal from './LearningPathContentModal';
import client from '../../../../lib/api/axiosClient';

const SemesterLearningPathSelection = ({
  learningGroupName,
  learningGroupUrl,
  baseNewSemesterUrl,
}) => {
  const { t } = useTranslation('Semester');
  const { t: lpt } = useTranslation('LearningPaths');

  const [learningPaths, setLearningPaths] = useState(null);
  const [filteredPaths, setFilteredPaths] = useState([]);
  const [selectedLearningPath, setSelectedLearningPath] = useState(null);

  const [filters, setFilters] = useState([]);
  const [filterIndex, setFilterIndex] = useState(-1);

  const defaultFilter = [{
    value: -1,
    label: 'All',
  }];

  useEffect(() => {
    loadLearningPaths();
  }, []);

  useEffect(() => {
    if (!learningPaths) return;

    const softwareList = learningPaths.reduce((acc, learningPath) => {
      if (acc.indexOf(learningPath.software_name) !== -1) return acc;
      acc.push(learningPath.software_name);
      return acc;
    }, []);
    setFilters(defaultFilter.concat(softwareList.map((elem, index) => ({
      value: index,
      label: elem,
    }))));
  }, [learningPaths]);

  useEffect(() => {
    if (!learningPaths) return;

    setFilteredPaths(learningPaths.filter(learningPath => filterIndex === -1 || learningPath.software_name === filters.find(f => f.value === filterIndex).label));
  }, [learningPaths, filters, filterIndex]);

  const onFilterChange = (selectedOption) => {
    setFilterIndex(parseInt(selectedOption.value, 10));
  };

  const loadLearningPaths = async () => {
    try {
      const response = await client.get(`/api/v1/learning_paths.json`);
      setLearningPaths(response.data.data.learning_paths);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) window.location.reload();
    }
  };

  const onLearningPathClick = (learningPath) => {
    setSelectedLearningPath(learningPath);
  };

  const onLearningPathModalCancel = () => {
    setSelectedLearningPath(null);
  };

  if (!learningPaths) return null;

  return (
    <>
      <div className="Banner u-bgLightGrey">
        <div className="Banner-container">
          <div className="Banner-content">
            <p>
              <a href={learningGroupUrl} className="u-textBlack">
                <span className="bold">
                  &lt;&nbsp;
                  {t('learningGroup')}
                </span>
                :
                {learningGroupName}
              </a>
            </p>
            <h2 className="u-textDarkBlue">
              {t('createNewSemesterTitle')}
            </h2>
            <p className="u-textBlack">
              {t('createNewSemesterLearningPathBlueprintDescription')}
            </p>
          </div>
        </div>
      </div>
      <section className="Page">
        <div className="Page-content">
          <div className="Filters">
            <div className="Filter">
              <div className="Filter">
                <label className="Filter-label">
                  {lpt('filterByTool')}
                  :
                </label>
                <Select
                  className="Filter-select"
                  isSearchable
                  defaultValue={defaultFilter}
                  onChange={onFilterChange}
                  options={filters}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>
          <div className="Showcase u-gridGap-ml">
            {
              filteredPaths.map(learningPath => (
                <LearningPathCard
                  key={`learning-path-${learningPath.id}`}
                  learningPath={learningPath}
                  onClick={onLearningPathClick}
                />
              ))
            }
          </div>
        </div>
      </section>
      {selectedLearningPath
        && (
          <LearningPathContentModal
            learningPathId={selectedLearningPath.id}
            onCancel={onLearningPathModalCancel}
            baseNewSemesterUrl={baseNewSemesterUrl}
          />
        )}
    </>
  );
};

export default SemesterLearningPathSelection;

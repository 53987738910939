import { useState } from 'react';
import client from '../api/axiosClient';

const useSqlEditor = ({ defaultSql = '', schema = 'public' }) => {
  const [query, setQuery] = useState(defaultSql);
  const [result, setResult] = useState([]);
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const runQuery = (e) => {
    e.preventDefault();
    runSql();
  };

  const runSql = () => {
    setErrorMessage(null);

    client
      .post('/api/v1/content_backend_sql_queries', { query, schema })
      .then(({ data }) => {
        const responseParsed = JSON.parse(data.backend_response);

        if (responseParsed.error) {
          setErrorMessage(responseParsed.error);
          setStatus('error');
          setResult([]);
          setFields([]);
        } else {
          setResult(responseParsed.values);
          setFields(responseParsed.fields);

          if(status === 'error') setStatus('');
        }
      });
  };

  return {
    query,
    setQuery,
    fields,
    result,
    status,
    errorMessage,
    runQuery,
  };
};

export default useSqlEditor;

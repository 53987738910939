import React, { useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid, ScopedCssBaseline, ThemeProvider, Typography, Button,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { useTranslation } from 'react-i18next';
import SubmissionError from './SubmissionError';
import client from '../../../../lib/api/axiosClient';
import KubicleMuiTheme from '../../../../lib/utils/muiTheme';
import SubmitAssignment from './SubmitAssignment';
import ProcessingAssignment from './ProcessingAssignment';
import SubmissionResult from './SubmissionResult';

const ProjectAssignmentStyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: theme.spacing(0.25),
  borderStyle: 'solid',
  borderColor: theme.palette.background.neutral,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.white,
  marginBottom: theme.spacing(2),
}));

const ProjectAssignment = ({
  projectId,
  step,
  selectedStep,
  totalStepsNumber,
  setStepCallback,
  reloadProjectCallback,
}) => {
  const { t } = useTranslation('ProjectSubmission');
  const [submissionFiles, setSubmissionFiles] = useState([]);
  const [submissionText, setSubmissionText] = useState(step.assessment_submission?.submission_text || '');
  const errorType = useMemo(() => step.assessment_submission?.feedback?.error?.type, [step]);
  const [currentStatus, setCurrentStatus] = useState('new');
  const isPassed = step.status === 'passed';

  const submitAssignment = () => {
    if (submissionFiles.length === 0 && submissionText === '') return;

    setCurrentStatus('processing');
    const formData = new FormData();
    if (step.submission_type === 'text') formData.append('assignment_submission_text', submissionText);
    else formData.append('assignment_submission[]', submissionFiles[0]);

    return client
      .post(`/api/v1/projects/${projectId}/project_attempts/submit_assignment_submission.json?project_step_id=${step.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      .then(() => {
        reloadProjectCallback(false).then(() => {
          setCurrentStatus('completed');
        });
      })
      .catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else setCurrentStatus('error');
      });
  };

  const resetPage = () => {
    setSubmissionFiles([]);
    setCurrentStatus('new');
  };

  const isSubmitDisabled = () => {
    if (currentStatus === 'processing') return true;

    if (step.submission_type === 'image' && submissionFiles.length <= 0) return true;

    if (step.submission_type !== 'image' && submissionText.length <= 0) return true;

    return false;
  };

  const renderStepButtons = () => {
    if (hasSubmissionError) return null;

    return (
      <ProjectAssignmentStyledGrid container justifyContent="flex-end" sx={{ mt: 1 }}>
        {selectedStep > 0 && (
          <Button variant="outlined" onClick={() => setStepCallback(selectedStep - 1)}>
            <Typography variant="button">{t('buttons.previous')}</Typography>
          </Button>
        )}
        {(!isPassed && (currentStatus === 'new' || currentStatus === 'processing')) && (
          <Button
            variant="contained"
            endIcon={<AutoAwesomeIcon />}
            onClick={submitAssignment}
            disabled={isSubmitDisabled()}
            sx={{ ml: 2 }}
          >
            <Typography variant="button">{t('buttons.submit')}</Typography>
          </Button>
        )}
        {currentStatus === 'completed' && !isPassed && (
          <Button
            variant="contained"
            endIcon={<AssignmentIcon />}
            onClick={() => resetPage()}
            sx={{ ml: 2 }}
          >
            <Typography variant="button">{t('buttons.submitNew')}</Typography>
          </Button>
        )}
        {selectedStep < totalStepsNumber && isPassed && (
          <Button variant="contained" onClick={() => setStepCallback(selectedStep + 1)} sx={{ ml: 2 }}>
            <Typography variant="button">{t('buttons.next')}</Typography>
          </Button>
        )}
      </ProjectAssignmentStyledGrid>
    );
  };

  const handleDroppedFiles = useCallback(files => setSubmissionFiles(files), [step]);
  const handleTextEditorChange = useCallback(content => setSubmissionText(content), [step]);
  const hasSubmissionError = currentStatus === 'completed' && errorType;

  const renderScreens = () => {
    if (hasSubmissionError) return <SubmissionError submissionFile={step.assessment_submission.file_name} errorType={errorType} onBackButtonClick={resetPage} />;

    if (currentStatus === 'completed' || isPassed) return <SubmissionResult step={step} fileName={submissionFiles[0]?.name} />;

    if (currentStatus === 'new') return <SubmitAssignment step={step} droppedFilesCallback={handleDroppedFiles} textEditorChangeCallback={handleTextEditorChange} currentStatus={currentStatus} />;

    if (currentStatus === 'processing') return <ProcessingAssignment fileName={submissionFiles[0]?.name} />;
  };

  return (
    <ThemeProvider theme={KubicleMuiTheme}>
      <ScopedCssBaseline sx={{ backgroundColor: 'background.lightest' }}>
        <StyledBox>
          {renderScreens()}
          {renderStepButtons()}
        </StyledBox>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default ProjectAssignment;

import React, { useState, useEffect } from 'react';

function usePageFocus() {
  const [hasFocus, setHasFocus] = useState(true);
  const [focusLost, setFocusLost] = useState(null);

  const checkFocus = () => {
    if (document.hidden) {
      setHasFocus(false);
      setFocusLost(new Date());
    } else {
      setHasFocus(true);
      setFocusLost(null);
    }
  };

  const getTimeWithoutFocus = () => {
    if (focusLost === null) return 0;
    return (new Date().getTime() - focusLost.getTime()) / 1000;
  };

  useEffect(() => {
    checkFocus();
    window.addEventListener('visibilitychange', checkFocus);
    return () => {
      window.removeEventListener('visibilitychange', checkFocus);
    };
  }, []);

  return {
    hasFocus,
    focusLost,
    getTimeWithoutFocus,
  };
}

export default usePageFocus;

export const NOT_EVALUATED = 0;
export const INCORRECT = 1;
export const CORRECT = 2;

export const answersInit = (questions) => questions.map((question) => ({
  questionId: question.id,
  userAnswer: null,
  answerEvaluation: NOT_EVALUATED,
}));

export const answersReducer = (state, action) => {
  const newState = [...state];

  switch (action.type) {
    case 'updateAnswer':
      newState[action.index].userAnswer = action.value;
      newState[action.index].answerEvaluation = NOT_EVALUATED;
      return newState;
    case 'setAnswersFromServer':
      return action.questions.map((question) => {
        const answer = action.data.find((userAnswer) => userAnswer.question_id === question.id);

        return {
          questionId: question.id,
          userAnswer: answer.answer,
          answerEvaluation: answer['correct?'] ? CORRECT : INCORRECT,
        };
      });
    default:
      throw new Error();
  }
};

export const allAnswersEvaluated = (answersState) => answersState.every((answer) => answer.answerEvaluation !== NOT_EVALUATED);

export const classNameForAnswer = (answerEvaluation) => {
  switch (answerEvaluation) {
    case NOT_EVALUATED:
      return '';
    case CORRECT:
      return 'correct';
    case INCORRECT:
      return 'incorrect';
    default:
      return '';
  }
};

import React, { useState } from 'react';
import Modal from "../Common/Components/Modal";

const ExerciseImage = ({ exerciseImageThumbnail, exerciseImageLink }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <div
        className="exercise-thumbnail-wrapper"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <img className="exercise-thumbnail" src={exerciseImageThumbnail} />
        <i className="fa fa-3 fa-expand" aria-hidden="true" />
      </div>

      <Modal
        isOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
        key="exercise_image_modal"
        className="exercise-image-modal"
      >
        <div className="modal_container">
          <img src={exerciseImageLink} />
        </div>
      </Modal>
    </div>
  );
};

export default ExerciseImage;

import React from 'react';
import NotificationMessage from '../Common/Components/NotificationMessage';

const SqlExerciseMessage = ({
  status, errorMessage, showAnswer, nextStepIsSolution,
}) => {
  switch (status) {
    case 'error':
      return <ExerciseErrorMessage errorMessage={errorMessage} showAnswer={showAnswer} />;
    case 'incorrect':
      return <AnswerIncorrectMessage showAnswer={showAnswer} />;
    case 'show-answer':
      return <ShowAnswerMessage nextStepIsSolution={nextStepIsSolution} />;
    case 'success':
      return <AnswerCorrectMessage nextStepIsSolution={nextStepIsSolution} />;
    default:
      return '';
  }
};

export const ExerciseErrorMessage = ({ errorMessage, showAnswer }) => (
  <NotificationMessage className="error-section">
    <h2>An Error Occurred: </h2>
    <p>{errorMessage}</p>
    <p>
      &bull; Try Again or&nbsp;
      <a onClick={showAnswer}>Show Answer.</a>
    </p>
  </NotificationMessage>
);

export const AnswerIncorrectMessage = ({ showAnswer }) => (
  <NotificationMessage className="error-section">
    <h2>Incorrect Answer</h2>
    <p>
      &bull; Try Again or&nbsp;
      <a onClick={showAnswer}>Show Answer.</a>
    </p>
  </NotificationMessage>
);

export const AnswerCorrectMessage = ({ nextStepIsSolution }) => (
  <NotificationMessage className="success-section">
    <h2>Correct!</h2>
    <NextStepMessage nextStepIsSolution={nextStepIsSolution} />
  </NotificationMessage>
);

export const ShowAnswerMessage = ({ nextStepIsSolution }) => (
  <NotificationMessage className="show-answer-section">
    <NextStepMessage nextStepIsSolution={nextStepIsSolution} />
  </NotificationMessage>
);

const NextStepMessage = ({ nextStepIsSolution }) => (
  nextStepIsSolution()
    ? <p>&bull; Click &quot;Watch Solution&quot; to watch the solution video.</p>
    : <p>&bull; Click &quot;Next&quot; to continue to the next section.</p>
);

export default SqlExerciseMessage;

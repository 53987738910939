import React from 'react';
import ReactDOM from 'react-dom';

export default function ({ question, onChange, key }) {
  return (
    <div className="skill-check-question-container" key={key}>
      <div className="skill-check-question">
        <p>{question.question}</p>
      </div>
      <div className="skill-check-answer">
        <div className="radio-options unbuttoned">
          <input
            type="radio"
            name={`answers[${question.id}]`}
            id={`answers_${question.id}_true`}
            value="true"
            checked={question.answer === 'true'}
            onChange={onChange}
          />
          <label htmlFor={`answers_${question.id}_true`}>Yes</label>

          <input
            type="radio"
            name={`answers[${question.id}]`}
            id={`answers_${question.id}_false`}
            value="false"
            checked={question.answer === 'false'}
            onChange={onChange}
          />
          <label htmlFor={`answers_${question.id}_false`}>No</label>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import client from '../../../lib/api/axiosClient';
import ExamBanner from "./ExamBanner";
import IncorrectAnswer from "./IncorrectAnswer";
import ExamDownload from "./ExamDownload";

const ExamSubmission = ({ user_id, submission_id, auto_submit }) => {
  const { t } = useTranslation('Exam');
  const [examSubmission, setExamSubmission] = useState();

  useEffect(() => {
    client.get(`/api/v1/exam_submissions/${submission_id}.json`)
      .then(res => setExamSubmission(res.data));
  }, []);

  if (!examSubmission) return null;

  const handleLinkedInSharing = (sharingUrl) => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharingUrl)}`, 'sharer', 'toolbar=0, status=0, width=626, height=436');
    return false;
  };

  return (
    <>
      <ExamBanner
        user_id={user_id}
        examSubmission={examSubmission}
        auto_submit={auto_submit}
        handleLinkedInSharing={handleLinkedInSharing}
      />
      <div className="Page">
        <div className="Page-content">
          {examSubmission.counts.incorrect > 0 && (
            <div className="Panel">
              <h2>{t('incorrectAnswers')}</h2>
              <p>
                {t('answerBreakdown', {
                  correct: examSubmission.counts.correct,
                  incorrect: examSubmission.counts.incorrect,
                  unanswered: examSubmission.counts.unanswered,
                })}
              </p>
              <ExamDownload
                prompt={false}
                examFileUrl={examSubmission.exam.exam_file}
                sqlExam={examSubmission.exam.sql_exam}
                examId={examSubmission.exam.id}
                className="Button Button--secondary Button--grid"
              />
              {examSubmission.incorrect_answers.map(answer => (
                <IncorrectAnswer
                  key={`incorrect-answer-${answer.question_id}`}
                  answer={answer}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExamSubmission;

import React from 'react';
import { Trans, useTranslation } from "react-i18next";

const ExamFailed = ({ examSubmission, auto_submit }) => {
  const { t } = useTranslation('Exam');

  return (
    <div className="CompletionState">
      <img src="/assets/exam/exam-failed.png" className="CompletionState-image" />
      <div className="CompletionState-content">
        <h2 className="CompletionState-title">{auto_submit ? t('outOfTime') : t('examFailed')}</h2>
        <div className="CompletionState-text">
          <p>
            <Trans t={t} i18nKey="didNotPassExam" values={{ course_name: examSubmission.exam.course_name, percent: examSubmission.percent }}>
              <span className="u-bold" />
              <span className="u-bold" />
            </Trans>
          </p>
          <ul>
            <li>{t('correctCount', { count: examSubmission.counts.correct })}</li>
            <li>{t('incorrectCount', { count: examSubmission.counts.incorrect })}</li>
            <li>{t('unansweredCount', { count: examSubmission.counts.unanswered })}</li>
          </ul>
        </div>
        <div className="CompletionState-actions">
          <a className="Button Button--primary" href={`/exam_submissions/new?course_id=${examSubmission.exam.course_id}`}>
            <i className="custom-icon icon-exam" />
            <span>{t('retakeExam')}</span>
          </a>
          <a className="Button Button--secondary" href={examSubmission.exam.course_path}>
            <i className="custom-icon icon-lesson icon-color-blue" />
            <span>{t('reviewLessons')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExamFailed;

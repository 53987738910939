import React from 'react';
import { useTranslation } from 'react-i18next';

const LearningPathEmptyState = () => {
  const { t } = useTranslation('LearnDashboard');

  return (
    <div className="learn-screen-container">
      <div className="semester-content-wrapper semester-with-stats">
        <div className="EmptyState">
          <h4 className="EmptyState-title">{t('learningPathsTitle')}</h4>
          <img className="EmptyState-image" src="/assets/empty_states/learning_paths.png" />
          <p className="EmptyState-description">{t('learningPathsDescription')}</p>
          <a href="/learning_paths" className="EmptyState-action Button Button--primary">{t('viewLearningPaths')}</a>
        </div>

      </div>
      <div className="semester-stats" />
    </div>
  );
};

export default LearningPathEmptyState;

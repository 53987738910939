import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCopyToClipboard() {
  const { t } = useTranslation('CopyToClipboard');
  const [copiedText, setCopiedText] = useState(null);
  const [error, setError] = useState(null);

  const copy = async text => {
    if (!navigator?.clipboard) {
      setError(t('clipboardUnsupported'));
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setError(t('copyFailed'));
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, error, copy];
}

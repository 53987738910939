import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../lib/api/axiosClient';
import Form from '../../DesignSystem/Forms/Form';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import Field from '../../DesignSystem/Forms/Field';
import Toasts from '../../DesignSystem/Toasts';
import Toast, { ToastStates } from '../../DesignSystem/Toast';

const MandatoryProfileData = ({
  user_id,
  user_name,
  learning_group,
  organisation,
  missing_location = false,
  missing_title = false,
  locations = [],
  titles = [],
}) => {
  const { t } = useTranslation('MandatoryProfileData');
  const [data, setData] = useState({});

  const [errors, setErrors] = useState({});
  const [networkError, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);

  const submit = async () => {
    if (actionInProgress) return;

    setErrors({});
    setNetworkError(null);
    setActionInProgress(true);

    if (missing_location && !data.location) {
      setErrors({ location: t('locationRequired') });
      setActionInProgress(false);
      return;
    }

    if (missing_title && !data.title) {
      setErrors({ title: t('titleRequired') });
      setActionInProgress(false);
      return;
    }

    try {
      const res = await axiosClient.put(`/api/v1/users/${user_id}.json`, {
        user: data,
      }, { timeout: 20000 });
      setActionInProgress(false);

      if (res.data.success) setUpdateDone(true);
    } catch (error) {
      if (error.response?.data) {
        setErrors(error.response.data.errors);
        setActionInProgress(false);
      } else {
        setNetworkError(`Error: ${error.message}`);
        setActionInProgress(false);
      }
    }
  };

  const handleChange = (name, value) => {
    setData(oldData => {
      oldData[name] = value;
      return oldData;
    });
  };

  const renderFields = () => (
    <>
      {missing_location
        && (
          <Field
            type="select"
            name="location"
            label={t('location')}
            onChange={handleChange}
            value={data.location}
            error={errors.location}
            containerClass="is-full-width"
            options={locations}
            required
          />
        )}

      {missing_title
        && (
          <Field
            type="select"
            name="title"
            label={t('title')}
            onChange={handleChange}
            value={data.title}
            error={errors.title}
            options={titles}
            containerClass="is-full-width"
            required
          />
        )}
    </>
  );

  const renderSubmit = () => (
    <input
      type="submit"
      className={`Form-item Button Button--primary Button--formSubmit is-full-width ${actionInProgress ? 'is-inactive' : ''}`}
      disabled={actionInProgress}
      value={t('completeRegistration')}
    />
  );

  const renderForm = () => (
    <Form onSubmit={submit} disabled={actionInProgress}>
      <h2>
        {user_name}
        {t('pageTitle')}
      </h2>
      <p>{t('pageContent')}</p>
      <Fieldset isSplit>
        {renderFields()}
        {renderSubmit()}
      </Fieldset>
    </Form>
  );

  const renderConfirmation = () => (
    <>
      <h1>{t('profileUpdatedTitle')}</h1>
      <p>{t('profileUpdatedContent')}</p>
      <p>
        <a id="continue_to_learn_dashboard" href="/">{t('continueToLearnDashboard')}</a>
      </p>
    </>
  );

  return (
    <>
      <Toasts>
        {networkError && (
          <Toast
            state={ToastStates.ERROR}
            title={t('failedToSubmit')}
          >
            <p>{networkError}</p>
          </Toast>
        )}
      </Toasts>
      <div className="SplitLayout SplitLayout--tabletReverse">
        <div className="SplitLayout-slide SplitLayout-slide--marketing">
          <div className="SplitLayout-slideInner">
            <div className="Blurb">
              <div className="EmptyState">
                <img className="EmptyState-image" src="/assets/logo/kubicle-logo-white-200px.svg" />
                <img className="EmptyState-image" src="/assets/12_col.gif" />
              </div>
              <h2 className="Blurb-title">
                {organisation}
                {' '}
                {t('signupPortal')}
              </h2>
              <h2 className="Blurb-title">{t('learningGroup')}</h2>
              <h2 className="Blurb-title">{learning_group}</h2>
            </div>
          </div>
        </div>
        <div className="SplitLayout-slide">
          <div className="SplitLayout-slideInner">
            {updateDone ? renderConfirmation() : renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default MandatoryProfileData;

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Content from './Content';
import LearningPathStatistics from './LearningPathStatistics';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import EmptyState from './EmptyState';
import SemesterStatistics from './SemesterStatistics';
import RequiresSkillCheckPanel from './RequiresSkillCheckPanel';
import Resources from './Resources';
import Bookmarks from './Bookmarks';
import LottieContainer from '../../Common/Components/LottieContainer';
import Milestone from './Milestone';
import Emoji from '../../DesignSystem/Emoji';

const DashboardTab = ({
  user_id,
  getData = () => {
  },
  tabData,
  tabType,
  tabName,
  allContentCompleted,
  apiCalls,
  hasLibraryAccess = true,
  bookmarks = [],
  newLearningPathEnrollment,
  showNewLearningPath,
  showCalendarCta,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const [contentFilter, setContentFilter] = useState('todo');
  const [activeContents, setActiveContents] = useState([]);
  const [activeMilestone, setActiveMilestone] = useState(-1);

  useEffect(() => {
    getData();
  }, [tabData]);

  useEffect(() => {
    if (activeContents.length === 0) initializeActiveContents();
  }, [tabData, tabData.data]);

  useEffect(() => {
    if (!tabData.data) return;
    if (tabType !== 'semester') return;

    let activeMilestoneFound = false;

    tabData.data.milestones.forEach((milestone, milestoneIndex) => {
      milestone.contents.forEach(content => {
        if (!activeMilestoneFound && !content.skipped && !content.completed) {
          setActiveMilestone(milestoneIndex);
          activeMilestoneFound = true;
        }
      });
    });
  }, [tabData, tabData.data]);

  const initializeActiveContents = () => {
    if (!tabData.data) return;

    if (tabType === 'semester') {
      setActiveContents([]);
      return;
    }

    const arr = [];

    ['all', 'todo', 'done'].forEach(filter => {
      if (tabData.data.contents[filter].length > 0) arr.push(tabData.data.contents[filter][0].id);
    });

    setActiveContents(arr);
  };

  const toggleActiveContent = contentId => {
    if (activeContents.indexOf(contentId) > -1) {
      const arr = [...activeContents];
      arr.splice(activeContents.indexOf(contentId), 1);
      setActiveContents(arr);
    } else {
      setActiveContents([...activeContents, contentId]);
    }
  };

  const renderEmptyState = () => {
    if (tabData.data.metadata.counters[contentFilter] === 0) {
      return (
        <EmptyState
          filter={contentFilter}
          semesterName={tabData.data.name}
          downloadUrl={tabData.data.download_diploma_url}
          shareUrl={tabData.data.share_diploma_url}
          progress={tabData.data.progress}
          allContentCompleted={allContentCompleted}
          tabType={tabType}
          user_id={user_id}
        />
      );
    }
  };

  const renderCalendarPreferenceCta = () => (
    <div className="calendar-preferences-cta">
      <span className="emoji-wrapper">
        <Emoji symbol="📚" label="books" />
        <Emoji symbol="🔔" label="bell" />
      </span>

      <Trans t={t} i18nKey="calendarPreferenceCta">
        <a href={`/users/${user_id}/calendar_preferences`}>Sync Due Dates with your Calendar</a>
      </Trans>
    </div>
  );

  const renderSidebar = () => {
    switch (tabType) {
      case 'learning_path':
        return (
          <div className="semester-stats">
            <LearningPathStatistics progress={tabData.data.progress} />
            {tabData.data.prerequisite_path
            && (
              <div className="Panel m-top-l">
                <h3 className="prerequisite-heading">
                  <i className="custom-icon icon-learning-path icon-color-navy Panel-headingIcon" />
                  <span>{t('prerequisitePath')}</span>
                </h3>
                <a href={`/learning_paths/${tabData.data.prerequisite_path.slug}`}>{tabData.data.prerequisite_path.name}</a>
              </div>
            )}
            <a
              className="learning-path-unenroll"
              onClick={() => apiCalls.unenrollFromLearningPath(tabData.id)}
            >
              {t('unenrollFromLearningPath')}
            </a>
          </div>
        );
      case 'semester':
        return (
          <SemesterStatistics
            semester={tabData}
            pacing={tabData.data.pacing}
            progress={tabData.data.progress}
            downloads={tabData.data.downloads}
          />
        );
      case 'saved':
        return (
          <div className="semester-stats">
            <Resources downloads={tabData.data.downloads} />
            <Bookmarks
              bookmarks={bookmarks}
              getBookmarks={() => apiCalls.getBookmarks()}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderSemesterMilestonesContent = () => (
    <div className="milestones-container">
      {
        tabData.data.milestones.map((milestone, index) => (
          <Milestone
            key={`milestone-${index + 1}`}
            milestone={milestone}
            active={index === activeMilestone}
            milestoneLocked={tabData.data.gated_milestones ? (activeMilestone > -1 && index > activeMilestone) : false}
            unlockRequirement={`Milestone ${index}`}
            firstLocked={index === activeMilestone + 1}
            getData={getData}
            apiCalls={apiCalls}
          />
        ))
      }
    </div>
  );

  const renderLearningContent = () => {
    if (!tabData.loaded) {
      return (
        <KubicleLoading />
      );
    }
    if (tabType === 'semester' && tabData.data.requires_skill_check === true && !(tabData.data.milestones.length > 0)) return <RequiresSkillCheckPanel semesterId={tabData.id} />;

    if (tabType === 'semester') {
      return (
        <>
          {showCalendarCta && renderCalendarPreferenceCta()}
          {renderEmptyState()}
          {renderSemesterMilestonesContent()}
        </>
      );
    }
    return (
      <>
        {renderEmptyState()}
        {
          tabData.data.contents[contentFilter].map(content => (
            <Content
              key={`${content.content_type}-${content.id}-filter-${contentFilter}`}
              content={content}
              active={activeContents.indexOf(content.id) > -1}
              setActiveCallback={() => {
                toggleActiveContent(content.id);
              }}
              reloadCallback={() => getData(true)}
              apiCalls={apiCalls}
            />
          ))
        }
      </>
    );
  };
  const renderContent = () => {
    if (newLearningPathEnrollment) {
      return (
        <div className="learn-screen-container">
          <div className="semester-content-wrapper semester-with-stats">
            <div className="EmptyState">
              <h4 className="EmptyState-title">{t('loadingLearningPath')}</h4>
              <LottieContainer
                className="EmptyState-image"
                src="/assets/lottie/learning-path-loading.json"
                cover={false}
                loop={false}
                onAnimationComplete={() => {
                  showNewLearningPath();
                }}
              />
            </div>
          </div>
        </div>
      );
    } if (!tabData.data) {
      return (
        <div className="learn-screen-container">
          <div className="semester-content-wrapper">
            <KubicleLoading />
          </div>
        </div>
      );
    }
    if (tabType === 'semester') {
      return (
        <div className="learn-screen-container">
          <div className="semester-content-wrapper semester-with-stats">
            <div className="semester-header">
              <div className="semester-title">
                <h3>{tabName}</h3>
              </div>
            </div>
            <div className="clearfix" style={{ clear: 'both' }} />
            <div className="semester-content">
              {renderLearningContent()}
            </div>
          </div>
        </div>
      );
    }
    if (tabType === 'saved' && !hasLibraryAccess) {
      return (
        <div className="learn-screen-container">
          {renderSidebar()}
        </div>
      );
    }
    return (
      <div className="learn-screen-container">
        <div className="semester-content-wrapper semester-with-stats">
          <div className="semester-header">
            <div className="semester-title">
              <h3>{tabName}</h3>
            </div>
            <div className="semesters-filters">
              <a
                className={`filter ${contentFilter === 'todo' ? 'active' : ''}`}
                onClick={() => setContentFilter('todo')}
              >
                {t('To-Do')}
                {' '}
                (
                {tabData.data.metadata.counters.todo}
                )
              </a>
              <a
                className={`filter ${contentFilter === 'done' ? 'active' : ''}`}
                onClick={() => setContentFilter('done')}
              >
                {t('Completed')}
                {' '}
                (
                {tabData.data.metadata.counters.done}
                )
              </a>
              {tabType === 'semester' && (
                <a
                  className={`filter ${contentFilter === 'skipped' ? 'active' : ''}`}
                  onClick={() => setContentFilter('skipped')}
                >
                  {t('Skipped')}
                  {' '}
                  (
                  {tabData.data.metadata.counters.skipped}
                  )
                </a>
              )}
              <a
                className={`filter ${contentFilter === 'all' ? 'active' : ''}`}
                onClick={() => setContentFilter('all')}
              >
                {t('All')}
                {' '}
                (
                {tabData.data.metadata.counters.all}
                )
              </a>
            </div>
          </div>
          <div className="clearfix" style={{ clear: 'both' }} />
          <div className="semester-content">
            {renderLearningContent()}
          </div>
        </div>
        {renderSidebar()}
      </div>
    );
  };

  return renderContent();
};

export default DashboardTab;

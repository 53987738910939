import React from 'react';
import { useTranslation } from 'react-i18next';
import useCopyToClipboard from '../../lib/hooks/useCopyToClipboard';

const CopyToClipboard = ({
  copyContent,
  large,
}) => {
  const { t } = useTranslation('CopyToClipboard');
  const [copiedValue, copyError, copy] = useCopyToClipboard();

  const onCopyClickHandler = () => {
    copy(copyContent);
  };

  return (
    <div className={`CopyToClipboard ${large ? 'CopyToClipboard--large' : ''}`}>
      <div className="u-flexRow">
        <span className="CopyToClipboard-contentArea">
          <span className="CopyToClipboard-icon fa fa-link" />
          <span className="CopyToClipboard-content u-bold">{copyContent}</span>
        </span>
        <button className="Button Button--primary Button--large" onClick={onCopyClickHandler}>
          {copiedValue && t('copiedButtonLabel')}
          {copyError && copyError}
          {!(copiedValue || copyError) && t('copyButtonLabel')}
        </button>
      </div>
    </div>
  );
};

export default CopyToClipboard;

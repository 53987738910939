import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const Bookmarks = ({ bookmarks, getBookmarks }) => {
  const { t } = useTranslation('LearnDashboard');
  const [limit, setLimit] = useState(5);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    getBookmarks();
  }, []);

  const loadAll = () => {
    setLimit(bookmarks.length);
    setShowAll(true);
  };

  const loadFew = () => {
    setLimit(5);
    setShowAll(false);
  };

  const renderBookmarks = () => {
    if (bookmarks.length === 0) {
      return (
        <div dangerouslySetInnerHTML={{ __html: t('bookmarksEmptyMessage') }} />
      );
    }

    return (
      <>
        {
          bookmarks.slice(0, limit).map((bookmark) => (
            <a href={bookmark.link} target="_blank" rel="noopener noreferrer" key={bookmark.id}>

              <div className="bookmark" key={bookmark.id}>
                <span
                  className="SubjectIcon SubjectIcon--background"
                  style={{ backgroundColor: bookmark.subject.colour }}
                >
                  <i className="SubjectIcon-inner custom-icon icon-lesson" />
                </span>
                <div>
                  <strong>{bookmark.title}</strong>
                  <br />
                  <span>{bookmark.subject.title}</span>
                </div>
              </div>
            </a>
          ))
        }
      </>
    );
  };

  return (
    <div className="bookmarks">
      <h3>{t('bookmarkedLessons')}</h3>
      <div className="bookmarks-container">
        {renderBookmarks()}
      </div>
      {bookmarks.length > 5 && !showAll
      && (
        <a onClick={loadAll}>
          {t('viewAllBookmarks')}
          ...
        </a>
      )}
      {showAll
      && (
        <a onClick={loadFew}>
          {t('viewLessBookmarks')}
          ...
        </a>
      )}
    </div>
  );
};

export default Bookmarks;

import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid, Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import LottieContainer from '../../../Common/Components/LottieContainer';

const StyledLottie = styled(LottieContainer)({
  height: '100%',
});

const dropzoneStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  borderWidth: 4,
  borderRadius: 4,
  borderStyle: 'dashed',
  transition: 'border .3s ease-in-out',
  height: '420px',
  overflow: 'auto',
};

const ProcessingAssignment = ({ fileName }) => {
  const { t } = useTranslation('ProjectSubmission');

  const AssessmentHeading = ({ children }) => (
    <Typography variant="h3" sx={{ mb: 2 }}>
      {children}
    </Typography>
  );

  const renderWaitingForResponseMessage = () => (
    <Box>
      <AssessmentHeading>{t('waitingForResponse.heading')}</AssessmentHeading>
      <Typography><b>{t('assignmentSubmission')}</b> {fileName}</Typography>
      <Typography>{t('waitingForResponse.waitTime')}</Typography>
    </Box>
  );

  const ProjectAssignmentStyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
    height: '100%',
  }));

  return (
    <ProjectAssignmentStyledGrid container alignItems="center">
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        {renderWaitingForResponseMessage()}
      </ProjectAssignmentStyledGrid>
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        <Box sx={dropzoneStyle}>
          <StyledLottie src="/assets/lottie/projects/waiting-for-ai-assessment.json" />
        </Box>
      </ProjectAssignmentStyledGrid>
    </ProjectAssignmentStyledGrid>
  );
};

export default ProcessingAssignment;
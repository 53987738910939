import React, { useState } from 'react';
import { ProgressBar, Step } from "react-step-progress-bar";
import ReactTooltip from "react-tooltip";
import AheadOfScheduleIcon from "./icons/AheadOfScheduleIcon";
import BehindOfScheduleIcon from "./icons/BehindOfScheduleIcon";
import OnScheduleIcon from "./icons/OnScheduleIcon";
import FinishLineIcon from "./icons/FinishLineIcon";
import CompletedIcon from "./icons/CompletedIcon";
import ExpectedProgressIcon from "./icons/ExpectedProgressIcon";
import SkillCheckNeededIcon from "./icons/SkillCheckNeededIcon";

const SemesterProgressBar = ({
  skillCheckRequired,
  currentProgress,
  progress,
  allCoursesOptional,
  expectedProgress = null,
}) => {
  const progressBarColor = () => {
    if (skillCheckRequired) return '#cddbe5';
    if (currentProgress === 100) return '#08c2bb';
    if (expectedProgress === null) return '#003663';
    if (allCoursesOptional) return '#003663';

    if (progress === 'ahead_of_schedule') return '#08c2bb';
    if (progress === 'very_behind_schedule') return '#d43014';
    if (progress === 'behind_schedule') return '#d43014';

    return '#003663';
  };

  const expectedProgressPosition = () => {
    if (expectedProgress < 5) return 4;
    if (expectedProgress > 95) return 95;

    return expectedProgress;
  };

  const expectedProgressIcon = () => {
    if (expectedProgress !== null && !skillCheckRequired && !allCoursesOptional && progress !== 'on_schedule' && currentProgress < 100) {
      return (
        <Step>
          {() => (
            <>
              <ExpectedProgressIcon className="progress-icon" />
              <ReactTooltip id="expected-progress" place="top" type="light" effect="solid" offset={{ top: 20 }}>
                <strong>Expected Progress</strong>
                <br />
                {Math.round(expectedProgress)}
                %
              </ReactTooltip>
            </>
          )}
        </Step>
      );
    }
    return (<></>);
  };

  const currentProgressPosition = () => {
    if (currentProgress < 4) return 3;
    if (currentProgress === 100) return 98;
    if (currentProgress > 95) return 95;

    return currentProgress - 4;
  };

  const currentProgressIcon = () => {
    if (skillCheckRequired) {
      return (
        <SkillCheckNeededIcon className="progress-icon" />
      );
    }
    if (currentProgress === 100) {
      return (
        <CompletedIcon className="progress-icon completed-icon" />
      );
    } if (expectedProgress === null || allCoursesOptional) {
      return (
        <OnScheduleIcon className="progress-icon" />
      );
    } if (progress === 'ahead_of_schedule') {
      return (
        <AheadOfScheduleIcon className="progress-icon" />
      );
    } if (['very_behind_schedule', 'behind_schedule'].includes(progress)) {
      return (
        <BehindOfScheduleIcon className="progress-icon" />
      );
    }
    return (
      <OnScheduleIcon className="progress-icon" />
    );
  };

  const progressLabelPosition = () => {
    const tmpPosition = currentPercent() / 2;
    if (tmpPosition < 7) return currentPercent() + 6;

    return tmpPosition;
  };

  const progressLabelIcon = () => {
    if (skillCheckRequired) {
      return (
        <Step>
          {() => (
            <div className="progress-label" />
          )}
        </Step>
      );
    }
    return (
      <Step>
        {() => (
          <div className="progress-label">
            {currentProgress}
            %
          </div>
        )}
      </Step>
    );
  };

  const finishLineIconRender = () => {
    if (currentProgress === 100) return (<></>);
    if (expectedProgress >= 95) return (<></>);

    return (
      <Step>
        {() => (
          <FinishLineIcon className="finish-line-icon" />
        )}
      </Step>
    );
  };

  const currentPercent = () => {
    if (currentProgress < 7) return 7;

    return currentProgress;
  };

  return (
    <div className="semester-progress-bar">
      <ProgressBar
        percent={currentPercent()}
        filledBackground={progressBarColor()}
        stepPositions={[100, expectedProgressPosition(), progressLabelPosition(), currentProgressPosition()]}
      >
        {finishLineIconRender()}
        {expectedProgressIcon()}
        {progressLabelIcon()}
        <Step>
          {() => (
            currentProgressIcon()
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default SemesterProgressBar;

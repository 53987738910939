import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import scrollToComponent from 'react-scroll-to-component';
import Content from './Content';
import SecondaryNotification from "../../Common/Components/SecondaryNotification";
import KubicleLoading from "../../Common/Components/KubicleLoading";
import UpcomingCourse from "./UpcomingCourse";
import client from '../../../lib/api/axiosClient';

const Subject = ({ id, userId, canSaveContent }) => {
  const { t } = useTranslation('Library');
  const [learningPlan, setSubject] = useState({});
  const [tracks, setTracks] = useState([]);
  const [showSecondaryNotification, setShowSecondaryNotification] = useState(false);
  const [secondaryNotificationMessage, setSecondaryNotificationMessage] = useState('');
  const [secondaryNotificationTitle, setSecondaryNotificationTitle] = useState('');
  const [secondaryNotificationLink, setSecondaryNotificationLink] = useState(null);
  const [secondaryNotificationCTA, setSecondaryNotificationCTA] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const refs = [];

  useEffect(() => loadSubject(), []);

  useEffect(() => {
    if (!isLoading) scrollToTrack();
  }, [isLoading]);

  const scrollToTrack = () => {
    const url = new URL(window.location);
    if(url.hash) {
      const section = document.querySelector(url.hash);
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scroll = (id) => scrollToComponent(refs[id], { offset: 0, align: 'top', duration: 500 });

  const loadSubject = () => {
    client
      .get(`/api/v1/subjects/${id}.json`)
      .then(({ data }) => {
        const tracksWithContent = data.data.tracks.filter(track => track.contents.length > 0);

        setSubject(data.data.subject);
        setTracks(tracksWithContent);
        setIsLoading(false);
      })
      .catch(error => {
        if(error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  };

  const saveContent = (content) => {
    client
      .post(`/api/v1/users/${userId}/user_saved_${content.content_type.toLowerCase()}s?${content.content_type.toLowerCase()}_id=${content.id}`)
      .then(_response => {
        setSecondaryNotificationMessage(t('addedToSavedContentMessage'));
        setSecondaryNotificationTitle(t('addedToSavedContent'));
        setSecondaryNotificationLink(`/users/${userId}/my_kubicle?tab=saved-content`);
        setSecondaryNotificationCTA(t('savedContent'));
        setShowSecondaryNotification(true);
      })
      .catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  };

  const removeFromSaved = (content) => {
    client
      .delete(`/api/v1/users/${userId}/user_saved_${content.content_type.toLowerCase()}s/${content.id}`)
      .then(_response => {
        setSecondaryNotificationMessage(t('removedFromSavedContentMessage'));
        setSecondaryNotificationTitle(t('removedFromSavedContent'));
        setSecondaryNotificationLink(`/users/${userId}/my_kubicle?tab=saved-content`);
        setSecondaryNotificationCTA('Saved Content');
        setShowSecondaryNotification(true);
      })
      .catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  };

  const renderContent = (content, index) => {
    if (content.live) {
      return (
        <Content
          canSaveContent={canSaveContent}
          content={content}
          index={index}
          userId={userId}
          key={content.id}
          saveContentCallback={() => saveContent(content)}
          removeFromSavedCallback={() => removeFromSaved(content)}
        />
      );
    } if (content.upcoming) {
      return (
        <UpcomingCourse
          course={content}
          index={index}
          userId={userId}
          key={content.id}
        />
      );
    }
  };

  const renderSubject = () => {
    if (isLoading) {
      return (
        <KubicleLoading />
      );
    }
    return (
      <>
        <div className="library subject">
          <div className="container-full-width white library-header">
            <div className="container">
              <div className="breadcrumbs">
                <a href="/library" className="link">
                  <span className="all">
                    {' '}
                    All Subjects
                    <i className="fa fa-chevron-right" aria-hidden="true" />
                    <span className="current-subject">
                      {learningPlan.name}
                    </span>
                  </span>
                  <span className="return">
                    <i className="fa fa-chevron-left" aria-hidden="true" />
                    {' '}
                    Return to Library
                  </span>
                </a>
                <a
                  href="https://support.kubicle.com/understanding-cpe-cpd-credits"
                  className="accreditation-guide"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-question-circle" aria-hidden="true" />
                  {t('cpdCreditGuide')}
                </a>
              </div>
              <div className="logo-wrapper">
                <div className="logo-container" style={{ backgroundColor: learningPlan.colour }}>
                  <div dangerouslySetInnerHTML={{
                    __html: learningPlan.logo,
                  }}
                  />
                </div>
              </div>
              <h1>
                {learningPlan.name}
              </h1>
              <p className="description">
                {learningPlan.description}
              </p>
            </div>
          </div>
          <div className="container-full-width subject-contents">
            <div className="container">
              <div
                className="tracks-list"
                id="categories-menu"
                data-magellan-expedition
                data-magellan-top-offset="99999"
              >
                <h2>Categories</h2>
                <ul>
                  {tracks.map((track) => (
                    <li key={track.id}>
                      <span data-href={`#${track.slug}`} onClick={() => scroll(track.id)}>
                        {track.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="courses-list">
                <h2>Contents</h2>
                {tracks.map((track, index) => {
                  let contentCount = 0;
                  return (
                    <div
                      className="track-content"
                      key={track.id}
                      id={track.slug}
                      ref={(section) => {
                        refs[track.id] = section;
                      }}
                    >
                      <h3 className="track-title">
                        {track.name}
                      </h3>
                      <p className="track-description">
                        {track.description}
                      </p>
                      {track.contents.map((content) => {
                        if (content.live || content.upcoming) {
                          contentCount += 1;
                          return renderContent(content, contentCount);
                        }
                        return undefined;
                      })}
                      {(() => {
                        if (!userId && ((index + 1) % 2 === 1)) {
                          return (
                            <div className="container track-content trial-cta">
                              <div className="m-top-m course-panel start-trial-cta cta-panel">
                                <p className="large">{t('workSmarter')}</p>
                                <a href="/select-user-type">
                                  <button className="white">{t('tryForFree')}</button>
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {showSecondaryNotification
            && (
              <SecondaryNotification
                title={secondaryNotificationTitle}
                message={secondaryNotificationMessage}
                link={secondaryNotificationLink}
                cta={secondaryNotificationCTA}
                className=""
                style={{ color: 'white', fontWeight: 'bold' }}
                target="_blank"
                type="notice"
                closeCallback={() => {
                  setShowSecondaryNotification(false);
                }}
              />
            )}
      </>
    );
  };

  return renderSubject();
};

export default Subject;

import React from 'react';

const FinishLineIcon = ({ className = '' }) => (
  <>
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="27.199"
      height="32"
      viewBox="0 0 27.199 32"
    >
      <path
        id="flag_1_"
        data-name="flag (1)"
        d="M27.8,11.6l4.08-5.44A1.6,1.6,0,0,0,30.6,3.6H11.4a1.6,1.6,0,0,0-3.2,0V30.8H6.6a1.6,1.6,0,0,0,0,3.2H13a1.6,1.6,0,0,0,0-3.2H11.4V19.6H30.6a1.6,1.6,0,0,0,1.28-2.56Z"
        transform="translate(-5 -2)"
        fill="#cddbe5"
      />
    </svg>
  </>
);

export default FinishLineIcon;

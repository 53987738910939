/* eslint-disable react/destructuring-assignment,react/sort-comp,react/jsx-no-bind,react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import $ from "jquery";
import ajaxError from '../../../lib/utils/ajaxError';

import SkillCheckQuestion from './SkillCheckQuestion';
import SkillStatus from './SkillStatus';
import SkillLevelSummary from './SkillLevelSummary';
import SkillCheckIcon from './SkillCheckIcon';
import LoadingCube from '../../Common/Components/LoadingCube';
import client from '../../../lib/api/axiosClient';

class SkillCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillCheckOpen: props.skillCheckOpen,
      skillLevel: props.skillLevel,
      questions: [],
      canSubmit: false,
    };
    this.toggleSkillCheck = this.toggleSkillCheck.bind(this);
    this.getSkillCheckQuestions = this.getSkillCheckQuestions.bind(this);
    this.submitSkillCheck = this.submitSkillCheck.bind(this);
    this.onOverlayClick = this.onOverlayClick.bind(this);
    this.closeSkillCheck = this.closeSkillCheck.bind(this);
    this.courseCompleteAction = this.courseCompleteAction.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  getSkillCheckQuestions() {
    client
      .get(`/api/v1/courses/${this.props.courseSlug}/skill_checks/new.json`)
      .then(({ data }) => this.setState({ questions: data }))
      .catch(error => {
        if (error.response?.status) ajaxError(error.response.status);
        else console.error(error);
      });
  }

  submitSkillCheck(event) {
    event.preventDefault();
    const data = { answers: {} };
    this.state.questions.forEach(question => {
      data.answers[question.id] = question.answer;
    });

    client
      .post(`/api/v1/courses/${this.props.courseSlug}/skill_checks`, data)
      .then(({ data }) => {
        this.props.setCourseState({
          skillLevel: data.skill_level,
          skillCheckOpen: true,
        });
      })
      .catch(error => {
        if (error.response?.status) ajaxError(error.response.status);
        else console.error(error);
      });
  }

  toggleSkillCheck(e) {
    e.preventDefault();
    const open = !this.state.skillCheckOpen;
    this.toggleOverlay(open);

    if (this.state.skillLevel === 0 && this.state.questions.length === 0) this.getSkillCheckQuestions();
  }

  onOverlayClick(e) {
    if (e.target.classList.contains("skill-check-overlay")) this.closeSkillCheck();
  }

  closeSkillCheck() {
    this.toggleOverlay(false);
  }

  toggleOverlay(shouldOpen) {
    this.setCourseState({ skillCheckOpen: shouldOpen, active: shouldOpen });

    if (this.props.addActiveToParent) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(this);

      if (shouldOpen) $(element).parents('div.course-panel').addClass('active');
      else $(element).parents('div.course-panel').removeClass('active');
    }
  }

  setCourseState(state) {
    if (this.props.setCourseState) this.props.setCourseState(state);
    else this.setState(state);
  }

  onAnswerChange(index, e) {
    const { questions } = this.state;
    questions[index].answer = e.target.value;

    this.setState({ questions, canSubmit: SkillCheck.determineIfCanSubmit(questions) });
  }

  static determineIfCanSubmit(questions) {
    // eslint-disable-next-line no-prototype-builtins
    return questions.every(question => question.hasOwnProperty('answer'));
  }

  skillCheckPanel() {
    if (this.state.skillCheckOpen) {
      let content;

      if (this.state.skillLevel === 0) {
        if (this.state.questions.length > 0) {
          const questions = this.state.questions.map((question, index) => (
            <SkillCheckQuestion
              question={question}
              onChange={this.onAnswerChange.bind(this, index)}
              id={index}
              key={index}
            />
          ));

          content = [
            <div className="skill-check-title">
              <SkillCheckIcon skillLevel={0} />
              <h2>
                <span className="skill-check-prefix">Skill Check</span>
                {' '}
                {this.props.courseName}
              </h2>
            </div>,
            <form onSubmit={this.submitSkillCheck}>
              {questions}
              <button disabled={!this.state.canSubmit}>Submit</button>
            </form>,
          ];
        } else {
          content = (
            <div className="loading-skill-check">
              <LoadingCube />
            </div>
          );
        }
      } else {
        content = [
          <div className="skill-check-title">
            <SkillCheckIcon skillLevel={0} />
            <h2>{this.props.courseName}</h2>
          </div>,
          <SkillLevelSummary
            skillLevel={this.state.skillLevel}
            courseSlug={this.props.courseSlug}
            summaryCTA={this.props.summaryCTA}
            courseSelection={this.props.courseSelection}
          />];
      }

      return (
        <div className="skill-check-overlay active" onClick={this.onOverlayClick}>
          <div className="skill-check-panel">
            <a className="close-skill-check" onClick={this.closeSkillCheck}><i className="fa fa-times" /></a>
            {content}
          </div>
        </div>
      );
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({
      skillCheckOpen: nextProps.skillCheckOpen,
      skillLevel: nextProps.skillLevel,
    });
  }

  render() {
    if (this.props.courseComplete) return this.courseCompleteAction();

    return (
      <div className="skill-level skill-check">
        <a onClick={this.toggleSkillCheck}>
          <SkillStatus
            skillLevel={this.state.skillLevel}
            semesterMandatorySkillLevel={this.props.semesterMandatorySkillLevel}
          />
        </a>
        {this.skillCheckPanel()}
      </div>
    );
  }

  courseCompleteAction() {
    if (this.props.certificateUrl) {
      return (
        <a className="course-complete" href={this.props.certificateUrl} target="_blank" rel="noreferrer">
          <i className="fa fa-download" aria-hidden="true" />
          {' '}
          Certificate
        </a>
      );
    }
    return (
      <div className="course-complete">Complete</div>
    );
  }
}

SkillCheck.defaultProps = {
  skillLevel: 0,
  skillCheckOpen: false,
};

SkillCheck.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  setCourseState: PropTypes.func.isRequired,
  skillLevel: PropTypes.number,
  skillCheckOpen: PropTypes.bool,
};

export default SkillCheck;

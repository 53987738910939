import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicResultsSection } from '../Common/SqlEditor/ResultsSection';
import SqlEditor, { RowCount } from '../Common/SqlEditor/SqlEditor';
import useSqlEditor from '../../lib/hooks/useSqlEditor';
import { openWindowLeft } from '../../lib/utils/openWindow';

const SqlSandbox = ({ imageUrl, databaseSchema }) => {
  const {
    query, setQuery, fields, runQuery, status, errorMessage, result,
  } = useSqlEditor({ schema: databaseSchema });
  const { t } = useTranslation('SqlEditor');

  return (
    <div>
      <div className="editor-container">
        <form>
          <SqlEditor {...{ query, setQuery, status }} />
          <div className="button-section">
            {fields.length > 0 ? <RowCount {...{ result }} /> : ''}
            <button className="button" onClick={runQuery}>{t('RunCode')}</button>
            {
              imageUrl
              && (
                <a
                  href={imageUrl}
                  className="button hollow"
                  onClick={(e) => {
                    e.preventDefault();

                    openWindowLeft(e.target.href);
                  }}
                >
                  {t('ShowSchema')}
                </a>
              )
            }
          </div>
        </form>

        <BasicResultsSection {...{ errorMessage, result, fields }} />
      </div>
    </div>
  );
};

export default SqlSandbox;

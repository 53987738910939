import React from 'react';
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import CTAPanel from "./CTAPanel";

const Banner = ({
  learningPath, user_id, enrollAction, actionInProgress, renderHours, renderEnrollButton,
}) => {
  const { t } = useTranslation('LearningPaths');

  return (
    <div className="Banner Banner--grid" style={{ backgroundImage: "url('/assets/banners/learning_paths.png')" }}>
      <div className="Banner-container">
        <div className="Banner-content">
          <div className="Breadcrumb">
            <div className="Breadcrumb-content">
              <a href="/learning_paths/" className="Breadcrumb-element u-textWhite">{t('learningPaths')}</a>
              <i className="Breadcrumb-separator fa fa-chevron-right u-textWhite" />
              <span className="Breadcrumb-element u-textWhite">{learningPath.name}</span>
            </div>
          </div>
          <h2 className="u-textWhite">{learningPath.name}</h2>
          <p className="u-textWhite">{learningPath.description}</p>
          <div className="Showcase Showcase--flex">
            <div className="Descriptor u-textWhite">
              <i className="Descriptor-icon fa fa-clock-o" />
              <span className="Descriptor-text">{renderHours()}</span>
            </div>
            <div className="Descriptor u-textWhite">
              <i className="Descriptor-icon custom-icon icon-cpe-cpd" />
              <a
                href="https://support.kubicle.com/understanding-cpe-cpd-credits"
                target="_blank"
                className="Descriptor-text DefinitionLink u-textWhite"
                data-tip
                data-for="cpdCredits"
                rel="noreferrer"
              >
                <span>{t('cpdCredits', { count: learningPath.cpd_credits })}</span>
              </a>
              <ReactTooltip
                id="cpdCredits"
                place="bottom"
                type="light"
                effect="solid"
                style={{ "white-space": "normal" }}
              >
                {t('cpdCreditsExplanation')}
              </ReactTooltip>
            </div>
          </div>
        </div>
        <CTAPanel
          className="Banner-extra"
          buttonOnly={false}
          user_id={user_id}
          learningPath={learningPath}
          actionInProgress={actionInProgress}
          enrollAction={enrollAction}
          renderEnrollButton={renderEnrollButton}
        />
      </div>
    </div>
  );
};

export default Banner;

import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import ContentSlide from '../../Common/Components/ContentSlide';
import SectionMessage, { SectionMessageStates } from '../../DesignSystem/SectionMessage';
import Field from '../../DesignSystem/Forms/Field';
import Fieldset from '../../DesignSystem/Forms/Fieldset';
import Checkbox from '../../DesignSystem/Forms/Fields/Checkbox';
import client from '../../../lib/api/axiosClient';
import MilestoneWizard from './MilestoneWizard';
import SemesterSummary from './SemesterSummary';
import Tooltip from '../../DesignSystem/Tooltip';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Toasts from '../../DesignSystem/Toasts';
import Icon from '../../DesignSystem/Icon';

const SemesterMilestonesForm = ({
  semester_id,
  learning_group_id,
  learning_path_id,
  learning_group_slug,
  kubicle_admin,
  gated_semesters,
}) => {
  const [semesterDetails, setSemesterDetails] = useState();
  const [semesterErrors, setSemesterErrors] = useState({});
  const [semesterWarnings, setSemesterWarnings] = useState({});
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [editMilestoneNameIndex, setEditMilestoneNameIndex] = useState();

  const [, setNetworkError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [actionButtonsDisabled, setActionButtonsDisabled] = useState(true);

  const [toastData, setToastData] = useState();

  const { t } = useTranslation('SemesterForm');

  const handleSaveAndNotifyClick = () => {
    submitSemesterData({ should_notify_learners: true });
  };

  const handleSaveClick = () => {
    submitSemesterData({ should_notify_learners: false });
  };

  const milestoneDurationReducer = (acc, milestone) => acc + milestone.contents.reduce(contentDurationReducer, 0);

  const contentDurationReducer = (acc, content) => acc + content.duration;

  const calculateDeadlines = () => {
    if (!semesterDetails?.milestones) return;

    if (semesterDetails.no_end_date) {
      removeDeadlines();
      removeSemesterErrors('end_date');
      return;
    }

    if (new Date(semesterDetails.start_date) >= new Date(semesterDetails.end_date)) {
      removeDeadlines();
      setSemesterErrors(prevState => ({ ...prevState, end_date: t('invalidEndDate') }));
      return;
    }

    removeSemesterErrors('end_date');

    setSemesterDetails(prevState => {
      const semesterLength = moment.duration(moment(semesterDetails.end_date).diff(moment(semesterDetails.start_date)));
      const totalDuration = prevState.milestones.reduce(milestoneDurationReducer, 0);
      const durations = prevState.milestones.map(ms => ms.contents.reduce(contentDurationReducer, 0));

      const newMilestones = prevState.milestones.map((m, index) => {
        const offsetDuration = durations.slice(0, index + 1).reduce((acc, d) => acc + d, 0);

        return {
          ...m,
          deadline: (moment(semesterDetails.start_date).add((offsetDuration / totalDuration) * semesterLength)).format('YYYY-MM-DD'),
        };
      });

      return {
        ...prevState,
        milestones: newMilestones,
      };
    });
  };

  const removeDeadlines = () => {
    setSemesterDetails(prevState => ({
      ...prevState,
      milestones: prevState.milestones.map(m => ({ ...m, deadline: undefined })),
    }));
  };

  const handleInputChange = (name, value) => {
    setSemesterDetails(pre => ({
      ...pre,
      [name]: value,
    }));
  };

  useEffect(() => {
    checkSemesterOverlap();
    calculateDeadlines();
  }, [semesterDetails?.start_date, semesterDetails?.end_date, semesterDetails?.no_end_date]);

  useEffect(() => {
    if (semesterDetails?.no_end_date === false && semesterDetails.end_date == null) {
      setSemesterDetails(prevState => ({
        ...prevState,
        end_date: moment().format('YYYY-MM-DD'),
      }));
    }
  }, [semesterDetails?.no_end_date]);

  useEffect(() => {
    if (!semesterDetails) return;
    if (semesterDetails.milestones.length === 0) return setActionButtonsDisabled(true);

    const allMilestonesHaveContent = semesterDetails.milestones.every(milestone => milestone.contents.length > 0);
    setActionButtonsDisabled(!allMilestonesHaveContent);
  }, [semesterDetails]);

  const semesterEndDateData = () => {
    if (semesterDetails.no_end_date) return null;

    return semesterDetails.end_date ? moment(semesterDetails.end_date).format('YYYY-MM-DD') : null;
  };

  const parseSemester = semesterData => ({
    ...semesterData,
    subjects: semesterData.subjects.map(subject => ({
      ...subject,
      subject_versions: subject.subject_versions.sort((a, b) => {
        if (a.current !== b.current) return (+b.current) - (+a.current); // Sort by "current" descending (1 before 0)
        return a.order_number - b.order_number; // Sort by order number ascending
      }),
    })),
  });

  const submitSemesterData = async ({ should_notify_learners = false }) => {
    setActionInProgress(true);
    setSemesterErrors({});
    setNetworkError(null);

    const semesterMilestonesFormData = () => {
      if (semesterDetails.milestones.length === 0) return [];

      return semesterDetails.milestones.map((milestone, index) => (
        {
          id: milestone.id,
          name: milestone.name,
          deadline: milestone.deadline,
          order_number: index,
          selected_content_ids: milestone.contents.map(content => content.id),
        }
      ));
    };

    const semesterFormData = {
      should_notify_learners,
      diplomas_enabled: semesterDetails.diplomas_enabled,
      mandatory_course_skill_level: semesterDetails.mandatory_course_skill_level,
      mandatory_projects: semesterDetails.mandatory_projects,
      gated_milestones: semesterDetails.gated_milestones,
      name: semesterDetails.name,
      start_date: semesterDetails.start_date ? moment(semesterDetails.start_date).format('YYYY-MM-DD') : null,
      end_date: semesterEndDateData(),
      milestones: semesterMilestonesFormData(),
      selected_content_ids: semesterMilestonesFormData().flatMap(milestone => milestone.selected_content_ids),
    };

    let response;
    try {
      if (semester_id) {
        response = await client.put(`/api/v1/admin/learning_groups/${learning_group_id}/semesters/${semester_id}`, {
          semester: semesterFormData,
        });
      } else {
        response = await client.post(`/api/v1/admin/learning_groups/${learning_group_id}/semesters`, {
          semester: semesterFormData,
        });
      }
    } catch (error) {
      setActionInProgress(false);
      if (error.response?.status === 401) {
        window.location.reload();
      } else if (error.response?.data) {
        setSemesterErrors(error.response.data.errors);
        setToastData({
          heading: t('validationErrorHeading'),
          message: t('validationErrorMessage'),
          state: ToastStates.ERROR,
        });
      } else {
        setToastData({
          heading: t('networkErrorHeading'),
          message: t('networkErrorMessage'),
          state: ToastStates.ERROR,
        });
        setNetworkError(`Error: ${error.message}`);
      }

      return;
    }

    if (response.data.redirect) window.location.href = response.data.redirect;
  };

  const fetchSemester = async () => {
    let response;

    try {
      if (semester_id) {
        response = await client.get(`/api/v1/admin/learning_groups/${learning_group_id}/semesters/${semester_id}`);
      } else {
        response = await client.get(`/api/v1/admin/learning_groups/${learning_group_id}/semesters/new`, {
          params: {
            learning_path_id,
          },
        });
      }

      setSemesterDetails(parseSemester(response.data.data));
    } catch (error) {
      if (error?.response?.status === 401) window.location.reload();
      else setNetworkError(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchSemester();
  }, [semester_id, learning_group_id, learning_path_id]);

  const createMilestone = evt => {
    evt.preventDefault();
    const newMilestone = {
      name: `Milestone ${semesterDetails.milestones.length + 1}`,
      contents: [],
      created_at: Date.now(),
    };
    setSemesterDetails(prevState => (
      {
        ...prevState,
        milestones: [...prevState.milestones, newMilestone],
      }
    ));
    editMilestone(evt, newMilestone);
  };

  const updateMilestoneNames = () => {
    setSemesterDetails(prevState => ({
      ...prevState,
      milestones: prevState.milestones.map((m, index) => {
        if (m.name.match(/^Milestone\s\d+$/)) return { ...m, name: `Milestone ${index + 1}` };
        return m;
      }),
    }));
  };

  const setMilestoneName = (index, evt) => {
    evt.preventDefault();
    const name = evt.target.name.value.replace(/\s+/g, ' ').trim();

    if (name) {
      setSemesterDetails(prevState => ({
        ...prevState,
        milestones: prevState.milestones.map((m, i) => {
          if (index === i) return { ...m, name };
          return m;
        }),
      }));
    }

    setEditMilestoneNameIndex(null);
  };

  const editMilestone = (evt, milestone) => {
    evt.preventDefault();
    setSelectedMilestone(milestone);
  };

  const updateMilestone = milestone => {
    setSemesterDetails(semester => {
      semester.milestones = semester.milestones.map(ms => {
        if (isSameMilestone(ms, milestone)) return { ...milestone, persisted: true };

        return ms;
      });
      return semester;
    });
    setSelectedMilestone(null);
    updateMilestoneNames();
    calculateDeadlines();
    setToastData({
      heading: t('milestoneUpdatedHeading'),
      message: t('milestoneUpdatedMessage', { name: milestone.name }),
      state: ToastStates.INFO,
    });
  };

  const deleteMilestone = (milestone, automated = false) => {
    // eslint-disable-next-line no-alert
    const approved = automated || window.confirm(t('confirmDeleteMilestone'));
    if (!approved) return;
    setSemesterDetails(prevState => ({
      ...prevState,
      milestones: prevState.milestones.filter(m => !isSameMilestone(m, milestone)),
    }));
    updateMilestoneNames();
    calculateDeadlines();
    if (!automated) {
      setToastData({
        heading: t('milestoneDeletedHeading'),
        message: t('milestoneDeletedMessage', { name: milestone.name }),
        state: ToastStates.INFO,
      });
    }
  };

  const cancelEdit = () => {
    if (!selectedMilestone.id && !selectedMilestone.persisted) deleteMilestone(selectedMilestone, true);
    setSelectedMilestone(null);
    updateMilestoneNames();
  };

  function removeSemesterErrors(attribute_name) {
    setSemesterErrors(prevState => {
      const { [attribute_name]: _, ...res } = prevState;
      return res;
    });
  }

  function removeSemesterWarnings(attribute_name) {
    setSemesterWarnings(prevState => {
      const { [attribute_name]: _, ...res } = prevState;
      return res;
    });
  }

  const checkSemesterOverlap = async () => {
    if (gated_semesters === true) {
      removeSemesterErrors('start_date');
      removeSemesterErrors('end_date');
    } else {
      removeSemesterWarnings('start_date');
      removeSemesterWarnings('end_date');
    }
    if (semesterDetails?.no_end_date) return;

    try {
      const response = await client.get(`/api/v1/admin/learning_groups/${learning_group_id}/semesters/check_overlaps`, {
        params: {
          start_date: semesterDetails?.start_date,
          end_date: semesterDetails?.end_date,
          semester_id,
        },
      });
      const response_data = response.data.data;
      if (gated_semesters === true) {
        if (Object.keys(response_data).length > 0) {
          Object.keys(response_data).forEach(attribute => {
            setSemesterErrors(prevState => ({
              ...prevState,
              [attribute]: t('semesterOverlaps', {
                semester_name: response_data[attribute].name,
                start_date: moment(response_data[attribute].start_date).format('dddd, MMMM Do YYYY'),
                end_date: moment(response_data[attribute].end_date).format('dddd, MMMM Do YYYY'),
              }),
            }));
          });
        }
      } else if (Object.keys(response_data).length > 0) {
        Object.keys(response_data).forEach(attribute => {
          setSemesterWarnings(prevState => ({
            ...prevState,
            [attribute]: (
              <Trans
                t={t}
                i18nKey={attribute === 'start_date' ? 'warningSemesterStartDate' : 'warningSemesterEndDate'}
                values={({
                  semester_name: response_data[attribute].name,
                  start_date: moment(response_data[attribute].start_date).format('dddd, MMMM Do YYYY'),
                  end_date: moment(response_data[attribute].end_date).format('dddd, MMMM Do YYYY'),
                })}
              >
                <strong />
              </Trans>),
          }));
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) window.location.reload();
    }
  };

  const isSameMilestone = (a, b) => (a.id !== undefined && a.id === b.id) || (a.id === undefined && a.created_at === b.created_at);

  if (!semesterDetails) return <KubicleLoading />;

  return (
    <>
      <section className="Page">
        <div className="Page-content">
          <h2
            className="m-top-xl m-bottom-ml u-textCenter"
          >
            {semester_id ? t('editSemesterTitle') : t('createSemesterTitle')}
          </h2>
          <SectionMessage
            className="m-bottom-ml"
            showIcon={false}
            title={t('semesterMessageTitle')}
            body={(
              <Trans t={t} i18nKey="semesterMessageBody">
                <a
                  className="u-bold u-underline is-external"
                  target="_blank"
                  href="https://support.kubicle.com/semester-milestones"
                  rel="noreferrer"
                />
              </Trans>
            )}
          />
        </div>
        <div className="Form">
          <Fieldset>
            <h4>{t('semesterDetails')}</h4>
            <section>
              <div className="Panel Panel--admin">
                <SemesterNameField
                  value={semesterDetails.name}
                  error={semesterErrors.name}
                  onChange={handleInputChange}
                  t={t}
                />
                <DiplomasEnabledCheckbox
                  value={semesterDetails.diplomas_enabled}
                  error={semesterErrors.diplomas_enabled}
                  onChange={handleInputChange}
                  t={t}
                />
              </div>
              <div className="Panel Panel--admin">
                <SemesterDateField
                  id="start_date"
                  name="start_date"
                  value={new Date(semesterDetails.start_date)}
                  tLabel="semesterStartDateLabel"
                  error={semesterErrors.start_date}
                  onChange={(n, v) => {
                    handleInputChange(n, v);
                  }}
                  t={t}
                  required
                />
                {semesterWarnings.start_date
                  && (
                    <SectionMessage
                      className="m-top-sm"
                      body={semesterWarnings.start_date}
                      state={SectionMessageStates.WARNING}
                    />
                  )}
              </div>
              <div className="Panel Panel--admin">
                <SemesterDateField
                  id="end_date"
                  name="end_date"
                  value={new Date(semesterDetails.end_date)}
                  tLabel="semesterEndDateLabel"
                  warning={semesterWarnings.end_date}
                  error={semesterErrors.end_date}
                  onChange={(n, v) => {
                    handleInputChange(n, v);
                  }}
                  t={t}
                  required={!semesterDetails.no_end_date}
                  disabled={semesterDetails.no_end_date}
                />
                {semesterWarnings.end_date
                  && (
                    <SectionMessage
                      className="m-top-sm"
                      body={semesterWarnings.end_date}
                      state={SectionMessageStates.WARNING}
                    />
                  )}
                {(kubicle_admin || semesterDetails.no_end_date) && (
                  <SemesterNoEndDateCheckbox
                    value={semesterDetails.no_end_date || false}
                    error={semesterErrors.no_end_date}
                    onChange={handleInputChange}
                    disabled={!kubicle_admin}
                    t={t}
                  />
                )}
              </div>
            </section>
            <h4>{t('contentSelection')}</h4>
            <section>
              <div
                className="Panel Panel--admin"
                style={semesterDetails.milestones.length === 0 ? { backgroundImage: 'url(\'/assets/empty_states/milestone_transparent.png\')' } : {}}
              >
                {semesterDetails.milestones.map((milestone, index) => (
                  <div key={`milestone-${milestone.id || milestone.created_at}`}>
                    <div className="Showcase has-2-columns m-top-none">
                      {editMilestoneNameIndex === index && (
                        <form onSubmit={evt => setMilestoneName(index, evt)}>
                          <input type="text" defaultValue={milestone.name} name="name" className="Milestone--name-input" />
                        </form>
                      )}
                      {editMilestoneNameIndex !== index && (
                        <a onClick={() => setEditMilestoneNameIndex(index)}>
                          <h4 className="m-bottom-none">
                            {milestone.name}
                            {' '}
                            <Icon icon="edit" outline />
                          </h4>
                        </a>
                      )}
                      <div className="u-flexRow u-alignCenter">
                        {milestone.deadline && (
                          <span className="u-grow1 u-textRight">
                            <span className="u-bold m-right-xs">Due Date:</span>
                            <span>{moment(milestone.deadline).format('dddd, MMMM Do YYYY')}</span>
                            <i
                              className="SubjectIcon SubjectIcon--circle SubjectIcon--small fa fa-question u-bgLightGrey u-textDarkBlue m-left-xs u-pointer"
                              data-tip
                              data-for={`deadline-tooltip-${(milestone.id || milestone.created_at)}`}
                            />
                            <Tooltip
                              id={`deadline-tooltip-${(milestone.id || milestone.created_at)}`}
                              text={(
                                <Trans t={t} i18nKey="milestoneDeadlineTip">
                                  <br />
                                  <span className="u-bold" />
                                </Trans>
                              )}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    {milestone.contents.length === 0 && (
                      <SectionMessage
                        className="m-top-sm"
                        body={t('emptyMilestoneError')}
                        state={SectionMessageStates.ERROR}
                      />
                    )}
                    {milestone.contents.map(content => (
                      <ContentSlide
                        key={`milestone-content-${content.id}`}
                        content={content}
                      />
                    ))}
                    <div className="Showcase has-2-columns b-highlightBlue b-hoverParent p-all-m m-bottom-none">
                      <a onClick={evt => editMilestone(evt, milestone)} className="u-textBlack u-gridGap-s u-hoverBold">
                        <span
                          className="SubjectIcon SubjectIcon--circle SubjectIcon--medium fa fa-pencil u-bgBlue u-textWhite m-right-s"
                        />
                        <span>{t('editMilestoneContents')}</span>
                      </a>
                      <a
                        className="b-hoverChild u-textRight lh-2 u-bold u-underline"
                        onClick={() => deleteMilestone(milestone)}
                      >
                        {t('deleteMilestone')}
                      </a>
                    </div>
                    {index + 1 < semesterDetails.milestones.length && <hr className="Separator m-top-m" />}
                  </div>
                ))}
                {semesterDetails?.milestones.length > 0 && (
                  <SemesterSummary
                    startDate={semesterDetails.start_date}
                    endDate={semesterDetails.end_date}
                    milestones={semesterDetails.milestones}
                    className="m-top-s m-bottom-ml"
                  />
                )}
                {semesterDetails.milestones.length > 0 && (
                  <a className="u-textCenter u-hoverUnderline" onClick={createMilestone}>
                    <h2 className="u-textCenter u-textBlue m-bottom-none">
                      <i className="fa fa-plus-circle size-large" />
                    </h2>
                    <p className="u-textBlack u-bold">{t('addMilestone')}</p>

                  </a>
                )}
                {semesterDetails.milestones.length === 0 && (
                  <div className="m-all-xl u-textCenter">
                    <h2 className="u-textBlue m-bottom-none"><i className="fa fa-plus-circle size-large" /></h2>
                    <p className="m-top-m">
                      <Trans t={t} i18nKey="createFirstMilestone">
                        <span className="u-bold" />
                      </Trans>
                    </p>
                    <a onClick={createMilestone} className="Button Button--primary">{t('createMilestone')}</a>
                  </div>
                )}
              </div>
            </section>
            <h4>{t('advancedConfiguration')}</h4>
            <section className="m-bottom-none">
              <CourseSkillsRadioButtons
                value={semesterDetails.mandatory_course_skill_level}
                error={semesterErrors.mandatory_course_skill_level}
                onChange={handleInputChange}
                t={t}
              />
              <MandatoryProjectRadioButtons
                value={semesterDetails.mandatory_projects}
                error={semesterErrors.mandatory_projects}
                onChange={handleInputChange}
                t={t}
              />
              <div className="Panel Panel--admin m-top-m m-bottom-s">
                <p className="u-bold">Other Options</p>
                <GatedMilestonesCheckbox
                  value={semesterDetails.gated_milestones}
                  error={semesterErrors.gated_milestones}
                  onChange={handleInputChange}
                  t={t}
                />
              </div>
            </section>
            <ActionButtons
              semesterId={semester_id}
              learningGroupSlug={learning_group_slug}
              disabled={actionInProgress || actionButtonsDisabled || Object.keys(semesterErrors).length > 0}
              onCreateClick={handleSaveClick}
              onSaveClick={handleSaveClick}
              onSaveAndNotifyClick={handleSaveAndNotifyClick}
              t={t}
            />
          </Fieldset>
        </div>
      </section>
      {
        selectedMilestone && (
          <MilestoneWizard
            milestone={selectedMilestone}
            subjects={semesterDetails.subjects}
            callbackFn={updateMilestone}
            cancelFn={cancelEdit}
            startDate={semesterDetails.start_date}
            endDate={semesterDetails.end_date}
            allMilestones={semesterDetails.milestones}
          />
        )
      }
      <Toasts>
        {
          toastData && (
            <Toast
              title={toastData.heading}
              state={toastData.state}
              closeCallback={() => setToastData(undefined)}
            >
              {toastData.message}
            </Toast>
          )
        }
      </Toasts>
    </>
  );
};

function SemesterNameField({
  value,
  error,
  onChange,
  t,
}) {
  return (
    <Field
      id="name"
      type="text"
      name="name"
      label={t('semesterNameLabel')}
      containerClass="Form-item--compact"
      labelClass="Form-label--bold"
      inputClass="Form-input--large"
      value={value}
      hintText={t('semesterNameHint')}
      milestonesRequired
      placeholder={t('semesterNamePlaceholder')}
      error={error}
      onChange={onChange}
    />
  );
}

function SemesterDateField({
  id,
  name,
  tLabel,
  value,
  error,
  onChange,
  t,
  required = false,
  disabled = false,
}) {
  return (
    <Field
      id={id}
      type="date"
      name={name}
      label={t(tLabel)}
      key={`date-input-${id}`}
      containerClass="Form-item--compact"
      labelClass="Form-label--bold"
      value={value}
      required={required}
      error={error}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

function SemesterNoEndDateCheckbox({
  value,
  error,
  onChange,
  disabled,
  t,
}) {
  return (
    <Checkbox
      id="no_end_date"
      name="no_end_date"
      label={t('semesterNoEndDateCheckboxLabel')}
      containerClass="Form-item--compact m-top-m"
      labelClass="Form-label--bold"
      value={value}
      error={error}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

function GatedMilestonesCheckbox({
  value,
  error,
  onChange,
  t,
}) {
  return (
    <Checkbox
      id="gated_milestones"
      name="gated_milestones"
      label={t('semesterGatedMilestonesCheckboxLabel')}
      containerClass="Form-item--compact m-top-ml"
      labelClass="Form-label--bold"
      value={value}
      error={error}
      onChange={onChange}
      disabled={false}
    />
  );
}

function DiplomasEnabledCheckbox({
  value,
  error,
  onChange,
  t,
}) {
  return (
    <Checkbox
      id="diplomas_enabled"
      name="diplomas_enabled"
      label={t('diplomasEnabledCheckboxLabel')}
      containerClass="Form-item--compact m-top-m"
      labelClass="Form-label--bold"
      value={value}
      error={error}
      onChange={onChange}
    />
  );
}

function CourseSkillsRadioButtons({
  value,
  error,
  onChange,
  t,
}) {
  const mandatoryCourseLevelOptions = [
    {
      label: t('mandatoryCourseLevelStrong'),
      value: 'strong',
      key: 'strong',
    },
    {
      label: t('mandatoryCourseLevelNeedsImprovement'),
      value: 'needs_improvement',
      key: 'needs_improvement',
    },
    {
      label: t('mandatoryCourseLevelWeak'),
      value: 'weak',
      key: 'weak',
    },
    {
      label: t('mandatoryCourseLevelOptional'),
      value: 'all_courses_optional',
      key: 'all_courses_optional',
    },
  ];

  return (
    <div className="Panel Panel--admin">
      <Field
        type="radio"
        name="mandatory_course_skill_level"
        label={t('mandatoryCourseLevelLabel')}
        containerClass="Form-item--compact"
        labelClass="Form-label--bold"
        value={value}
        options={mandatoryCourseLevelOptions}
        error={error}
        onChange={onChange}
      />
    </div>
  );
}

function MandatoryProjectRadioButtons({
  value,
  error,
  onChange,
  t,
}) {
  const projectCompletionsLevelOptions = [
    {
      label: t('projectCompletionLevelMandatory'),
      value: true,
      key: 'true',
    },
    {
      label: t('projectCompletionLevelOptional'),
      value: false,
      key: 'false',
    },
  ];

  return (
    <div className="Panel Panel--admin m-bottom-s">
      <Field
        type="radio"
        name="mandatory_projects"
        label={t('projectCompletionLabel')}
        containerClass="Form-item--compact"
        labelClass="Form-label--bold"
        value={value}
        options={projectCompletionsLevelOptions}
        error={error}
        onChange={onChange}
      />
    </div>
  );
}

function ActionButtons({
  semesterId,
  learningGroupSlug = '',
  disabled,
  onCreateClick,
  onSaveClick,
  onSaveAndNotifyClick,
  t,
}) {
  const cancelLink = (
    <a
      href={`/learning-groups/${learningGroupSlug}`}
      className="Button Button--secondary Button--grid"
    >
      {t('cancelLabel')}
    </a>
  );

  const createSemesterButton = (
    <input
      type="submit"
      className={`Button Button--primary Button--grid ${disabled && 'is-inactive'}`}
      value={t('createSemesterLabel')}
      disabled={disabled}
      onClick={onCreateClick}
    />
  );

  const saveSemesterButton = (
    <input
      type="submit"
      className={`Button Button--primary Button--grid ${disabled && 'is-inactive'}`}
      value={t('saveSemesterLabel')}
      disabled={disabled}
      onClick={onSaveClick}
    />
  );

  const saveSemesterAndNotifyButton = (
    <input
      type="submit"
      className={`Button Button--primary Button--grid ${disabled && 'is-inactive'}`}
      value={t('saveSemesterAndNotifyLabel')}
      disabled={disabled}
      onClick={onSaveAndNotifyClick}
    />
  );

  return (
    <div className="u-textRight">
      {semesterId && cancelLink}
      {semesterId && saveSemesterButton}
      {semesterId && saveSemesterAndNotifyButton}
      {!semesterId && createSemesterButton}
    </div>
  );
}

export default SemesterMilestonesForm;

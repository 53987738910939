import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import client from '../../../lib/api/axiosClient';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import LottieContainer from '../../Common/Components/LottieContainer';
import Toast, { ToastStates } from '../../DesignSystem/Toast';
import Toasts from '../../DesignSystem/Toasts';
import ProgressBar from '../../DesignSystem/ProgressBar';

const BackgroundOperation = ({ background_operation_id, email_enabled = true }) => {
  const { t } = useTranslation('BackgroundOperation');
  const [backgroundOperation, setBackgroundOperation] = useState({ completed_at: null });
  const [isLoading, setIsLoading] = useState(true);
  const [emailEnabled, setEmailEnabled] = useState(email_enabled);
  const [intervalID, setIntervalID] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastTitle, setToastTitle] = useState('');

  useEffect(() => {
    if (intervalID === null) {
      getBackgroundOperation();
      const interval = setInterval(() => {
        getBackgroundOperation();
      }, 2500);
      setIntervalID(interval);
    } else if (backgroundOperation.completed_at !== null) {
      clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [backgroundOperation.completed_at]);

  const getBackgroundOperation = () => {
    if (backgroundOperation.completed_at === null) {
      client
        .get(`/api/v1/background_operations/${background_operation_id}.json`)
        .then(response => {
          setBackgroundOperation(response.data.data);
          setEmailEnabled(email_enabled && response.data.data.subscribable);
          setIsLoading(false);
        })
        .catch(() => {
        });
    }
  };

  const subscribeEmail = () => {
    if (emailEnabled === true && backgroundOperation.subscribed === false) {
      client
        .get(`/api/v1/background_operations/${background_operation_id}/subscribe.json`)
        .then(() => {
          getBackgroundOperation();
          setToastTitle(t('EmailToastTitle'));
          setToastMessage(t('EmailToastMessage'));
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        })
        .catch(() => {
        });
    }
  };

  function subscribeButton() {
    if (backgroundOperation.subscribed === false) {
      return (
        <div className="EmptyState-action">
          <a
            className="u-underline"
            onClick={subscribeEmail}
          >
            {t('RequestEmail')}
          </a>
        </div>
      );
    }
  }

  function downloadButton() {
    if (backgroundOperation.completed_at !== null) {
      return (
        <a
          className="Button Button--primary m-bottom-none"
          href={backgroundOperation.attachment.url}
          target="_blank"
          rel="noreferrer"
        >
          {t('DownloadReport')}
        </a>
      );
    }
    return (
      <a
        className="Button Button--primary m-bottom-none is-inactive"
        onClick={event => event.preventDefault()}
      >
        {t('DownloadReport')}
      </a>
    );
  }

  const renderProgressBar = () => {
    if (backgroundOperation.precise === true) {
      return (
        <ProgressBar percentage={backgroundOperation.progress} />
      );
    }
  };

  const renderStatus = () => {
    if (isLoading) return (<KubicleLoading />);

    if (backgroundOperation.completed_at === null) {
      if (emailEnabled) {
        return (
          <>
            <div className="EmptyState">
              {renderLoader()}
              <h2>{t('ReportInProgressTitle')}</h2>
              <div className="EmptyState-description">
                <p>
                  {`${t('ReportInProgressBody')} `}
                  {backgroundOperation.subscribed ? t('ReportInProgressEmailNotification') : ''}
                </p>
              </div>
              {renderProgressBar()}
              <div className="EmptyState-description EmptyState-action">
                {downloadButton()}
                {subscribeButton()}
              </div>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="EmptyState">
            {renderLoader()}
            <h2>{t('ReportInProgressTitle')}</h2>

            <div className="EmptyState-description">
              <p>{t('ReportInProgressBody')}</p>
            </div>
            {renderProgressBar()}
            <div className="EmptyState-description EmptyState-action">
              {downloadButton()}
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="EmptyState">
          {renderLoader()}
          <h2>{t('ReportReadyTitle')}</h2>

          <div className="EmptyState-description">
            <p>{t('ReportReadyBody')}</p>
          </div>
          <div className="EmptyState-action">
            {downloadButton()}
          </div>
        </div>
      </>
    );
  };

  const renderLoader = () => {
    if (backgroundOperation.completed_at === null) {
      return (
        <>
          <LottieContainer
            className="BackgroundOperation-image generating"
            src="/assets/lottie/background-operation-generating.json"
            cover={false}
            loop
            onAnimationComplete={() => {
            }}
          />
        </>
      );
    }
    return (
      <>
        <LottieContainer
          className="BackgroundOperation-image completed"
          src="/assets/lottie/background-operation-completed.json"
          cover={false}
          loop={false}
          onAnimationComplete={() => {
          }}
        />
      </>
    );
  };

  return (
    <div className="background-operation-content">
      <div className="semester-content-wrapper semester-with-stats">
        <div>
          <div className="container-full-width">
            <div className="container">
              {renderStatus()}
            </div>
          </div>
        </div>
      </div>
      <Toasts>
        {showToast
          && (
            <Toast
              state={ToastStates.INFO}
              title={toastTitle}
            >
              <p>{toastMessage}</p>
            </Toast>
          )}
      </Toasts>

    </div>
  );
};

export default BackgroundOperation;

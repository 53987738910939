import React, { useCallback, useEffect, useState } from 'react';
import KubicleLoading from '../../Common/Components/KubicleLoading';
import ProjectProgressBar from './ProjectProgressBar';
import client from '../../../lib/api/axiosClient';
import ProjectStep from './ProjectStep';
import FinalStep from './FinalStep';
import Breadcrumbs from '../../Common/Components/Breadcrumbs';

const Project = ({
  projectId, userId, projectSlug, loadingFunction, loadedFunction = (() => true),
}) => {
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStep, setSelectedStep] = useState(0); // the step selected by user
  const [currentStep, setCurrentStep] = useState(0); // current active step determined by the progress of the user
  const [_step, setStep] = useState({}); // Step content

  useEffect(() => {
    loadProject();
  }, []);

  const parseProject = (projectData, changeStepOnSuccess) => {
    setProject(projectData);
    setStep(projectData.steps[selectedStep]);
    setCurrentStep(projectData.project_attempt.current_step);
    // update the current step based on progress
    if (changeStepOnSuccess) setSelectedStep(projectData.project_attempt.current_step);

    setIsLoading(false);
    loadedFunction();
  };

  const getProjectData = loadingFunction || (pId => new Promise((resolve, _reject) => {
    client
      .get(`/api/v1/projects/${pId}.json`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        if (error.response?.status === 401) window.location.reload();
        else console.error(error);
      });
  }));

  const loadProject = useCallback((async (changeStepOnSuccess = true) => {
    const projectData = await getProjectData(projectId);
    parseProject(projectData, changeStepOnSuccess);
  }), [projectId]);

  const changeStepCallback = n => {
    setSelectedStep(n);
    window.scrollTo(0, 0);
  };

  const breadcrumbFunction = () => <Breadcrumbs href="/" sections={['Projects', project.subject_name, project.name]} />;

  const renderStep = () => {
    if (selectedStep >= project.steps.length) {
      return (
        <FinalStep
          conclusions={project.final_step.conclusion}
          exampleUseCases={project.final_step.example_use_cases}
          skillsCovered={project.final_step.skills_covered}
          certificateUrl={project.final_step.certificate_url}
          selectedStep={selectedStep}
          setStepCallback={changeStepCallback}
          userId={userId}
        />

      );
    }

    const step = project.steps[selectedStep];

    const stepProps = {
      key: step.id,
      projectId,
      step,
      totalStepsNumber: project.steps.length,
      selectedStep,
      reloadProjectCallback: loadProject,
      setStepCallback: changeStepCallback,
      breadcrumbFunction,
    };

    return (
      <ProjectStep
        {...stepProps}
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <KubicleLoading />
      );
    }
    return (
      <div className="projects">
        {renderStep()}
        <ProjectProgressBar
          projectSlug={projectSlug}
          steps={project.steps}
          selectedStep={selectedStep}
          currentStep={currentStep}
          changeStepCallback={changeStepCallback}
        />
      </div>
    );
  };

  return renderContent();
};

export default Project;

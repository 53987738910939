import React from 'react';
import ExamFailed from "./ExamFailed";
import ExamSuccess from "./ExamSuccess";
import LottieContainer from "../../Common/Components/LottieContainer";

const ExamBanner = ({
  user_id, examSubmission, auto_submit, handleLinkedInSharing,
}) => {
  if (examSubmission.passed) {
    return (
      <div className="Banner Banner--passed">
        <LottieContainer
          className="Banner-background"
          src="/assets/lottie/project-celebrate.json"
          cover
        />
        <div className="Banner-overlay">
          <div className="Banner-container">
            <div className="Banner-content">
              <ExamSuccess
                examSubmission={examSubmission}
                user_id={user_id}
                handleLinkedInSharing={handleLinkedInSharing}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="Banner">
      <div className="Banner-container">
        <div className="Banner-content">
          <ExamFailed examSubmission={examSubmission} auto_submit={auto_submit} />
        </div>
      </div>
    </div>
  );
};

export default ExamBanner;

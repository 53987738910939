import React from 'react';
import { useTranslation } from "react-i18next";
import VisitorPreviewCTA from "../Common/Components/VisitorPreviewCTA";

const SqlInaccessibleOverlay = ({ isSignedIn, ctaUrl }) => (
  <div className="sql-inaccessible-overlay">
    {isSignedIn ? <SignedInOverlay /> : <SignedOutOverlay ctaUrl={ctaUrl} subject="SQL" />}
  </div>
);

const SignedInOverlay = () => {
  const { t } = useTranslation('SqlEditor');

  return (
    <div className="overlay-body">
      <h2>{t('RequestAccessToSQL')}</h2>
      <p>
        {t('DontHaveALicence')}
        <br />
        {t('ContactAdministrator')}
      </p>
      <p>
        <a href="mailto:support@kubicle.com" className="button">
          {t('RequestAccess')}
        </a>
      </p>
    </div>
  );
};

const SignedOutOverlay = ({ ctaUrl, subject }) => (
  <div className="video-overlay-poster sql-exercise">
    <VisitorPreviewCTA ctaUrl={ctaUrl} subject={subject} />
  </div>
);

export default SqlInaccessibleOverlay;

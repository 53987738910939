import React, { useState } from 'react';
import SemesterProgressBar from "./SemesterProgressBar";

const LearningPathStatistics = ({ progress }) => {
  const renderStatistics = () => (
    <>
      <h3>Diploma Progress</h3>
      <SemesterProgressBar
        className="semester-progress-bar"
        currentProgress={progress}
      />
    </>
  );

  return (
    <>
      {renderStatistics()}
    </>
  );
};

export default LearningPathStatistics;

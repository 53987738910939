import React, { useEffect, useState } from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const HtmlRendererWithVideo = ({ htmlContent }) => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    // Create a DOM parser to parse the HTML content into a document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Convert the HTML nodes into an array of React elements
    const childNodes = Array.from(doc.body.childNodes).map((node, index) => {
      if (node.className === 'video-player') {
        // If we find the element with id 'videoPlayer', replace it with the VideoPlayer component
        const playbackId = node.getAttribute('data-playbackid');
        return (
          <VideoPlayer
            // eslint-disable-next-line react/no-array-index-key
            key={`videoPlayer-${index}`}
            playbackId={playbackId}
          />
        );
      }
      // For other HTML nodes, create a React element and preserve their order
      return (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          dangerouslySetInnerHTML={{ __html: node.outerHTML }}
        />
      );
    });

    // Set the parsed and replaced elements to be rendered
    setElements(childNodes);
  }, [htmlContent]);

  return <div className="step-description boxed">{elements}</div>;
};

export default HtmlRendererWithVideo;

/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useSqlEditor from './useSqlEditor';
import SqlEditor, { RowCount } from '../Common/SqlEditor/SqlEditor';
import { ResultsSection } from '../Common/SqlEditor/ResultsSection';
import ExerciseTab from '../Exercise/ExerciseTab';
import SqlExerciseMessage from './SqlExerciseMessage';
import SqlInaccessibleOverlay from './SqlInaccessibleOverlay';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const QUESTIONS_SECTION_TAB_NUMBER = 1;
const SOLUTION_SECTION_TAB_NUMBER = 2;

const SqlExercise = ({
  videoPlaybackId,
  nextAction,
  sections,
  isAccessible,
  isSignedIn,
  ctaUrl,
  databaseSchema,
}) => {
  const { t } = useTranslation('Exercises');
  const [activeTabNumber, setActiveTabNumber] = useState(QUESTIONS_SECTION_TAB_NUMBER);

  useEffect(() => {
    KUBE.LessonList.init();
  }, []);

  return (
    <div className="sql-exercise-container exercise">
      <ExerciseTab
        {...{
          activeTabNumber,
          setActiveTabNumber,
        }}
        tabNumber={QUESTIONS_SECTION_TAB_NUMBER}
      >
        {t('Exercise')}
      </ExerciseTab>
      <ExerciseTab
        {...{ activeTabNumber }}
        setActiveTabNumber={setActiveTabNumber}
        tabNumber={SOLUTION_SECTION_TAB_NUMBER}
      >
        {t('Solution')}
      </ExerciseTab>
      {activeTabNumber === QUESTIONS_SECTION_TAB_NUMBER
        && <SqlExerciseSection sections={sections} setActiveTabNumber={setActiveTabNumber} databaseSchema={databaseSchema} />}
      {activeTabNumber === SOLUTION_SECTION_TAB_NUMBER && (
        <div className="exercise-section exercise-solution-section">
          <VideoPlayer playbackId={videoPlaybackId} nextAction={nextAction} showPlayRate />
        </div>
      )}
      {!isAccessible && <SqlInaccessibleOverlay isSignedIn={isSignedIn} ctaUrl={ctaUrl} />}
    </div>
  );
};

const SqlExerciseSection = ({
  sections,
  databaseSchema,
  setActiveTabNumber,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const section = sections[currentStep];
  const nextStepIsSolution = () => currentStep + 1 >= sections.length;

  const goToNextStep = () => {
    if (nextStepIsSolution()) setActiveTabNumber(SOLUTION_SECTION_TAB_NUMBER);
    else setCurrentStep(currentStep + 1);
  };

  return (
    <div className="sql-exercise-section">
      {sections.length > 1 ? (
        <div className="steps">
          <span>Steps:</span>
          {' '}
          {sections.map((_s, index) => (
            <a
              key={index}
              className={`step ${index === currentStep && 'current-step'} ${index < currentStep && 'completed-step'}`}
              onClick={() => setCurrentStep(index)}
            >
              {index + 1}
            </a>
          ))}
        </div>
      ) : ''}
      <SqlExerciseStep
        {...section}
        goToNextStep={goToNextStep}
        nextStepIsSolution={nextStepIsSolution}
        databaseSchema={databaseSchema}
      />
    </div>
  );
};

const SqlExerciseStep = ({
  placeholder_sql,
  answer_sql,
  id,
  instructions,
  goToNextStep,
  nextStepIsSolution,
  databaseSchema,
}) => {
  const {
    query,
    setQuery,
    runQuery,
    submitQuery,
    status,
    setStatus,
    errorMessage,
    setErrorMessage,
    result,
    setResult,
    fields,
    setFields,
  } = useSqlEditor({ defaultSql: placeholder_sql, exerciseId: id, schema: databaseSchema });

  const resetQuery = () => {
    setQuery(placeholder_sql);
    setStatus('');
    setResult([]);
    setFields([]);
    setErrorMessage(null);
  };

  const showAnswer = (e) => {
    e.preventDefault();
    setQuery(answer_sql);
    setStatus('show-answer');
  };

  useEffect(() => {
    resetQuery();
  }, [placeholder_sql, id]);

  return (
    <div>
      <div
        className="sql-exercise-instructions"
        dangerouslySetInnerHTML={{
          __html: instructions,
        }}
      />
      <form>
        <SqlEditor {...{ query, setQuery, status }} />
        <a
          className="reset-code-button"
          onClick={(e) => {
            e.preventDefault();
            resetQuery();
          }}
        >
          <i className="fa fa-undo" aria-hidden="true" />
          <span className="button-text">Reset Code</span>
        </a>
        <SqlExerciseMessage {...{
          status, errorMessage, showAnswer, nextStepIsSolution,
        }}
        />
        <div className="button-section">
          {fields.length > 0 ? <RowCount {...{ result }} /> : ''}
          <SubmitButton {...{
            status, goToNextStep, nextStepIsSolution, submitQuery,
          }}
          />
          <button className="button hollow" onClick={runQuery}>Run code</button>
        </div>
      </form>

      <ResultsSection {...{ errorMessage, result, fields }} />
    </div>
  );
};

const SubmitButton = ({
  status, goToNextStep, nextStepIsSolution, submitQuery,
}) => {
  if (status === 'success' || status === 'show-answer') {
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          goToNextStep();
        }}
        className="button"
      >
        {nextStepIsSolution() ? 'Watch Solution' : 'Next'}
      </button>
    );
  }
  return <button onClick={submitQuery} className="button">Submit Answer</button>;
};

export default SqlExercise;

import React, { useState, useEffect, useRef } from 'react';

const NotificationMessage = ({ children, className }) => {
  const [activeClass, setActiveClass] = useState('');
  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setActiveClass('active');
    }, 100);
  }, []);

  return (
    <div className={`notification-message ${className} ${activeClass}`}>
      {children}
    </div>
  );
};

export default NotificationMessage;

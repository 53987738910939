import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CopyToClipboard from '../DesignSystem/CopyToClipboard';
import UserEventTracker from '../../lib/analytics/UserEventTracker';

const CalendarPreferencesPage = ({ manualCalendarUrl, googleCalendarUrl, outlookCalendarUrl }) => {
  const { t } = useTranslation('CalendarPreference');

  const trackUserEvent = (event, userEventName) => {
    UserEventTracker.sendEvent('Element Clicked', {
      Section: 'Learner Calendar Preferences',
      Name: userEventName,
    });
  };

  return (
    <>
      <h2>{t('pageHeading')}</h2>
      <p>{t('pageAboutPara')}</p>
      <a
        rel="noreferrer"
        href="https://support.kubicle.com/how-to-sync-your-kubicle-milestones-with-your-calendar"
        target="_blank"
        className="u-underline is-external"
      >
        {t('calendarSupportLinkText')}
      </a>
      <hr className="Separator" />
      <h4>{t('pageHeadingAutomaticCalendarIntegration')}</h4>
      <p>{t('automaticCalendarIntegrationPara')}</p>

      <a
        id="google-calendar-link"
        onClick={event => trackUserEvent(event, 'Google Calendar')}
        className="Button Button--secondary Button--withIcon Button--large Button--Inside-Shadow u-block u-textLeft"
        href={`https://calendar.google.com/calendar/r?cid=${googleCalendarUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="Button-image-icon" src="/assets/icons/google_calendar_icon.png" />
        <span className="Button-text">
          <Trans t={t} i18nKey="googleCalendarLinkText">
            <b>Google Calendar</b>
          </Trans>
        </span>
      </a>

      <a
        id="outlook-calendar-link"
        onClick={event => trackUserEvent(event, 'Outlook Calendar')}
        className="Button Button--secondary Button--withIcon Button--large Button--Inside-Shadow u-block u-textLeft"
        href={`https://outlook.live.com/calendar/0/addfromweb?url=${outlookCalendarUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="Button-image-icon" src="/assets/icons/microsoft_outlook_icon.png" />
        <span className="Button-text">
          <Trans t={t} i18nKey="microsoftOutlookLinkText">
            <b>Microsoft Outlook Calendar</b>
          </Trans>
        </span>
      </a>

      <h4>{t('manualCalendarIntegrationTitle')}</h4>
      <p>{t('manualCalendarIntegrationHelpText')}</p>

      <CopyToClipboard copyContent={manualCalendarUrl} large />
    </>
  );
};

export default CalendarPreferencesPage;

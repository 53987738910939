import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initHelperPopup, closeHelperPopup } from '../../../lib/scripts/helper_popup';
import ExamTimer from "../../../lib/scripts/exam_timer";
import { openWindowRight } from "../../../lib/utils/openWindow";

const ExamDownload = ({
  examFileUrl, sqlExam, examId, prompt = true, className = 'button green right',
}) => {
  const { t } = useTranslation('Exam');
  const examFileDownloadPromptText = t('ExamFileDownloadPrompt');
  const codeEditorPromptText = t('CodeEditorPromptText');

  useEffect(() => {
    if (!prompt) return;

    if (sqlExam) initHelperPopup(codeEditorPromptText, '#open_sql_editor', false);
    else if (examFileUrl) initHelperPopup(examFileDownloadPromptText, '#exam_file_download', false);
    else new ExamTimer();
  }, []);

  if (sqlExam) return <CodeEditorButton examId={examId} className={className} />;
  if (examFileUrl) return <FileDownloadButton examFileUrl={examFileUrl} className={className} />;

  return '';
};

const TimerStartButton = (props) => {
  const [clickedOnce, setClickedOnce] = useState(false);
  const { className, children } = props;

  const closePopupAndStartTimer = () => {
    if (!clickedOnce) {
      closeHelperPopup();
      new ExamTimer();
      setClickedOnce(true);
    }
  };

  return (
    <a {...props} onMouseDown={closePopupAndStartTimer} className={className}>
      {children}
    </a>
  );
};

const CodeEditorButton = ({ examId, className }) => {
  const { t } = useTranslation('Exam');

  return (
    <TimerStartButton
      href="#"
      id="open_sql_editor"
      className={className}
      onClick={() => {
        openWindowRight(`/sql_sandbox?exam_id=${examId}`);
      }}
    >
      <i className="fa fa-code" />
      {t('openEditor')}
    </TimerStartButton>
  );
};

const FileDownloadButton = ({ examFileUrl, className }) => {
  const { t } = useTranslation('Exam');

  return (
    <TimerStartButton
      href={examFileUrl}
      id="exam_file_download"
      className={className}
      download
      target="_blank"
    >
      <i className="fa fa-download" />
      {t('downloadFile')}
    </TimerStartButton>
  );
};

export default ExamDownload;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Slide from '../../DesignSystem/Slide';
import ContentIcon from './ContentIcon';

const ContentSlide = ({
  content,
  className = '',
  showDetails = true,
  onClick = (() => {}),
}) => {
  const { t } = useTranslation('Common');

  return (
    <Slide className={`${content.content_type === 'Project' ? 'Slide--project' : ''} ${className}`} onClick={onClick} color={content.subject_colour}>
      <ContentIcon className="Slide-icon" content={content} />
      <span className="u-grow1">{content.name}</span>
      {showDetails && (
        <div className="Slide-details u-flexRow">
          <span>{t('durationMinutes', { minutes: content.duration })}</span>
        </div>
      )}
    </Slide>
  );
};

export default ContentSlide;

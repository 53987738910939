import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import LottieContainer from "../../Common/Components/LottieContainer";

const ExamSuccess = ({ examSubmission, user_id, handleLinkedInSharing }) => {
  const { t } = useTranslation("Exam");

  return (
    <div className="CompletionState">
      <LottieContainer
        className="CompletionState-image m-left-none"
        src="/assets/lottie/diploma-celebrate.json"
        cover={false}
        loop={false}
        onAnimationComplete={() => {
        }}
      />
      <div className="CompletionState-content">
        <h2 className="CompletionState-heading">{t('examPassed')}</h2>
        <p>
          <Trans t={t} i18nKey="examPassedText" values={{ course_name: examSubmission.exam.course_name, percent: examSubmission.percent }}>
            <span className="u-bold" />
            <span className="u-bold" />
          </Trans>
        </p>
        <p>
          <Trans t={t} i18nKey="viewCertificate">
            <a href={`/certificates?user_id=${user_id}`} />
          </Trans>
        </p>
        <div className="Banner-actions">
          <a
            onClick={() => handleLinkedInSharing(examSubmission.certificate_url)}
            className="Button Button--primary Button--grid"
          >
            <i className="fa fa-linkedin-square" />
            <span>{t('shareCertificate')}</span>
          </a>
          <a href={`/users/${user_id}/my_kubicle?u=true`} className="Button Button--secondary Button--grid">
            <i className="fa fa-play-circle" />
            <span>{t('continueLearning')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExamSuccess;

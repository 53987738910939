import React from "react";

const EmptyStatePanel = ({ title, content }) => (
  <div className="empty-panel">
    <h2>{title}</h2>
    <p>{content}</p>
  </div>
);

export default EmptyStatePanel;

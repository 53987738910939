import React, { useState } from 'react';

const KeyboardShortcuts = ({
  windows_shortcuts,
  windows_shortcuts_url,
  mac_shortcuts,
  mac_shortcuts_url,
}) => {
  const toggleShortcuts = (e) => {
    if (e.target.value === 'windows') setIsWindows(true);
    else setIsWindows(false);
  };

  const setWindowsAsDefault = () => !(mac_shortcuts && isMacOS(navigator.platform));

  const displayDownloadIcon = () => (windows_shortcuts_url && isWindows) || (mac_shortcuts_url && !isWindows);

  const [isWindows, setIsWindows] = useState(setWindowsAsDefault());

  const windowsShortcutList = (
    <div
      className="keyboard-shortcuts-list"
      dangerouslySetInnerHTML={{ __html: windows_shortcuts }}
    />
  );

  const macShortcutList = (
    <div
      className="keyboard-shortcuts-list"
      dangerouslySetInnerHTML={{ __html: mac_shortcuts }}
    />
  );

  return (
    <div>
      <h2 className="bold">
        Keyboard Shortcuts

        <a
          href={isWindows ? windows_shortcuts_url : mac_shortcuts_url}
          className={displayDownloadIcon() ? 'active' : ''}
          id="download-shortcuts"
          download=""
          target="_blank"
          rel="noreferrer"
        >
          <i className=" fa fa-download" title=" Download Shortcuts PDF" />
        </a>

      </h2>
      {mac_shortcuts && (
        <div className="radio-buttons">
          <label>
            <input
              type="radio"
              onChange={toggleShortcuts}
              id="show_windows_shortcuts"
              name="shortcutsOption"
              value="windows"
              checked={isWindows}
            />
            Windows
          </label>
          <label>
            <input
              type="radio"
              onChange={toggleShortcuts}
              id="show_mac_shortcuts"
              name="shortcutsOption"
              value="mac"
              checked={!isWindows}
            />
            Mac
          </label>
        </div>
      )}
      <div>
        {isWindows ? windowsShortcutList : macShortcutList}
      </div>
    </div>
  );
};

export function isMacOS(navigatorPlatform) {
  return navigatorPlatform.toUpperCase()
    .indexOf('MAC') >= 0;
}

export default KeyboardShortcuts;
